import React from "react";
import appConfig from "../../appConfig";
import {connect} from "react-redux";
import Mixpanel from "mixpanel-browser";
import {DateConverter} from "../../DateConverter";
import {MdPublic} from "react-icons/md";
import {AiOutlineEyeInvisible, AiOutlineBranches} from "react-icons/ai";
import {BsCalendar3} from "react-icons/bs";
import {CiBarcode} from "react-icons/ci";
import {VscExtensions} from "react-icons/vsc";
import {FaRegHandshake} from "react-icons/fa";
import "../../styles/components/myrecords_createdrecord.scss";
import {DataReporter} from "../../DataReporter";


class CreatedRecord extends React.Component {

    // Events
    recordItemOnClick() {
        let routeParam;
        if (!this.props.pending) {
            routeParam = this.props.rootRecord[5];
        } else {
            routeParam = this.props.rootRecord.routeParam;
        }

        DataReporter.trackMixpanel(this.props, "Selected from My Records",
            { category: "Navigation", value: routeParam }
        );

        window.open(`/record?rp=${routeParam}`, "_self");
    }

    // Renderers

    renderPrivacyLevel() {
        let _private = <span><MdPublic className={"public-icon"}/>Publicly visible</span>;

        if (this.props.rootRecord[3] === "true") {
            _private = <span><AiOutlineEyeInvisible className={"private-icon"}/>Privately visible</span>;
        }
        return _private;
    }

    renderTransferStatus() {
        // TODO: Work
        if (false) {
            let transferred = null;
            let ownAddress = this.props.walletReducer.connectedAddress.toLowerCase();

            if (this.props.rootRecord[1] !== ownAddress) {
                transferred = (
                    <p className={"transferred"}>
                        <FaRegHandshake className={"text-icon"}/>
                        Transferred to you
                    </p>
                );
            } else if (this.props.rootRecord[1] === ownAddress && this.props.rootRecord[14] !== ownAddress) {
                transferred = (
                    <p className={"transferred-out"}>
                        <FaRegHandshake className={"text-icon"}/>
                        Transferred out
                    </p>
                );
            }
            return transferred;
        }
        return null;
    }

    renderBranchStatus() {
        // TODO: Work
        if (false) {
            if (this.props.rootRecord[8] !== "") {
                return (
                    <div className={"branch-status"}>
                        <p><AiOutlineBranches className={"text-icon"}/>Branched Record</p>
                    </div>);
            } else if (this.props.rootRecord[9] !== "") {
                return (
                    <div className={"branch-status"}>
                        <p><AiOutlineBranches className={"text-icon"}/>This Record has been branched</p>
                    </div>);
            }
        }
        return null;
    }

    renderStatusInfos() {
        let transferStatus = this.renderTransferStatus();
        let branchStatus = this.renderBranchStatus();

        if (transferStatus || branchStatus)
        return (
            <div className={"status-infos"}>
                {transferStatus}
                {branchStatus}
            </div>
        );
    }

    renderQuickInfoBar() {
        let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";

        let data = this.props.CIDJson;
        let creationDate = data.recordCreationDate;

        return (
            <div className={"quick-info-bar inline" + mobileSuffix}>
                <p className={"added-on" + mobileSuffix}>
                    <BsCalendar3 className={"cal-icon"}/>
                    Created on {DateConverter.dateObjToString(parseInt(creationDate))}
                </p>

                <p className={"is-private" + mobileSuffix}>
                    {this.renderPrivacyLevel()}
                </p>

                <p className={"extension-count" + mobileSuffix}>
                    <VscExtensions className={"ext-icon"}/>
                    {this.props.pending ? 0 : this.props.rootRecord[6]} updates added
                </p>
            </div>
        );
    }

    renderMobileLayout() {
        let data;

        if (this.props.pending) {
            data = this.props.rootRecord.rootRecordData;
        } else {
            data = this.props.CIDJson.data;
        }

        return (
            <div className={"created-record-item"} onClick={() => this.recordItemOnClick(this.props.rootRecord[5])}>
                <p className={"name"}>
                    {data.name}
                </p>

                {this.renderStatusInfos()}
                {this.renderQuickInfoBar()}

                <p className={"descr-label"}>Description</p>
                <p className={"descr-mobile"}>{data.description}</p>

                <p className={"serial-nr-label"}>Serial number</p>
                <p className={"serial-nr-mobile"}>
                    <CiBarcode className={"barcode-icon"}/>
                    {data.serialNr}
                </p>

            </div>
        );
    }

    renderDesktopLayout() {
        let data = this.props.rootRecord.rootRecordData;

        return (
            <div className={"created-record-item"} onClick={() => this.recordItemOnClick()}>
                {this.renderStatusInfos()}
                {this.renderQuickInfoBar()}

                <p className={"name"}>
                    {data.name}
                </p>

                <p className={"descr-label"}>Description</p>
                <p className={"descr"}>{data.description}</p>

                <p className={"serial-nr-label"}>Serial number</p>
                <p className={"serial-nr"}><CiBarcode className={"barcode-icon"}/>{data.serialNr}</p>

            </div>
        );
    }

    render() {
        if (this.props.appReducer.mobileMode) {
            return this.renderMobileLayout();
        } else return this.renderDesktopLayout();

    }
}

const mapStateToProps = (state) => {
    return {
        walletReducer: state.wallet,
        appReducer: state.app
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatedRecord);