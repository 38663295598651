import * as types from "../actionTypes/user";
import * as actionResponse from "./actionResponse";


export function requestSetLoginToken(param) {
    return (dispatch) => {
        dispatch(actionResponse.gen(types.SET_LOGIN_TOKEN, param));
    }
}

export function requestSetEmail(param) {
    return (dispatch) => {
        dispatch(actionResponse.gen(types.SET_EMAIL, param));
    }
}

export function requestRemoveLoginToken() {
    return (dispatch) => {
        dispatch(actionResponse.gen(types.REMOVE_LOGIN_TOKEN, null));
    }
}

export function requestRemoveEmail() {
    return (dispatch) => {
        dispatch(actionResponse.gen(types.REMOVE_EMAIL, null));
    }
}