import React from "react";
import {connect} from "react-redux";
import appConfig from "../../appConfig";
import {DataReporter} from "../../DataReporter";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { IoCloseCircleOutline } from "react-icons/io5";
import {IoIosArrowDown, IoIosArrowForward} from "react-icons/io";
import "../../styles/components/reliableinformation.scss";

class ReliableInformationSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hint: null,
            expandMyProduct: false,
            expandModeration: false,
        }
    }

    getProductWord(capitalize=false, plural=false) {
        let productWord = plural ? "products" : "product";

        if (this.props.appReducer.activePersona === appConfig.personas.watches) {
            productWord = plural ? "watches" : "watch";
        }
        if (capitalize) {
            return productWord.charAt(0).toUpperCase() + productWord.slice(1);
        } else {
            return productWord;
        }
    }

    // Events
    setShowHint(hint) {
        this.setState({
            hint: hint
        }, () => {
            DataReporter.trackMixpanel(this.props, "Show Explainer Hint",
                {
                    category: "Learning",
                    hint: hint
                }
            );
        });
    }

    onExpandMyProduct() {
        let set = this.state.expandMyProduct;
        this.setState({
            expandMyProduct: !set
        }, () => {
            DataReporter.trackMixpanel(this.props, "Clicked on Foldable Text",
                {
                    category: "Learning",
                    set: set,
                    question: "How my products?"
                }
            );
        });
    }

    onExpandModeration() {
        let set = this.state.expandModeration;
        this.setState({
            expandModeration: !set
        }, () => {
            DataReporter.trackMixpanel(this.props, "Clicked on Foldable Text",
                {
                    category: "Learning",
                    set: set,
                    question: "How moderation?"
                }
            );
        });
    }


    // Renderers
    renderHint() {
        let title;
        let descr;
        let showHintLow;

        if (this.state.hint === "factories") {
            title = `${this.getProductWord(true)} Factories`;
            descr = `These Provide details about every built ${this.getProductWord()} to Ventrace. Such as the construction date, ` +
                "quality control results and necessary inspection reminders.";
        }
        if (this.state.hint === "retail") {
            title = "Retail Stores (online and physical)";
            descr = `They Provide details about sold or bought ${this.getProductWord(false, true)}. Including where and when, ` +
                "if warranties were given, and much more.";
        }
        if (this.state.hint === "repair") {
            title = "Service and Repair Shops";
            descr = `Provide details about any kind of servicing for a ${this.getProductWord()}. ` +
                "Including damage analyses and applied changes to fix a problem.";
        }
        if (this.state.hint === "owners") {
            title = "Certified Owners";
            descr = `Provide custom updates about a ${this.getProductWord()} while it is being used. ` +
                "Including scratch marks, battery health or private sales to new owners.";
        }
        if (this.state.hint === "moderation") {
            showHintLow = true;
            title = "Moderation";
            descr = "Ventrace is checking whether the submitted details are plausible, " +
                "correctly formatted and provable upon request.";
        }
        if (this.state.hint === "order") {
            showHintLow = true;
            title = "Your Order";
            descr = `You order the Ventrace Record of a specific ${this.getProductWord()} which you own.`;
        }
        if (this.state.hint === "vr") {
            showHintLow = true;
            title = "Your Ventrace Record";
            descr = `From now on you will automatically receive valuable updates about the condition of your ${this.getProductWord()} ` +
                `(internal or external). Also, you can always digitally prove that you own your ${this.getProductWord()}.`;
        }


        if (this.state.hint) {
            return (
                <div id={"explainer-hint" + (showHintLow ? "-low" : "")}
                     onClick={() => this.setState(null)}>
                    <p id={"title"}>{title}</p>
                    <p id={"hint-descr"}>{descr}</p>
                    <IoCloseCircleOutline id={"close-icon"}
                        onClick={() => this.setShowHint(null)}
                    />
                </div>
            )
        }
    }

    renderExplainerDiagram() {
        if (this.props.forMobile) {
            return (
                <div id={"explainer-diagram-container"}>
                    <img
                        id="explainer-diagram"
                        src={process.env.PUBLIC_URL + '/assets/PlatformExplainer_mobile.png'}
                        alt={"Ventrace Platform Explainer"}
                    />
                    <div id={"explainer-diagram-hints"}>
                        {this.renderHint()}

                        <IoMdInformationCircleOutline
                            id={"retail-stores-icon"} className={"hint-icon"}
                            onClick={() => this.setShowHint("retail")}
                        />
                        <IoMdInformationCircleOutline
                            id={"repair-shops-icon"} className={"hint-icon"}
                            onClick={() => this.setShowHint("repair")}
                        />

                        <IoMdInformationCircleOutline
                            id={"factories-icon"} className={"hint-icon"}
                            onClick={() => this.setShowHint("factories")}
                        />
                        <IoMdInformationCircleOutline
                            id={"owners-icon"} className={"hint-icon"}
                            onClick={() => this.setShowHint("owners")}
                        />

                        <IoMdInformationCircleOutline
                            id={"moderation-icon"} className={"hint-icon"}
                            onClick={() => this.setShowHint("moderation")}
                        />
                        <IoMdInformationCircleOutline
                            id={"your-order-icon"} className={"hint-icon"}
                            onClick={() => this.setShowHint("order")}
                        />
                        <IoMdInformationCircleOutline
                            id={"your-vr-icon"} className={"hint-icon"}
                            onClick={() => this.setShowHint("vr")}
                        />
                    </div>
                </div>
            );
        } else {
            let path = '/assets/PlatformExplainer.png';
            if (this.props.appReducer.activePersona === appConfig.personas.watches) {
                path = '/assets/PlatformExplainer_desktop_watches.png';
            }

            return (
                <img
                    id="explainer-diagram"
                    src={path}
                    alt={"platformexplainer"}
                />
            );
        }
    }

    renderFoldableExplainers() {
        let myProductText;
        let pw = this.getProductWord(false, false);

        if (this.state.expandMyProduct) {

            myProductText = (
                <p id={"my-product-text"} className={"expanded-text"}>
                    Ventrace is connected to a global network of {pw} factories, {pw} marketplaces and {pw} service centers.
                    When these so-called 'providers' handle a {pw} as part of their work, they then deliver their business
                    transactions to Ventrace. Ventrace in turn, matches these transactions as updates to your certificate.
                </p>
            );
        }

        let moderationText;
        if (this.state.expandModeration) {
            moderationText = (
                <p id={"how-moderation-text"} className={"expanded-text"}>
                    Ventrace is using many methods to verify the quality and correctness of all incoming &thinsp;
                    {pw} updates. These methods are divided into human-based methods (active moderators) and algorithm-based methods.
                </p>
            );
        }


        return (
            <div id={"additional-explainers"}>
                <div className={"diagram-explainer"} onClick={() => this.onExpandMyProduct()}>
                    <div className={"inline"}>
                        {this.state.expandMyProduct ? <IoIosArrowDown className={"arrow"} />
                            : <IoIosArrowForward  className={"arrow"} />}
                        <p className={"title"}>
                            How am I getting updates about my own {this.getProductWord(false, true)}?
                        </p>
                    </div>
                    {myProductText}
                </div>

                <div className={"diagram-explainer"} onClick={() => this.onExpandModeration()}>
                    <div className={"inline"}>
                        {this.state.expandModeration ? <IoIosArrowDown className={"arrow"} />
                            : <IoIosArrowForward  className={"arrow"} />}
                        <p className={"title"}>How does Ventrace verify {this.getProductWord()} updates?</p>
                    </div>
                    {moderationText}
                </div>

            </div>
        )
    }

    render() {
        let mobileSuffix = this.props.forMobile ? "-mobile" : "";

        return (
            <div id={"reliable-information-container" + mobileSuffix}>
                <div id={"content"}>
                    <p id={"headline"}>
                        <span className={"hl"}>Safe</span>&nbsp;&nbsp;and
                        &nbsp;
                        <span className={"hl"}>reliable</span>&nbsp;
                        information
                    </p>

                    <p id={"descr"}>
                        Ventrace is connected to a global network of factories, retail stores and service centers.
                        The history of every {this.getProductWord()} is collected in Ventrace Records.
                    </p>

                    {this.renderExplainerDiagram()}
                    {this.renderFoldableExplainers()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        appReducer: state.app
    }
}

export default connect(mapStateToProps, null)(ReliableInformationSection);