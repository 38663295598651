import React from "react";
import appConfig from "../../appConfig";
import {DateConverter} from "../../DateConverter";
import {DataReporter} from "../../DataReporter";
import CreatorInfo from "./CreatorInfo";
import {AiFillInfoCircle} from "react-icons/ai";
import {MdOutlineNextWeek} from "react-icons/md";
import {BsCalendarWeek} from "react-icons/bs";
import "../../styles/components/details_transfer_opening.scss";

class TransferOpening extends React.Component {

    clickOpenAddressExplorer(val, role) {
        let baseUrl = appConfig.explorerURLs.testnet.address;

        DataReporter.trackMixpanel(this.props, "TransOpen: Open " + role, {
            category: "Interaction",
        });

        window.open(baseUrl + val, "_blank");
    }

    render() {
        if (this.props.data) {
            let mobileSuffix = this.props.forMobile ? "-mobile" : "";

            let descr = null;
            if (this.props.data.description) {
                descr = (
                    <div className={"descr-container"}>
                        <p className={"descr-label"}>Description added by creator</p>
                        <p className={"descr-val"}>{this.props.data.description}</p>;
                    </div>
                );
            }

            let originatorAddress = this.props.data.originator;
            let nextOwnerAddress = this.props.data.nextOwnerWalletAddress_Display;

            originatorAddress = originatorAddress.slice(0, appConfig.address_SliceLen)
                + "-" + originatorAddress.slice(-appConfig.address_SliceLen);

            nextOwnerAddress = nextOwnerAddress.slice(0, appConfig.address_SliceLen)
                + "-" + nextOwnerAddress.slice(-appConfig.address_SliceLen);

            let changeDateLabel = "On";

            if (this.props.forMobile) {
                changeDateLabel = "";
            }

            return (
                <div className={"transfer-opening-container" + mobileSuffix}>
                    <div className={"record-content"}>

                        <div className={"l1 inline"}>
                            <p className={"record-type"}>
                                <MdOutlineNextWeek className={"type-icon"}/>
                                Transfering
                            </p>
                            <div className={"added-on-container"}>
                                <p className={"change-date"}>
                                    {changeDateLabel}&nbsp;
                                    <span className={"data"}>
                                    <BsCalendarWeek className={"cal-icon"}/>
                                        {DateConverter.dateObjToString(parseInt(this.props.data.recordCreationDate))}
                                </span>
                                </p>
                            </div>
                        </div>

                        <div className={"l2"}>
                            <div className={"record-creation-date"}>
                                <p className={"label"}>Actually happened on</p>
                                <p className={"data"}>&nbsp;
                                    <BsCalendarWeek className={"cal-icon"}/>
                                    {DateConverter.dateObjToString(parseInt(this.props.data.changeDate))}
                                </p>
                            </div>
                        </div>

                        <div className={"l3"}>
                            <CreatorInfo
                                title={"Transfer started by verified owner"}
                                creatorAddress={this.props.data.originator}
                                defaultRender={(
                                    <div className={"originator"}>
                                        <p className={"label"}>Update Creator</p>
                                        <p className={"data"}
                                           onClick={() => this.clickOpenAddressExplorer(this.props.data.originator, "creator")}>
                                            {originatorAddress}
                                        </p>
                                    </div>
                                )}
                            />

                            <div className={"next-owner-address"}>
                                <p className={"label"}>Appointed next owner</p>
                                <a className={"data"}
                                   onClick={() => this.clickOpenAddressExplorer(this.props.data.nextOwnerWalletAddress_Display, "next owner")}>
                                    {nextOwnerAddress}
                                </a>
                            </div>
                        </div>

                        <div className={"l4"}>
                            {descr}
                        </div>

                        <div className={"opening-hint"}>
                            <AiFillInfoCircle id={"icon"} />
                            <p>
                                This update is a transfer <i>opening</i>, which means that the next Record owner
                                has yet to accept the ownership over this Record. There will be another Record update,
                                when the transfer is accepted.
                            </p>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default TransferOpening;