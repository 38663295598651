import React from "react";
import {create} from "ipfs-http-client";
import {connect} from "react-redux";
import Mixpanel from "mixpanel-browser";
import Logger from "js-logger";
import UserButton from "./inputs/UserButton";
import appConfig from "../appConfig";
import TRXReceipt from "./TRXReceipt";
import FeedbackMessage from "./FeedbackMessage";
import HintContainer from "./HintContainer";
import SidebarExplainer from "./SidebarExplainer";
import VTRCalculator from "./VTRCalculator";
import {AiOutlineBranches} from "react-icons/ai";
import {requestSetBlockchainLoading, requestSetBlockchainLoadingStop} from "../store/actions/blockchain";
import {EIP712types} from "../EIP712types";
import "../styles/components/details_branchcreator.scss";
import {DataReporter} from "../DataReporter";

class RecordBranchCreator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submissionSuccessful: false,
            trxReceipt: null,
            enoughBalance: false,
            rateToPay: null,
            estimatedGasUse: null,
            errorMessage: null,
            submitting: false
        }
    }

    getSideBarText() {
        return [
            {
                "name": "Branch creation",
                "descr": "You create a branch of an existing record and automatically copy all extensions up" +
                    " to the moment of branching."
            },
            {
                "name": "Branch management",
                "descr": "You can find and access your branched record under 'My Records'. You'll be able to add" +
                    " new extensions to keep your branch up to date."
            },
            {
                "name": "Accept or dismiss",
                "descr": "Since you branched off an existing (original) record, your branch will be listed in the" +
                    " history of the original record. If the owner of the original record accepts your branch," +
                    " both of you will get VTR rewards and you will become the next owner of the original record. " +
                    "But if the original owner dismisses your record, your branch will be marked as dismissed and" +
                    " unlinked from the original record."
            },
            {
                "name": "One year time limit",
                "descr": "If the owner of the original record doesn't react to the branch for one year,"
                + " Ventrace will automatically accept your branch and transfer the ownership to you."
            },
        ];
    }

    getRecordBranchJSON() {
        let originalJSON = this.props.rootRecordData.data;
        originalJSON.recordType = "branchedRoot";
        originalJSON.recordCreationDate = Date.now().toString();
        originalJSON.originator = this.props.walletReducer.connectedAddress;
        return originalJSON;
    }

    getBranchExtensionJSON(branchRouteParam) {
        return {
            "recordType": "branchCreated",
            "recordCreationDate": Date.now().toString(),
            "originator": this.props.walletReducer.connectedAddress,
            "branchRouteParam": branchRouteParam
        }
    }

    async createBranch() {
        this.props.dispatchBlockchainLoading();

        if (!this.state.submitting) {
            this.setState({ submitting: true },
                () => {
                    let vtrTokenContract = this.props.blockchainReducer.vtrTokenContract;
                    vtrTokenContract.methods.approve(appConfig.currentConfig.contracts.address_VTR_Manager,
                        this.state.rateToPay*appConfig.approvalMultiply)
                        .send({
                            from: this.props.walletReducer.connectedAddress,
                            gas: appConfig.currentConfig.blockchainGasLimit
                        })
                        .then(async (receipt) => {
                            Logger.info(`Successfully approved ${this.state.rateToPay} VTR with TRX hash: ${receipt.transactionHash}`);

                            // Upload data as JSON to IPFS
                            let auth = "Basic " + Buffer.from(appConfig.IPFS_ProjectID + ":" + appConfig.IPFS_Key).toString("base64");
                            let client = create({url: appConfig.IPFS_Endpoint, headers: {authorization: auth}});
                            let storedJSON = await client.add(JSON.stringify(this.getRecordBranchJSON()));

                            try {
                                let signature = await window.ethereum.request({
                                    method: 'eth_signTypedData_v4',
                                    params: [this.props.walletReducer.connectedAddress,
                                        JSON.stringify(EIP712types.newBranch(parseInt(this.props.rootRecord[0])))
                                    ],
                                });

                                let rsv = EIP712types.getRSVfromSignature(signature);

                                let recordManagerContract = this.props.blockchainReducer.recordManagerContract;
                                recordManagerContract.methods.createRecordBranch(parseInt(this.props.rootRecord[0]),
                                    storedJSON.path, rsv.r, rsv.s, rsv.v)
                                    .send({
                                        from: this.props.walletReducer.connectedAddress,
                                        gas: appConfig.currentConfig.blockchainGasLimit
                                    })
                                    .then((branchReceipt) => {
                                        Logger.info(`Successfully created branch with TRX hash: ${branchReceipt.transactionHash}`);

                                        DataReporter.trackMixpanel(this.props, "Success: Branch created", {
                                            category: "Interaction",
                                        });

                                        // Get routeParam of branch by quering for latest root data info
                                        recordManagerContract.methods.getRootRecord(this.props.rootRecord[5])
                                            .call({
                                                from: this.props.walletReducer.connectedAddress,
                                                gas: appConfig.currentConfig.blockchainGasLimit
                                            })
                                            .then(async (rootReceipt) => {
                                                let storedJSON = await client.add(JSON.stringify(this.getBranchExtensionJSON(rootReceipt[9])));
                                                let extType = this.getBranchExtensionJSON(null).recordType;

                                                let signature = await window.ethereum.request({
                                                    method: 'eth_signTypedData_v4',
                                                    params: [this.props.walletReducer.connectedAddress,
                                                        JSON.stringify(EIP712types.newExtension(this.props.rootRecord[0], extType))
                                                    ],
                                                });

                                                let rsv = EIP712types.getRSVfromSignature(signature);

                                                // Add branch extension update to original record
                                                recordManagerContract.methods.addExtensionRecord(this.props.rootRecord[0], storedJSON.path,
                                                    extType, rootReceipt[9], 0, rsv.r, rsv.s, rsv.v)
                                                    .send({
                                                        from: this.props.walletReducer.connectedAddress,
                                                        gas: appConfig.currentConfig.blockchainGasLimit
                                                    })
                                                    .then((extReceipt) => {
                                                        this.props.dispatchBlockchainLoadingStop();

                                                        DataReporter.trackMixpanel(this.props, "Branch ext. created successfully", {
                                                            category: "Interaction",
                                                        });

                                                        this.onSubmissionSuccessful(extReceipt);
                                                    })
                                                    .catch((err) => {
                                                        this.setState({
                                                            errorMessage: "Error during branch creation (1). Please try again later."
                                                        }, () => {
                                                            Logger.info(err.message);
                                                            this.props.dispatchBlockchainLoadingStop();

                                                            DataReporter.trackMixpanel(this.props, "Error: Creating branch extension", {
                                                                category: "Display",
                                                            });
                                                        });
                                                    })
                                            })
                                            .catch((err) => {
                                                this.setState({
                                                    errorMessage: "Error during branch creation (2). Please try again later."
                                                }, () => {
                                                    this.props.dispatchBlockchainLoadingStop();

                                                    DataReporter.trackMixpanel(this.props, "Error: Getting root record", {
                                                        category: "Display",
                                                    });
                                                });
                                            })

                                    })
                                    .catch((err) => {
                                        this.setState({
                                            errorMessage: "Error during branch creation (3). Please try again later."
                                        }, () => {
                                            console.log(err.message);
                                            this.props.dispatchBlockchainLoadingStop();
                                        });
                                    })

                            } catch (err) {
                                let msg = "Signing failed. Please check if your wallet is configured correctly.";
                                if (err.message.toLowerCase().includes("chainid")) {
                                    msg = `Signing failed. Please check if your wallet is connected to the ${appConfig.currentConfig.blockchainName} network.`;
                                }

                                this.setState({
                                    errorMessage: msg,
                                    submitting: false
                                });
                            }
                        })
                        .catch((err) => {
                            this.setState({
                                errorMessage: "Error during branch creation (4). Please try again later."
                            }, () => {
                                this.props.dispatchBlockchainLoadingStop();

                                DataReporter.trackMixpanel(this.props, "Error: Appoving branch creation", {
                                    category: "Interaction",
                                });
                            });
                        })
                })
        }
    }

    // Events
    componentDidMount() {
        DataReporter.trackMixpanel(this.props, "Component view: Branch creator", {
            pageDisplayMode: this.props.appReducer.mobileMode ? "mobile" : "desktop"
        });
    }

    onSubmissionSuccessful(receipt) {
        this.setState({
            submissionSuccessful: true,
            trxReceipt: receipt,
            errorMessage: null
        })
    }

    onVTRGetRate(rate) {
        if (this.props.walletReducer.vtrBalance) {
            this.setState({
                enoughBalance: this.props.walletReducer.vtrBalance >= Math.abs(rate),
                rateToPay: Math.abs(rate)
            });
        }
    }

    onEstimatedGasUse(estimate) {
        this.setState({
            estimatedGasUse: estimate
        });
    }

    // Renderers
    renderErrorMessage() {
        if (this.state.errorMessage !== null) {
            DataReporter.trackMixpanel(this.props, "Branch creator: Error message", {
                category: "Interaction",
            });

            return <FeedbackMessage
                success={false}
                message={this.state.errorMessage}
            />;
        }
    }

    renderTRXReceipt() {
        if (this.state.submissionSuccessful) {
            let last = this.state.trxReceipt;

            DataReporter.trackMixpanel(this.props, "TRX receipt: Branch created", {
                category: "Display",
            });

            return <TRXReceipt
                forMobile={this.props.appReducer.mobileMode}
                descr={"Created record branch"}
                trxHash={last.transactionHash}
                trxGasUsed={last.gasUsed}
                estimatedGas={this.state.estimatedGasUse}
            />;
        }
    }

    renderSubmitContainer() {
        let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";

        let spinner;
        let buttonValue;
        if (this.state.submitting) {
            buttonValue = "Branching in progress ...";
            spinner = <div id={"spinner-container"}>
                <img id="spinner" src={process.env.PUBLIC_URL + '/spinner.gif'} alt={"spinner"}/>
            </div>;
        }
        else buttonValue = "Create branch";

        return(
            <div id={"submit-container"}>
                <VTRCalculator
                    posName={`Creating a branch`}
                    rateName={"getRateCreateBranch"}
                    onGetRate={(rate) => this.onVTRGetRate(rate)}
                    onGetGasUseEstimate={(estimate) => this.onEstimatedGasUse(estimate)}
                />
                {this.renderErrorMessage()}
                <div className={"inline" + mobileSuffix}>
                    <UserButton
                        forMobile={this.props.appReducer.mobileMode}
                        id={"submit-button"}
                        value={buttonValue}
                        icon={<AiOutlineBranches className={"text-icon"}/>}
                        disabled={this.props.blockchainReducer.loading
                            || this.props.walletReducer.noWalletMode || this.state.submitting
                            || !this.state.enoughBalance}
                        onClick={async () => this.createBranch()}
                    />
                    {spinner}
                </div>
                <HintContainer
                    forMobile={this.props.appReducer.mobileMode}
                    id={"hint-container"}
                    hints={[
                        "You will be prompted to sign two transactions.",
                        "The first signature is for approving the cost in VTR.",
                        "The second is for an update to the original record to inform about your branch.",
                    ]}
                />
            </div>
        )
    }

    renderContent() {
        if (this.props.rootRecord && !this.state.submissionSuccessful) {
            return (
                <div>
                    <h1>Create a branch of a Record</h1>
                    <div id={"left"}>
                        <p className={"descr"}>
                            Branches are basically copies of existing <span className={"vr-hl"}>Records</span>.
                            The owner of the original Record can decide, whether your branch will be accepted
                            or dismissed.
                        </p>
                        <HintContainer
                            forMobile={this.props.appReducer.mobileMode}
                            id={"branch-hints"}
                            hints={[
                                "Record branches won't appear in the landingPage results of Ventrace.",
                                "Record branches can only be accessed through the original record."
                            ]}
                        />
                        {this.renderSubmitContainer()}
                    </div>
                    <div id={"right"}>
                        <SidebarExplainer
                            forMobile={this.props.appReducer.mobileMode}
                            id={"sidebar-explainer"}
                            h1={"How does branching work?"}
                            steps={this.getSideBarText()}
                        />
                    </div>
                    <div id={"clearer"}/>
                </div>
            );
        }
    }

    render() {
        let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";

        return (
            <div id={"create-branch-container" + mobileSuffix}>
                {this.renderTRXReceipt()}
                {this.renderContent()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        walletReducer: state.wallet,
        blockchainReducer: state.blockchain,
        appReducer: state.app
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatchBlockchainLoading: () => {
            dispatch(requestSetBlockchainLoading())
        },
        dispatchBlockchainLoadingStop: () => {
            dispatch(requestSetBlockchainLoadingStop())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecordBranchCreator);