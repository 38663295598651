import React from "react";
import "../styles/components/sidebar-explainer.scss";

class SidebarExplainer extends React.Component {

    renderSteps() {
        let mobileSuffix = this.props.forMobile ? "-mobile" : "";

        let steps = [];
        for (let i = 0; i < this.props.steps.length; i += 1) {
            steps.push(
                <div className={"step-container" + mobileSuffix} key={i}>
                    <div className={"name"}>
                        <p id={"nr" + mobileSuffix}>{i + 1}</p>
                        <p id={"val"}>{this.props.steps[i].name}</p>
                    </div>
                    <p className={"descr"}>{this.props.steps[i].descr}</p>
                </div>
            );
        }
        return <div className={"steps" + mobileSuffix}>{steps}</div>;
    }

    render() {
        let mobileSuffix = this.props.forMobile ? "-mobile" : "";
        return (
            <div className={"side-bar-explainer-container" + mobileSuffix}
                 id={this.props.id}>
                <h1 className={"sidebar-h1" + mobileSuffix}>
                    {this.props.h1}
                </h1>
                {this.renderSteps()}
            </div>
        );
    }
}

export default SidebarExplainer;