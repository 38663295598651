const {
    currentConfig
} = require("./appConfig");

class EIP712types {
    static getRSVfromSignature(_signature) {
        let signature = _signature.substring(2);
        let r = "0x" + signature.substring(0, 64);
        let s = "0x" + signature.substring(64, 128);
        let v = parseInt(signature.substring(128, 130), 16);

        return {r: r, s: s, v: v};
    }

    static getEIP712Domain() {
        return [
            {name: "name", type: "string"},
            {name: "version", type: "string"},
            {name: "chainId", type: "uint256"},
            {name: "verifyingContract", type: "address"},
        ];
    }

    static getDomainValues(verifyingContractAddress, chainId) {
        return {
            name: "Ventrace",
            version: "1",
            chainId: chainId ? chainId : currentConfig.chainID,
            verifyingContract: verifyingContractAddress ? verifyingContractAddress : currentConfig.contracts.address_RecordManager,
        };
    }

    // Not using internal 'rootRecord', since function will be used in a signing request, where
    // the end user has to understand what he's signing.
    static newRecord(productName, productUID, verifyingContractAddress=null, chainId=null) {
        return {
            types:
                {
                    EIP712Domain: this.getEIP712Domain(),
                    NewRecord: [
                        {name: "productName", type: "string"},
                        {name: "productUID", type: "string"}
                    ]
                },
            primaryType: "NewRecord",
            domain: this.getDomainValues(verifyingContractAddress, chainId),
            message: {
                productName: productName,
                productUID: productUID
            }
        };
    }

    static newExtension(rootRecordID, extensionType) {
        return {
            types:
                {
                    EIP712Domain: this.getEIP712Domain(),
                    NewExtension: [
                        {name: "rootRecordID", type: "uint256"},
                        {name: "extensionType", type: "string"}
                    ]
                },
            primaryType: "NewExtension",
            domain: this.getDomainValues(),
            message: {
                rootRecordID: rootRecordID,
                extensionType: extensionType
            }
        };
    }

    static newDispute(extID) {
        return {
            types:
                {
                    EIP712Domain: this.getEIP712Domain(),
                    NewDispute: [
                        {name: "extID", type: "uint256"}
                    ]
                },
            primaryType: "NewDispute",
            domain: this.getDomainValues(),
            message: { extID: extID }
        };
    }

    static newBranch(rootRecordID) {
        return {
            types:
                {
                    EIP712Domain: this.getEIP712Domain(),
                    NewBranch: [
                        {name: "rootRecordID", type: "uint256"}
                    ]
                },
            primaryType: "NewBranch",
            domain: this.getDomainValues(),
            message: { rootRecordID: rootRecordID }
        };
    }

    static newDisputeReport(IPFS_CID) {
        return {
            types:
                {
                    EIP712Domain: this.getEIP712Domain(),
                    NewDisputeReport: [
                        {name: "IPFS_CID", type: "string"}
                    ]
                },
            primaryType: "NewDisputeReport",
            domain: this.getDomainValues(),
            message: { IPFS_CID: IPFS_CID }
        };
    }

    static newDisputeDecision(IPFS_CID) {
        return {
            types:
                {
                    EIP712Domain: this.getEIP712Domain(),
                    NewDisputeDecision: [
                        {name: "IPFS_CID", type: "string"}
                    ]
                },
            primaryType: "NewDisputeDecision",
            domain: this.getDomainValues(),
            message: { IPFS_CID: IPFS_CID }
        };
    }

}

module.exports = { EIP712types };