import React from "react";
import {connect} from "react-redux";
import {AiFillQuestionCircle} from "react-icons/ai";
import {DataReporter} from "../DataReporter";
import "../styles/components/info_tooltip.scss";


class InfoToolTip extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mouseOver: false
        };
    }

    // Events
    onMouseOver(e) {
        if (!this.state.mouseOver) {
            this.setState({
                mouseOver: true
            }, () => {
                DataReporter.trackMixpanel(this.props, "Showing tool tip", {
                    category: "Display",
                    text: this.props.text
                });
            });
        }
    }

    onMouseLeave(e) {
        this.setState({mouseOver: false});
    }

    onClick() {
        if (this.props.onClick) {
            this.props.onClick();
        }
    }

    // Renderers
    renderText() {
        if (this.state.mouseOver && !this.props.appReducer.mobileMode) {
            return <p id={"text"}>{this.props.text}</p>
        }
    }

    render() {
        return (
            <div className={"info-tooltip-wrapper"} id={this.props.wrapperID}
                onClick={() => this.onClick()}
            >
                <div className={"info-tooltip-container"}
                     id={this.props.containerID}
                     onMouseOver={(e) => this.onMouseOver(e)}
                     onMouseLeave={(e) => this.onMouseLeave(e)}
                >
                    <AiFillQuestionCircle id={"icon"}/>
                    {this.renderText()}
                </div>
                <div id={"closer"}/>
            </div>
        );
    }
}

InfoToolTip.defaultProps = {
    wrapperID: "tooltipWrapper",
    containerID: "tooltipContainer",
}

const mapStateToProps = (state) => {
    return {
        appReducer: state.app
    }
}

export default connect(mapStateToProps, null)(InfoToolTip);