import React from "react";
import {connect} from "react-redux";
import UserButton from "./inputs/UserButton";
import {requestSetCookies} from "../store/actions/app";
import { MdOutlineCookie } from "react-icons/md";
import { FaCheck } from "react-icons/fa6";
import { ImCross } from "react-icons/im";
import {isMobile} from "react-device-detect";
import "../styles/components/cookieconsentbanner.scss";
import {DataReporter} from "../DataReporter";

class CookieConsentBanner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    onAccept() {
        this.props.dispatchSetCookies(true);
    }

    onReject() {
        this.props.dispatchSetCookies(false);
    }

    // Renderers

    renderDetails() {
        return (
            <div id={"details-content"}>
                You can get more details through the&nbsp;
                <a href={"/privacypolicy"} target={"_blank"}>Ventrace Privacy Policy</a> and/or
                the <a href={"/terms"} target={"_blank"}>Ventrace Terms and Conditions</a>.
            </div>
        )
    }

    render() {
        let mobileSuffix = isMobile ? "-mobile" : "";

        if (!this.props.appReducer.cookieDecisionMade) {
            return (
                <div id={"consent-banner-container" + mobileSuffix}>
                    <h3>
                        <MdOutlineCookie id={"cookie-icon"}/>
                        Cookies
                    </h3>
                    <p id={"text"}>
                        Ventrace is using cookies to enhance your experience on this website.<br/>
                        By using Ventrace, you consent to the use of cookies as described in the details in this banner.
                    </p>
                    <div id={"details-container"}>
                        <p id={"details-h"}>Details</p>
                        {this.renderDetails()}
                    </div>
                    <div id={"buttons-container"} className={"inline"}>
                        <UserButton
                            id={"accept-button"}
                            value={"Accept"}
                            icon={<FaCheck className={"text-icon"}/>}
                            onClick={(e) => this.onAccept(e)}
                        />
                        <UserButton
                            id={"reject-button"}
                            value={"Reject"}
                            icon={<ImCross className={"text-icon"}/>}
                            onClick={(e) => this.onReject(e)}
                        />
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        appReducer: state.app,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatchSetCookies: (set) => {
            dispatch(requestSetCookies(set));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CookieConsentBanner);