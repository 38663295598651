import React from "react";
import {connect} from "react-redux";
import axios from "axios";
import appConfig from "../../appConfig";
import {requestSetBlockchainLoading, requestSetBlockchainLoadingStop} from "../../store/actions/blockchain";
import {MdVerified} from "react-icons/md";
import {DataReporter} from "../../DataReporter";
import {AiOutlineMail} from "react-icons/ai";
import {TbWorld} from "react-icons/tb";
import "../../styles/components/details_creatorinfo.scss";

class CreatorInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checking: false,
            verifiedInfo: null
        };
    }

    getProviderData() {
        if (!this.state.checking) {
            this.setState({
                checking: true
            }, async () => {
                try {
                    axios
                        .get(appConfig.currentConfig.backendApp.url + "/provider/all",
                            {
                                headers: {
                                    Authorization: `Bearer ${appConfig.currentConfig.backendApp.tokens.provider}`,
                                    'Content-Type': 'application/json'
                                },
                                params: {
                                    id: this.props.providerID
                                }
                            })
                        .then(res => {
                            if (res.status === 200) {
                                this.setState({
                                    verifiedInfo: res.data[0],
                                    checking: false
                                });
                            } else {
                                DataReporter.trackMixpanel(this.props,
                                    "Error: Getting ProviderData", { category: "Error" });
                            }
                        })
                        .catch(err => {
                            DataReporter.trackSentry(err, { extra: {additionalData: "getProviderData"} });
                        })

                } catch (err) {
                    DataReporter.trackSentry(err, { extra: {additionalData: "getProviderData"} });
                }
            })
        }
    }

    checkVerification() {
        if (!this.state.checking) {
            this.setState({
                checking: true
            }, async () => {
                try {
                    axios
                        .get(appConfig.currentConfig.backendApp.url + "/vrholder/getsingle",
                            {
                                headers: {
                                    Authorization: `Bearer ${appConfig.currentConfig.backendApp.tokens.standard}`,
                                    'Content-Type': 'application/json',
                                },
                                params: {
                                    email: this.props.userReducer.email,
                                },
                            })
                        .then(res => {
                            if (res.data.success || res.data.isVerified) {
                                this.setState({
                                    verifiedInfo: res.data.verifiedOwnerData,
                                    checking: false
                                });
                            }
                        })
                        .catch(err => {
                            DataReporter.trackMixpanel(this.props, "Details page: Can't check verification (1)");
                            DataReporter.trackSentry(err);
                        })

                } catch (err) {
                    DataReporter.trackMixpanel(this.props, "Details page: Can't check verification (2)");
                    DataReporter.trackSentry(err);
                }
            })
        }
    }

    // Events
    onClickSupportEmail() {
        DataReporter.trackMixpanel(this.props, "Verified owner: Click support mail", {
            category: "Interaction",
        });

        let mailTo;
        if (this.props.providerID) {
            mailTo = this.state.verifiedInfo.contactInfo.email;
        } else {
            mailTo = this.state.verifiedInfo.supportEmail;
        }

        window.location.href = `mailto:${mailTo}`;
    }

    onClickRelatedPage() {
        DataReporter.trackMixpanel(this.props, "Verified owner: Click page", {
            category: "Interaction",
        });

        if (this.props.fromProvider) {
            window.open(this.state.verifiedInfo.contactInfo.website, "_blank");
        } else {
            let url = this.state.verifiedInfo.relatedPageURL;
            window.open(url.startsWith("http") ? url : "https://" + url, "_blank");
        }
    }

    // Renderers
    renderReferences() {
        let supportEmail;

        if (this.props.providerID ||
            (this.state.verifiedInfo.supportEmail && this.state.verifiedInfo.supportEmail.length > 5)) {
            supportEmail = (
                <p id={"support-email"} onClick={() => this.onClickSupportEmail()}>
                    <AiOutlineMail className={"ref-icon"} />
                    Support
                </p>
            );
        }

        return (
            <div id={"references-container"}>
                <p id={"related-page"} onClick={() => this.onClickRelatedPage()}>
                    <TbWorld className={"ref-icon"} />
                    {this.props.fromProvider ? "Website" : "About"}
                </p>
                {supportEmail}
            </div>
        );
    }

    render() {
        if (!this.state.verifiedInfo) {
            if (this.props.providerID) {
                this.getProviderData();
            }
            else {
                this.checkVerification();
            }
            return this.props.defaultRender;
        } else {
            let p = this.props;
            let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";

            let imageUrl = process.env.PUBLIC_URL + "/assets/verified-bg.jpg";

            let style = {
                backgroundImage: `url(${imageUrl})`,
                backgroundSize: "210%",
                backgroundPosition: 'center',
            };

            console.log(this.state.verifiedInfo.name);
            return (
                <div className={"verified-creator-container" + mobileSuffix} style={style}>
                    <p id={"verified-title"}>
                        <MdVerified id={"icon"} />
                        {p.providerID ? "Officially provided by" : this.props.title}
                    </p>

                    <p className={"label"}>Registered name</p>
                    <p id={"verified-name"}>
                        {p.providerID ? this.state.verifiedInfo.name : this.state.verifiedInfo.displayName}
                    </p>

                    <p className={"label"}>References</p>
                    {this.renderReferences()}
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        userReducer: state.user,
        walletReducer: state.wallet,
        blockchainReducer: state.blockchain,
        appReducer: state.app
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatchBlockchainLoading: () => {
            dispatch(requestSetBlockchainLoading())
        },
        dispatchBlockchainLoadingStop: () => {
            dispatch(requestSetBlockchainLoadingStop())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatorInfo);