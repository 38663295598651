import React from "react";
import {MdOutlineAddAPhoto} from "react-icons/md";
import {FiTrash2} from "react-icons/fi";
import "../../styles/inputs/imageUploadInput.scss";

class ImageUploadInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedImages: [null, null, null],
            previewImages: [null, null, null]
        }
    }

    // Events
    onChange(e, onChangeIndex) {
        let curr = this.state.selectedImages;
        curr[onChangeIndex] = e.target.files[0];

        this.setState({ selectedImages: curr },
            () => {
                if (this.props.onChange) {
                    this.props.onChange(this.state.selectedImages);
                }
            });

        let img = new Image();
        img.src = URL.createObjectURL(e.target.files[0]);
        img.onload = (e) => {
            let curr = this.state.previewImages;
            curr[onChangeIndex] = img;
            this.setState({ previewImages: curr });
        }
    }

    onResetImageClick() {
        this.setState({
            selectedImages: [],
            previewImages: []
        }, () => {
            if (this.props.OnReset) {
                this.props.OnReset();
            }
        });
    }

    // Renderers
    resetImage(imageIndex) {
        if (this.state.selectedImages[imageIndex] !== null) {
            let curr = this.state.selectedImages;
            curr[imageIndex] = null;

            let currPreviews = this.state.previewImages;
            currPreviews[imageIndex] = null;

            this.setState({
                    selectedImages: curr,
                    previewImages: currPreviews
                },
                () => {
                    if (this.props.onChange) {
                        this.props.onChange(this.state.selectedImages);
                    }
                });

        }
    }

    renderValidationResult() {
        if (this.props.validationError) {
            return (
                <div className={"input-error"}>
                    <p className={"msg"}>
                        {this.props.validationMsg}
                    </p>
                </div>
            )
        }
    }

    renderImgLabel(idFor) {
        return (
            <label className={"input-label-img"} htmlFor={idFor}>
                <MdOutlineAddAPhoto className={"icon"} />
            </label>
        );
    }

    renderImgThumbnail(imageIndex, idFor) {
        if (this.state.previewImages[imageIndex] !== null) {
            return (
                <div className={"img-preview-container"}>
                    <label htmlFor={idFor}>
                        <img
                            className={"img-preview"}
                            alt={"Image preview"}
                            src={this.state.previewImages[imageIndex].src}
                        />
                    </label>
                    <div className={"icon-container"} onClick={() => this.resetImage(imageIndex)}>
                        <FiTrash2 className={"close-icon"}/>
                    </div>
                </div>
            );
        }
    }

    renderImageUploadBox(imageIndex, idFor) {
        if (this.state.previewImages[imageIndex] !== null) {
            return this.renderImgThumbnail(imageIndex, idFor);
        } else return this.renderImgLabel(idFor);
    }

    renderImageUploadInput(id, onChangeIndex) {
        return (
            <input
                id={id}
                className={"img-upload-input"}
                type={"file"}
                accept={".jpg,.jpeg,.png"}
                onChange={(e) => this.onChange(e, onChangeIndex)}
                onClick={(event)=> { event.target.value = null }}
            />
        );
    }

    render() {
        let mobileSuffix = this.props.forMobile ? "-mobile" : "";
        return (
            <div className={"img-input-container"} id={this.props.id}>
                {this.props.hints ? this.props.hints : null}

                <div className={"inline"+mobileSuffix}>
                    {this.renderImageUploadBox(0, "img-upload-1")}
                    {this.renderImageUploadBox(1, "img-upload-2")}
                    {this.renderImageUploadBox(2, "img-upload-3")}

                    {this.renderImageUploadInput("img-upload-1", 0)}
                    {this.renderImageUploadInput("img-upload-2", 1)}
                    {this.renderImageUploadInput("img-upload-3", 2)}

                </div>
                {this.renderValidationResult()}
            </div>
        );
    }
}

ImageUploadInput.defaultProps = {
    renderLabel: true,
    showCharLimit: true,
    type: "text",
    fontSize: "14px",
    width: "200px",
    className: "text-input"
}

export default ImageUploadInput;