import React from "react";
import {connect} from "react-redux";
import axios from "axios";
import appConfig from "../appConfig";
import ContentLoader from "react-content-loader";
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";
import FeedbackMessage from "../components/FeedbackMessage";
import CTANoSearchResult from "../components/CTANoSearchResult";
import {DataReporter} from "../DataReporter";
import TextInput from "../components/inputs/TextInput";
import CreatedRecord from "../components/myRecords/CreatedRecord";
import "../styles/pages/myrecords.scss";
import {requestSetClaimingRecord, requestSetCurrentPage} from "../store/actions/app";

class MyRecords extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            recordsReceived: false,
            recordsComponents: [],
            recordsRootData: [],
            gettingRecords: false,
            pendingRecords: false,
            minFilterLength: 3,
            filterByName: "",
            errorMessage: null,
        }
    }

    async getRecords() {
        if (!this.state.gettingRecords) {
            this.setState({
                gettingRecords: true
            }, () => {
                try {
                    axios
                        .get(appConfig.currentConfig.backendApp.url + "/rootRecords/all",
                            {
                                headers: {
                                    Authorization: `Bearer ${appConfig.currentConfig.backendApp.tokens.standard}`,
                                    'Content-Type': 'application/json',
                                },
                                params: {
                                    originator: this.props.userReducer.email
                                }
                            }
                        )
                        .then(res => {
                            if (res.status === 200) {
                                let components = [];
                                let rootDatas = [];

                                for (let i = 0; i < res.data.result.length; i += 1) {
                                    rootDatas.push(res.data.result[i].rootRecord);

                                    components.push(
                                        <CreatedRecord
                                            rootRecord={res.data.result[i].rootRecord}
                                            CIDJson={res.data.result[i].rootRecord.rootRecordData}
                                            pending={true}
                                            key={i + 10}
                                        />
                                    );
                                }

                                this.setState({
                                    recordsComponents: components,
                                    recordsRootData: rootDatas,
                                    errorMessage: null,
                                    recordsReceived: true
                                }, () => {

                                    DataReporter.trackMixpanel(this.props, "Listing Records",
                                        { page: "My Records" }
                                    );
                                });
                            } else {
                                this.setState({
                                    errorMessage: "Error getting pending Records. Please try again."
                                }, () => {
                                    DataReporter.trackMixpanel(this.props,
                                        "pRR not found", {category: "Error"});
                                })
                            }
                        })
                        .catch(err => {
                            let displayErr;

                            if (err.message === "Network Error") {
                                displayErr = "Your pending Records couldn't be loaded. Please try again.";
                            } else {
                                displayErr = err.message;
                            }

                            this.setState({
                                errorMessage: displayErr
                            }, () => {

                                DataReporter.trackSentry(err, {
                                    extra: {additionalData: "getFromSQLDB"}
                                });
                            })
                        })

                } catch (err) {
                    this.setState({
                        errorMessage: "Data connection error. Please try again.",
                    }, () => {
                        DataReporter.trackMixpanel(this.props, `Error getting pRR from backend.`,
                            {category: "Error"});
                        DataReporter.trackSentry(err, {extra: {additionalData: "getRecords"}});
                    });
                }
            })
        }
    }

    getFilteredRecords() {
        if (this.state.filterByName.length >= this.state.minFilterLength) {
            DataReporter.trackMixpanel(this.props, "Filtering my Records", {
                category: "Display"
            });

            let s = this.state;
            let filtered = [];

            for (let i = 0; i < s.recordsRootData.length; i += 1) {
                let currName = s.recordsRootData[i].rootRecordData.name;
                if (s.filterByName.length > 0 && currName.toLowerCase().includes(s.filterByName.toLowerCase())) {
                    filtered.push(
                        <CreatedRecord
                            rootRecord={this.state.recordsRootData[i]}
                            CIDJson={this.state.recordsRootData[i].rootRecordData}
                            key={i}
                        />
                    );
                }
            }
            return filtered;
        }
        return this.state.recordsComponents;
    }

    // Events
    componentDidMount() {
        this.props.dispatchSetPage("myrecords");
        this.props.dispatchSetClaimingRecord(null);

        DataReporter.trackMixpanel(this.props, "Page view: My records", {
            pageDisplayMode: this.props.appReducer.mobileMode ? "mobile" : "desktop"
        });
    }

    onFilterChange(e) {
        this.setState({
            filterByName: e.inputValue
        });
    }

    // Renderers
    renderErrorMessage() {
        if (this.state.errorMessage !== null) {
            return <FeedbackMessage
                success={false}
                message={this.state.errorMessage}
            />;
        }
    }

    renderContentSpinner() {
        return (
            <div id={"content-spinner"}>
                <ContentLoader viewBox={"0 0 100 25"}
                               backgroundColor={"#dddbdb"}
                               foregroundColor={"#e87373"}
                               speed={1.5}>

                    <rect x={0} y={0} rx={1} ry={1} width={30} height={4}/>
                    <rect x={0} y={8} rx={1} ry={1} width={100} height={16}/>
                </ContentLoader>
            </div>
        );
    }

    renderFilter() {
        if (this.state.recordsReceived && this.state.recordsComponents) {
            return (
                <TextInput
                    id={"filter-text-input"}
                    label={"Filter by Records name"}
                    placeholder={"Record name"}
                    onChange={(e) => this.onFilterChange(e)}
                />
            );
        }
    }

    renderRecords() {
        let content = null;

        if (!this.state.recordsReceived) {
            content = this.renderContentSpinner();
            this.getRecords();
        } else {
            // Records received
            let recordCount = 0;
            if (this.state.recordsComponents) recordCount = this.state.recordsComponents.length;

            let records = this.getFilteredRecords();
            if ((!records || records.length === 0) && this.state.filterByName.length === 0) {
                records = <CTANoSearchResult
                    forMobile={this.props.appReducer.mobileMode}
                />;
            }

            content = (
                <div>
                    <h2 id={"h2"}>
                        You own<span id={"count"}>{recordCount}</span>Ventrace
                        Record{recordCount > 1 || recordCount === 0 ? "s" : ""}
                    </h2>

                    {this.renderFilter()}
                    <div id={"records-container"}>
                        {this.renderErrorMessage()}
                        {records}
                    </div>

                </div>
            );
        }

        let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";
        return (
            <div id={"my-records-container" + mobileSuffix}>
                <h1>Records owned by you</h1>
                {content}
            </div>
        );
    }

    render() {
        let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";

        if (!this.props.userReducer.loginToken) {
            window.open("/", "_self");
        } else {
            document.title = `Ventrace - My Records`;

            return (
                <div>
                    <Navigation/>
                    <div className={"content-container" + mobileSuffix}>
                        {this.renderRecords()}
                    </div>
                    <Footer
                        forMobile={this.props.appReducer.mobileMode}
                    />
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        appReducer: state.app,
        userReducer: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatchSetClaimingRecord: (claimingRecord) => {
            dispatch(requestSetClaimingRecord(claimingRecord));
        },
        dispatchSetPage: (pageName) => {
            dispatch(requestSetCurrentPage(pageName));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyRecords);