import React from "react";
import appConfig from "../../appConfig";
import {DateConverter} from "../../DateConverter";
import {BsCalendarWeek} from "react-icons/bs";
import {FaRegHandshake} from "react-icons/fa";
import "../../styles/components/details_transfer_confirmation.scss";
import {DataReporter} from "../../DataReporter";

class TransferConfirmation extends React.Component {
    clickOpenAddressExplorer(val) {
        let baseUrl = appConfig.explorerURLs.testnet.address;

        DataReporter.trackMixpanel(this.props, "TransConf. open address", {
            category: "Interaction",
        });

        window.open(baseUrl + val, "_blank");
    }

    render() {
        if (this.props.data) {
            let mobileSuffix = this.props.forMobile ? "-mobile" : "";

            let originatorAddress = this.props.data.originator;
            originatorAddress = originatorAddress.slice(0, appConfig.address_SliceLen)
                + "-" + originatorAddress.slice(-appConfig.address_SliceLen);

            let changeDateLabel = "Confirmed on";

            if (this.props.forMobile) {
                changeDateLabel = "";
            }

            return (
                <div className={"transfer-confirmation-container" + mobileSuffix}>
                    <div className={"record-content"}>

                        <div className={"l1 inline"}>
                            <p className={"record-type"}>
                                <FaRegHandshake className={"type-icon"} />
                                Transfer confirmed
                            </p>
                            <div className={"added-on-container"}>
                                <p className={"change-date"}>
                                    {changeDateLabel}&nbsp;
                                <span className={"data"}>
                                    <BsCalendarWeek className={"cal-icon"} />
                                    {DateConverter.dateObjToString(parseInt(this.props.data.recordCreationDate))}
                                </span>
                                </p>
                            </div>

                        </div>

                        <div className={"l2"}>
                            <div className={"originator"}>
                                <p className={"label"}>New owner</p>
                                <a className={"data"}
                                   onClick={() => this.clickOpenAddressExplorer(this.props.data.originator)}>
                                    {originatorAddress}
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
            )
        }
    }
}

export default TransferConfirmation;