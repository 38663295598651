import moment from "moment";

export class DateConverter {

    static stringIsValidDate(stringDate) {
        if (stringDate === null) return false;
        if (!stringDate.includes(".")) return false;
        if (stringDate.length < 8) return false;
        return moment.utc(stringDate, "DD.MM.yyyy").isValid();
    }

    static getUTCMilliseconds(date) {
        return Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
            date.getUTCDate(), date.getUTCHours(),
            date.getUTCMinutes(), date.getUTCSeconds());
    }

    static stringToDate(stringDate) {
        return moment.utc(stringDate, "MMM DD, YYYY");
    }

    static stringToDate_DDFormat(stringDate) {
        return moment.utc(stringDate).format("YYYY-MM-DD HH:mm:ss");
    }

    static dateObjToString(dateObj) {
        return moment.utc(dateObj).format("MMM DD, YYYY");
    }

}
