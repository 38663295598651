import React from "react";
import {connect} from "react-redux";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import {DataReporter} from "../DataReporter";
import "../styles/pages/gettingstarted.scss";
import {requestSetCurrentPage} from "../store/actions/app";

class GettingStarted extends React.Component {
    componentDidMount() {
        this.props.dispatchSetPage("gettingstarted");
        DataReporter.trackMixpanel(this.props, "Page view: Getting started", {
            pageDisplayMode: this.props.appReducer.mobileMode ? "mobile" : "desktop"
        });
    }

    onGetToolClick(url) {
        DataReporter.trackMixpanel(this.props, "Get MetaMask click", {
            category: "Interaction"
        });

        window.open(url, "_blank");
    }

    // Renderers
    renderGetTool(logoPath, text, openURL) {
        return (
            <div className={"get-tool-container"} onClick={() => this.onGetToolClick(openURL)}>
                <div className={"inline"}>
                    <img className="logo" src={process.env.PUBLIC_URL + logoPath} alt={"logo"}/>
                    <p className={"text"}>{text}</p>
                </div>

            </div>
        );
    }

    renderExplainerImage() {
        if (this.props.appReducer.mobileMode) {
            return (
                <img id={"getting-started-explainer"}
                     src={process.env.PUBLIC_URL + '/assets/GettingStarted_mobile.png'}
                />
            );
        } else {
            return (
                <img id={"getting-started-explainer"}
                     src={process.env.PUBLIC_URL + '/assets/GettingStarted.png'}
                />
            );
        }
    }

    renderTutorialVideo() {
        let width = 869;
        let height = 504;

        if (this.props.appReducer.mobileMode) {
            width = "100%";
            height = 200;
        }

        return (
            <iframe
                id={"yt-video"}
                width={width}
                height={height}
                src="https://www.youtube.com/embed/GY61g6zGVIQ?si=sFNAl3TYkoi4M2yP"
                title="YouTube video player" frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin" allowFullScreen>
            </iframe>
        );
    }

    render() {
        let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";

        document.title = "Ventrace - Getting started";

        return (
            <div>
                <Navigation />
                <div
                    className={"content-container" + mobileSuffix}
                    id={"getting-started-container" + mobileSuffix}
                >

                    <h1 id={"h1-gs"}>Getting ready for Ventrace</h1>

                    {this.renderExplainerImage()}
                    {this.renderTutorialVideo()}

                    <div>
                        <h2 className={"h2c"}>What is a crypto wallet and why?</h2>
                        <p className={"pc"}>
                            A crypto wallet allows you to safely own digital objects online.
                            Most crypto wallets can be created for free and anonymously.
                            <br/><br/>
                            Crypto wallets also have unique public addresses, which are relevant for Ventrace Records.
                            This is because Ventrace will link every ordered Record to the address of the connected wallet <b>only</b>.
                            <br/><br/>
                            This means that only you as the owner of your wallet can update or manage your Ventrace Records.
                        </p>
                    </div>

                    <div>
                        <h2 className={"h2c"}>Get a free and safe crypto wallet</h2>
                        <p className={"pc"}>
                            There are many crypto wallets you can choose from.
                            A very popular and well-rounded crypto wallet is MetaMask. It's free and easy to use.
                        </p>
                        {this.renderGetTool(
                            "/metamask-logo.png",
                            "Get MetaMask from the official website",
                            "https://metamask.io/")}
                    </div>

                    <div className={"explanation-section"}>
                        <h2 className={"h2c"}>Connect to Ventrace</h2>
                        <p className={"pc"}>
                            Once you have configured your wallet, you're ready to connect to Ventrace.
                            Reload your browser or open a new tab and go to <a href={"https://www.ventrace.com/"}>www.ventrace.com</a>.
                            <br/><br/>

                            Click the red "Connect wallet" button at the top right of Ventrace.
                            Once you're connected to Ventrace, your detected wallet address will be displayed at the top right
                            (where the connect button was).
                        </p>
                    </div>


                </div>
                <Footer
                    forMobile={this.props.appReducer.mobileMode}
                />
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        appReducer: state.app
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatchSetPage: (pageName) => {
            dispatch(requestSetCurrentPage(pageName));
        },
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(GettingStarted);