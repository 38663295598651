import React from "react";
import "../../styles/components/search_infobox.scss";
import "../../styles/components/environment_section.scss";

class EnvironmentSection extends React.Component {
    render() {
        let mobileSuffix = this.props.forMobile ? "-mobile" : "";

        return (
            <div className={"environment-package" + mobileSuffix}>
                <div id={"content"}>
                    <p id={"headline"}>Good for our planet</p>
                    <p id={"descr"}>
                        By using Ventrace, you contribute to saving the environment
                        by not throwing away watches that others could still use. Producing less
                        waste can help prevent tons of toxic materials from finding their way into the earth, air, and water.
                    </p>

                    <div id={"planet-img-container"}>
                        <img
                            id="planet-img"
                            src={process.env.PUBLIC_URL + '/assets/Planet.png'}
                            alt={"Planet"}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default EnvironmentSection;