import React from "react";
import {AiOutlineCheck,AiOutlineWarning} from "react-icons/ai"
import "../styles/feedbackMessage.scss";

class FeedbackMessage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: true
        }
    }

    onUserClick() {
        this.setState({show: !this.state.show});
    }

    renderSuccess() {
        let centered = this.props.centered ? "centered" : "";
        return (
            <div className={`feedback success ${centered}`}
                onClick={(e) => this.onUserClick(e)}>
                <p>
                    <AiOutlineCheck className={"text-icon"}/>
                    {this.props.message}
                </p>
            </div>
        );
    }

    renderError() {
        let centered = this.props.centered ? "centered" : "";
        return (
            <div className={`feedback error ${centered}`}
                onClick={(e) => this.onUserClick(e)} >
                <p>
                    <AiOutlineWarning className={"text-icon"}/>
                    {this.props.message}
                </p>
            </div>
        );
    }

    render() {
        if (this.state.show) {
            if (this.props.success) return this.renderSuccess();
            else return this.renderError();
        }
        return(<div></div>);
    }
}

export default FeedbackMessage;