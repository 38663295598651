import React from "react";
import { withRouter } from "react-router-dom";
import "../../styles/inputs/userButton.scss"

class UserButton extends React.Component {
    clickHandler(e) {
        if (this.props.onClick)
            this.props.onClick(e);
        if (this.props.path)
            window.open(this.props.path, this.props.target);
    }

    render() {
        let mobileSuffix = this.props.forMobile ? "-mobile" : "";

        return (
            <div>
                <button
                    disabled={this.props.disabled}
                    className={this.props.className + mobileSuffix}
                    id={this.props.id}
                    type='button'
                    value={this.props.value}
                    onClick={(e) => this.clickHandler(e)}
                    style={{marginTop: this.props.marginTop, width: this.props.width}}
                >
                    {this.props.icon}
                    {this.props.value}
                </button>
            </div>
        )
    }
}

UserButton.defaultProps = {
    className: "user-button",
    disabled: false
}

export default withRouter (UserButton);