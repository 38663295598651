import React from "react";
import {connect} from "react-redux";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import {DataReporter} from "../DataReporter";
import "../styles/pages/privacypolicy.scss";
import {requestSetCurrentPage} from "../store/actions/app";

class PrivacyPolicy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.dispatchSetPage("privacypolicy");
        DataReporter.trackMixpanel(this.props, "Page view: Privacy policy", {
            pageDisplayMode: this.props.appReducer.mobileMode ? "mobile" : "desktop"
        });
    }

    render() {
        let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";
        return (
          <div>
              <Navigation />
              <div
                  className={"content-container" + mobileSuffix}
                  id={"privacy-policy" + mobileSuffix}
              >
                  <h1>Privacy Policy</h1>

                  <h2>1. Introduction</h2>
                  <h3>1.1 Information Collected</h3>
                  <p className={"paragraph"}>
                      When a customer of Ventrace is submitting his/her order of a Ventrace Record,
                      then Ventrace is collecting the customer’s first- and last name, e-mail address,
                      selected payment method and optionally the customer’s address of the residence when provided.
                      <br/><br/>
                      Aside from the ordering process, Ventrace is collecting data regarding the user’s behaviour
                      on the Ventrace platform as a whole. This includes page views, button clicks and input selections
                      such as drop-downs or checkboxes. This collection is completely anonymous and can not be
                      re-traced to a specific user.
                      <br/><br/>
                      Ventrace is using the user tracking service Mixpanel (https://www.mixpanel.com) for the
                      common tracking UX events and Sentry (https://www.sentry.io) in case of technical errors.
                      <br/><br/>
                      For both of these third party services, Ventrace is collecting and sending tracking data in the background while the user is navigating on Ventrace.
                  </p>


                  <h3>1.2 Purpose of Collection</h3>
                  <p className={"paragraph-section-h"}>1.2.1 Order information</p>
                  <p className={"paragraph"}>
                      Ventrace is collecting the customer information in order to send relevant
                      communications to the given email-address and to verify the used payment method by
                      comparing matching data (in case of errors or warnings from the payment provider).
                  </p>

                  <p className={"paragraph-section-h"}>1.2.2 User interactions</p>
                  <p className={"paragraph"}>
                      Ventrace is collecting anonymous data regarding the user’s interaction on Ventrace in
                      order to measure the general performance of the platform, such as the conversions from
                      one page to another or how many times a certain button as been used on a specific page.
                      This data is then used to improve the UX of Ventrace.
                  </p>

                  <h3>1.3 Consent</h3>
                  <p className={"paragraph"}>
                      When a user is visiting Ventrace for the first time (or when he/she has deleted all cookies
                      for the Ventrace website), then Ventrace will display a clearly visible consent banner at the
                      bottom of the screen on the user’s device. The consent banner will remain visible until the
                      user takes a decision on accepting the privacy policy.
                  </p>

                  <h2>2. Ventrace Records</h2>
                  <h3>2.1 Ventrace Records Data</h3>
                  <p className={"paragraph"}>
                      The initial product details of a Ventrace Record (such as product name, description, serial
                      number, etc.) will be uploaded to a decentralized database managed by Infura IPFS
                      (https://www.infura.io/product/ipfs). Once the product details have been uploaded to Infura,
                      Ventrace will then create the actual Record on the public Ethereum blockchain.
                      <br/><br/>
                      For the Record creation on the blockchain, Ventrace will use the following details:
                      <ul>
                          <li>The creation time and date of the Record</li>
                          <li>The crypto wallet address of the customer / owner</li>
                          <li>The link to the uploaded IPFS file</li>
                          <li>The count of added Record updated (initially zero)</li>
                          <li>The address of the Record itself</li>
                          <li>A state which will indicate if the Record has premium features enabled</li>
                      </ul>

                      This procedure is secured by various validation features to ensure that only Ventrace can
                      initialize and execute a Record creation.
                  </p>

                  <h3>2.2 Record Updates</h3>
                  <p className={"paragraph"}>
                      When a Ventrace Record owner is submitting a Record update, then Ventrace is bundling the
                      given details into a single structure to upload them first to infura IPFS (see previous section
                      for more details). After the bundled structure has been uploaded to IPFS, Ventrace will then
                      contact its own Ethereum smart contracts to store the Record update onto the blockchain.
                      For the creation of the Record update, Ventrace will use the following details:
                      <br/><br/>

                      <ul>
                          <li>The creation time and date of the Record update</li>
                          <li>The ID of the Record (where the update will be linked to)</li>
                          <li>The crypto wallet address of the customer / owner</li>
                          <li>The link to the uploaded IPFS file</li>
                      </ul>

                      This procedure is secured by various validation features to ensure that only Ventrace
                      can initialize a Record update creation.
                  </p>

                  <h3>2.3 Data Ownership</h3>
                  <p className={"paragraph"}>
                      The owner of a Ventrace Record is determined by the connected crypto wallet to the Ventrace
                      platform. As described in the earlier sections, Ventrace is storing all Records and Record
                      updates with the address of the customer’s crypto wallet.
                      <br/><br/>
                      The specific Ethereum smart contracts which are developed and maintained by Ventrace only
                      allow changes to Ventrace Records, when the change-applying crypto wallet address is exactly
                      the same as the stored address on the Ventrace Record itself.
                      <br/><br/>
                      Therefore, only the owner of the crypto wallet can manage a Ventrace Record, which is
                      ultimately only the customer.
                  </p>

                  <h2>3. Ordering Process</h2>
                  <h3>3.1 Crypto Wallet</h3>
                  <p className={"paragraph"}>
                      Ventrace is assuming that the connected crypto wallet (to Ventrace) is legally owned by
                      the ordering customer. If, however, Ventrace receives proven reports that a Ventrace
                      Record was ordered with a stolen crypto wallet (through access misuse or hacking),
                      then Ventrace will activate moderation on the respective Record to hide it from public
                      view permanently.
                  </p>

                  <h3>3.1 Order Communication</h3>
                  <p className={"paragraph"}>
                      Ventrace will send all order-related communications primarily via email to the
                      email address which was supplied during the ordering process.
                  </p>

              </div>
              <Footer
                  forMobile={this.props.appReducer.mobileMode}
              />
          </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        appReducer: state.app
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatchSetPage: (pageName) => {
            dispatch(requestSetCurrentPage(pageName));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);
