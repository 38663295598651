// App settings
const VTRdecimals = 10 ** 5;
const useMainnet = false;
const onDev = false;
const playVideos = true;
const address_SliceLen = 7;
const approvalMultiply = 100000;
const supportEmail = "onur.karaman@ventrace.com";

const personas = {
    watches: "w"
};

// Keys
const ganache_OwnerKey = "0xa2ce95edf326017ad2ee84e33d79e3fdc1bc4660f706472d4c3c66effdecbb07";
const devWalletPrivateKey = "3fb4567055178af2a823493a7f542c7242a647f83604c629e2a2c743c3d93d26";
const IPFS_Key = "9250c7829aba9c3bbcb61821185492a3";
const IPFS_ProjectID = "2GJzNm7jqG2sGv40HbNuMTe6FUZ";
const WalletConnect_ProjectID = "102513de5b3e6c3cdad1405651a69baf";
const SentryDSN = "https://6df86494a15c49b3abb19564153e5a34@o98745.ingest.sentry.io/4505340321267712";
const googleTagManagerID = "GTM-TFQWT3S";
const GAMeasurementID = "G-HXC5ENW6SC";
const GTMid = "GTM-TFQWT3S";
const mixpanelToken = "49c38c873ff4513739fcc18522e4fba8";

// Addresses
const ganache_nodeAddress = "HTTP://192.168.0.114:7545";
const Infura_APIKey = "2GJzNm7jqG2sGv40HbNuMTe6FUZ";

const Infura_TestnetURL = "https://polygon-amoy.infura.io/v3/2GJzNm7jqG2sGv40HbNuMTe6FUZ";
const Infura_PolygonMainURL = "https://polygon-mainnet.infura.io/v3/2GJzNm7jqG2sGv40HbNuMTe6FUZ";
const mainnetPublicRPC = "https://polygon-bor-rpc.publicnode.com";
const testnetPublicRPC = "https://polygon-amoy-bor-rpc.publicnode.com";
const IPFS_Endpoint = "https://ipfs.infura.io:5001";
const IPFS_ProjectURL = "https://ventrace.infura-ipfs.io/ipfs/";


const AddRecordFrequency = {
    oneInlastHourUnit: "seconds",
    oneInlastHourTime: 30,
    severalInLastHourUnit: "seconds",
    severalInLastHourTime: 30,
    manyInLastHourUnit: "minutes",
    manyInLastHourTime: 2,
}
const AddExtFrequency = {
    oneInlastHourUnit: "seconds",
    oneInlastHourTime: 30,
    severalInLastHourUnit: "seconds",
    severalInLastHourTime: 30
}

// DeploymentConfigs
const testnetConfig = {
    blockchainName: "Polygon (Ventrace)",
    currentDomain: "https://www.ventrace.com",
    web3Path: "https://polygon-amoy.infura.io/v3/2GJzNm7jqG2sGv40HbNuMTe6FUZ",
    chainID: 80002,
    blockchainGasLimit: 6721975,
    trackAnalytics: true,
    logDebug: false,
    useEIP712Signing: false,
    contractDeploySleepTime: 5000,
    contractRunFunctionsSleepTime: 6500,
    contractRunFunctionsGasPrice: 280000000000,
    explorerURLs: {
        trx: "https://www.oklink.com/amoy/tx/",
        address: "https://www.oklink.com/amoy/address/"
    },
    contracts: {
        address_VTR_Token: "0x4fE4eC738122fe9DF3C03C13bF024B92f00a91c3",
        address_VTR_Bonus: "0x6AC4cF8B7d927D7a93ffbA631706b0B353bfCaed",
        address_RecordManager: "0x842E4df9dCFfA17b273BEdcC53a5619E587774af",
        address_VTR_Manager: "0x22ede5a02b2EBE7326ac6fEd9ceD755F0355E661",
        address_RecordStructs: "0x6d8f20271E9f3745fB427a00EA93c792B541Ff86",
        address_RecordStorage: "0xA1ccA6DaF106e0a1C0b91AE702c44727bE6C7182",
        address_RecordValuator: "0xF7D1C3FC11c3d0840080a265cBE2A7Fd23dF2fc7",
        address_Disputes: "0x03978A252E28B549Bbd0497CAC796704E3971664",
        address_RecordValidator: "0x0e54e6EF1EC2a4074f6523289cF17DB7283bcAA2",
        address_Helpers: "0x78E256ded00c494bB930479c51f935ad3D8aBEfA",
        address_VerifiedOwners: "0x4244e8C3F4a68E9Cda9c96A601702611E6a60a3e"
    },
    backendApp: {
        url: "https://ventrace-backend-374d1b06b999.herokuapp.com",
        tokens: {
            standard: "1189f3g866eb45479448789aafa23172",
            lossNoticeQuery: "0Ooiedvn13_D.138!9",
            provider: 'u6&4RSYb?&2vxf$3Y4.]c_a1'
        }
    }
}

const devConfig = {
    blockchainName: "Ganache",
    currentDomain: "http://localhost:3000",
    chainID: 1337,
    blockchainGasLimit: 6721975,
    trackAnalytics: false,
    logDebug: true,
    useEIP712Signing: false,
    contractDeploySleepTime: 500,
    contractRunFunctionsSleepTime: 500,
    contractRunFunctionsGasPrice: 280000000000,
    explorerURLs: {
        trx: "https://www.oklink.com/amoy/tx/",
        address: "https://www.oklink.com/amoy/address/"
    },
    contracts: {
        address_VTR_Token: "0x952B6A594790eC4bB1aA3ED347d6630104152744",
        address_VTR_Bonus: "0x0502e5408476109f6805660B07B0B8BaBBb8e68C",
        address_RecordManager: "0x937b0BA9b5922d25FD374da437F5504AbDf116bd",
        address_VTR_Manager: "0x5E5939e0B2D9927Da8f512e697E28c9dDA315CFf",
        address_RecordStructs: "0xFF050C7e3c7125934B91c121c55342F492183F7e",
        address_RecordStorage: "0x3ac60d86b2b28DEE7F13F4c136B5Da48980840E0",
        address_RecordValuator: "0x48e81e0ceCeC3A0E938E388804e47e64f72e95d0",
        address_Disputes: "0x4826B22e97C594563F0D98E9e4B2F3f2D882efD9",
        address_RecordValidator: "0x9c4e5d0f3742633e068263B8fFB28a8c0856cB1e",
        address_Helpers: "0x521F0Ba00d2F9F0A8701E65eEE8345733C9D22a1",
        address_VerifiedOwners: "0xf7c6b4453F828FAde2F225a3c7a563a770953254"
    },
    backendApp: {
        url: "http://localhost:5005",
        tokens: {
            standard: "1189f3g866eb45479448789aafa23172",
            lossNoticeQuery: "0Ooiedvn13_D.138!9",
            provider: 'u6&4RSYb?&2vxf$3Y4.]c_a1'
        }
    }
}


module.exports = {
    Infura_APIKey,
    Infura_MumbaiURL: Infura_TestnetURL,
    Infura_TestnetURL,
    Infura_PolygonMainURL,
    IPFS_Key,
    IPFS_ProjectID,
    IPFS_Endpoint,
    IPFS_ProjectURL,
    WalletConnect_ProjectID,
    ganache_OwnerKey,
    ganache_nodeAddress,
    googleTagManagerID,
    devWalletPrivateKey,
    VTRdecimals,
    onDev,
    useMainnet,
    SentryDSN,
    supportEmail,
    AddRecordFrequency,
    AddExtFrequency,
    GAMeasurementID,
    GTMid,
    mixpanelToken,
    testnetConfig: testnetConfig,
    devConfig,
    currentConfig: onDev? devConfig : testnetConfig,
    playVideos,
    address_SliceLen,
    approvalMultiply,
    personas,
    testnetPublicRPC,
    mainnetPublicRPC
}