import React from "react";
import appConfig from "../../appConfig";
import Mixpanel from "mixpanel-browser";
import {DateConverter} from "../../DateConverter";
import {BsCalendarWeek} from "react-icons/bs";
import {AiOutlineEyeInvisible} from "react-icons/ai";
import "../../styles/components/details_private_mode_extension.scss";
import {DataReporter} from "../../DataReporter";

class PrivateModeExtension extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    onClickCreatorAddress(val) {
        DataReporter.trackMixpanel(this.props, "Opening private mode creator address", {
            category: "Interaction",
        });

        let baseUrl = appConfig.explorerURLs.testnet.address;
        window.open(baseUrl + val, "_blank");
    }

    render() {
        if (this.props.data) {
            let mobileSuffix = this.props.forMobile ? "-mobile" : "";

            let originatorAddress = this.props.data.originator;
            originatorAddress = originatorAddress.slice(0, appConfig.address_SliceLen)
                + "-" + originatorAddress.slice(-appConfig.address_SliceLen);

            let changeDateLabel = "Activated on";
            if (this.props.forMobile) {
                changeDateLabel = "On";
            }

            return (
                <div className={"private-mode-extension-container" + mobileSuffix}>
                    <div className={"record-content"}>
                        <div className={"l1 inline"}>
                            <p className={"record-type"}>
                                <AiOutlineEyeInvisible className={"type-icon"}/>
                                Private mode
                            </p>
                            <div className={"added-on-container"}>
                                <p className={"change-date"}>
                                    {changeDateLabel}&nbsp;
                                    <span className={"data"}>
                                    <BsCalendarWeek className={"cal-icon"}/>
                                        {DateConverter.dateObjToString(parseInt(this.props.data.recordCreationDate))}
                                </span>
                                </p>
                            </div>
                        </div>

                        <div className={"l2"}>
                            <p className={"originator"}>Creator
                                <a className={"data"}
                                   onClick={() => this.onClickCreatorAddress(this.props.data.originator)}>
                                    {originatorAddress}
                                </a>
                            </p>
                        </div>

                        <div className={"l3"}>
                            <div className={"descr-container"}>
                                <p className={"descr-label"}>System message</p>
                                <p className={"descr-val"}>
                                    Activated private mode for
                                    <span className={"month-count"}>
                                {this.props.data.privateMonthCount}
                            </span> months.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            );
        }
    }
}

export default PrivateModeExtension;