import * as types from "../actionTypes/blockchain";

const initState = {
    loading: false,
    web3: null,
    recordManagerContract: null,
    recordStorageContract: null,
    vtrTokenContract: null,
    vtrBonusContract: null,
    vtrManagerContract: null,
    disputeContract: null,
    recordValuatorContract: null,
    verifiedOwnersContract: null,
    vtrManagerCallEstimates: [],
    recordManagerCallEstimates: [],
    valueReportedDamages: null,
    valueModifications: null,
    valueOthers: null,
    valueWeekAge: null,
    sessionTrx: [],
    contractsReady: false,
    walletReady: false
}

export default function blockchain(state = initState, payload) {
    switch (payload.type) {
        case types.BLOCKCHAIN_LOADING: {
            return {
                ...state,
                loading: true
            }
        }
        case types.BLOCKCHAIN_LOADING_STOP: {
            return {
                ...state,
                loading: false
            }
        }
        case types.SET_WEB3: {
            return {
                ...state,
                web3: payload.data
            }
        }
        case types.SET_RECORD_MANAGER_CONTRACT: {
            return {
                ...state,
                recordManagerContract: payload.data,
            }
        }
        case types.SET_RECORD_STORAGE_CONTRACT: {
            return {
                ...state,
                recordStorageContract: payload.data,
            }
        }
        case types.SET_VTR_TOKEN_CONTRACT: {
            return {
                ...state,
                vtrTokenContract: payload.data
            }
        }
        case types.SET_VTR_BONUS_CONTRACT: {
            return {
                ...state,
                vtrBonusContract: payload.data
            }
        }
        case types.SET_VTR_MANAGER_CONTRACT: {
            return {
                ...state,
                vtrManagerContract: payload.data
            }
        }
        case types.SET_DISPUTE_CONTRACT: {
            return {
                ...state,
                disputeContract: payload.data
            }
        }
        case types.SET_VALUATOR_CONTRACT: {
            return {
                ...state,
                recordValuatorContract: payload.data
            }
        }
        case types.SET_VERIFIED_OWNERS_CONTRACT: {
            return {
                ...state,
                verifiedOwnersContract: payload.data
            }
        }
        case types.ADD_SESSION_TRX: {
            let _sessionTrx = initState.sessionTrx;
            _sessionTrx.push(payload.data);

            return {
                ...state,
                loading: false,
                sessionTrx: _sessionTrx
            }
        }
        case types.SET_CONTRACTS_READY: {
            return {
                ...state,
                contractsReady: payload.data,
            }
        }
        case types.SET_WALLET_READY: {
            return {
                ...state,
                walletReady: payload.data,
            }
        }
        default:
            return state;
    }
}