import React from "react";
import {connect} from "react-redux";
import "../styles/components/formstepsindicator.scss"

class FormStepsIndicator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    // Renderers
    renderSteps() {
        let steps = [];
        for (let i=0; i<this.props.steps.length; i+=1) {

            let stepBarClass = "passive";
            if (this.props.activeStep === (i+1)) stepBarClass = "active";
            if (this.props.activeStep > (i+1)) stepBarClass = "done";

            steps.push(
                <div className={"step"}>
                    <div className={"inline"}>
                        <p className={"nr"}>{i+1}</p>

                        <div className={"step-content"}>
                            <p className={`label ${stepBarClass}`}>{this.props.steps[i]}</p>
                            <div className={`step-bar ${stepBarClass}`} />
                        </div>
                    </div>
                </div>
            )
        }
        return steps;
    }

    render() {
        let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";
        return (
            <div className={"form-steps-container" + mobileSuffix}>
                <div className={"steps"}>
                    {this.renderSteps()}
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        appReducer: state.app,
    }
}

export default connect(mapStateToProps, null)(FormStepsIndicator);