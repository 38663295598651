import React from "react";
import {connect} from "react-redux";
import {create} from "ipfs-http-client";
import Logger from "js-logger";
import appConfig from "../appConfig";
import Mixpanel from "mixpanel-browser";
import * as Sentry from "@sentry/react";
import {EIP712types} from "../EIP712types";
import moment from "moment/moment";
import UserButton from "./inputs/UserButton";
import FeedbackMessage from "./FeedbackMessage";
import TextInput from "./inputs/TextInput";
import TextArea from "./inputs/TextArea";
import HintContainer from "./HintContainer";
import SidebarExplainer from "./SidebarExplainer";
import VTRCalculator from "./VTRCalculator";
import TRXReceipt from "./TRXReceipt";
import DatePicker from "react-datepicker";
import {DateConverter} from "../DateConverter";
import {requestSetBlockchainLoading, requestSetBlockchainLoadingStop} from "../store/actions/blockchain";
import {GiHouseKeys} from "react-icons/gi";
import {BsCalendarCheck} from "react-icons/bs";
import {DataReporter} from "../DataReporter";
import "../styles/components/ownershipchanger.scss";

class OwnershipChanger extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            minInputLength: 5,
            nextOwnerWalletAddress: null,
            nextOwnerWalletAddressValid: true,
            nextOwnerWalletAddressValidMsg: null,
            changeDate: Date.now(),
            changeDateValid: true,
            changeDateValidMsg: null,
            optionalComment: null,
            optionalCommentValid: true,
            submissionSuccessful: null,
            trxReceipt: null,
            confirmingTransfer: false,
            submitting: false,
            receiptDescr: null,
            estimatedGasUse: null,
            errorMessage: null
        }
    }

    getExplainerText() {
        return [
            {
                "name": "Beginning the transfer process",
                "descr": "By submitting this form you will initiate the transferring process." +
                    " Your 'opening' will be stored on the blockchain and will be visible only to the designated" +
                    " next owner of this record."
            },
            {
                "name": "Reviewing the transfer",
                "descr": "Only the next owner will be able to see your transfer opening. To do so, the next"
                    + " owner will have to navigate to your (this) Record's page. Ventrace will then display an info-box"
                    + " containing options to confirm or decline the transfer."
            },
            {
                "name": "Confirming the transfer",
                "descr": "If the next owner confirms the transfer, Ventrace will appoint him or her as the official owner."
                    + " Also, the both of you will receive VTR rewards for handling the transfer."
            }
        ];
    }

    getLastOpeningExtension() {
        let latest = null;
        for (let i = 0; i < this.props.completeRecordData.length; i += 1) {
            let curr = this.props.completeRecordData[i];
            if (curr.data.recordType === "ownershipTransferOpening") {
                latest = this.props.recordReceipt[i];
            }
        }
        return latest;
    }

    getOpeningExtensionJSON() {
        return {
            "recordType": "ownershipTransferOpening",
            "recordCreationDate": Date.now().toString(),
            "originator": this.props.walletReducer.connectedAddress,
            "nextOwnerWalletAddress": this.state.nextOwnerWalletAddress.trim().toLowerCase(),
            "nextOwnerWalletAddress_Display": this.state.nextOwnerWalletAddress.trim(),
            "optionalComment": this.state.optionalComment ? this.state.optionalComment.trim() : null,
            "changeDate": this.state.changeDate,
        }
    }

    getConfirmationExtensionJSON() {
        return {
            "recordType": "ownershipTransferConfirmation",
            "recordCreationDate": Date.now().toString(),
            "originator": this.props.walletReducer.connectedAddress,
        }
    }

    isValidToSave() {
        let canSave = true;
        this.setState({
            nextOwnerWalletAddressValid: true,
            nextOwnerWalletAddressValidMsg: null,
            changeDateValid: true,
            optionalCommentValid: true
        }, () => {
            DataReporter.trackMixpanel(this.props, "Submitting transfer opening", {
                category: "Interaction"
            });

        });

        if (!this.props.blockchainReducer.web3.utils.isAddress(this.state.nextOwnerWalletAddress)) {
            this.setState({
                nextOwnerWalletAddressValid: false,
                nextOwnerWalletAddressValidMsg: "The given address in invalid or wrong."
            }, () => {
                DataReporter.trackMixpanel(this.props, "Error: Invalid next owner address", {
                    category: "Interaction"
                });

            });
            canSave = false;
        }

        if (this.props.walletReducer.connectedAddress === this.state.nextOwnerWalletAddress) {
            this.setState({
                nextOwnerWalletAddressValid: false,
                nextOwnerWalletAddressValidMsg: "You can't use your own address."
            }, () => {
                DataReporter.trackMixpanel(this.props, "Error: Tried to use own address", {
                    category: "Interaction"
                });
            });
            canSave = false;
        }

        if (this.state.optionalComment !== null) {
            if (this.state.optionalComment.length < this.state.minInputLength) {
                this.setState({optionalCommentValid: false}, () => {
                    DataReporter.trackMixpanel(this.props, "Error: Optional comment too short", {
                        category: "Interaction"
                    });

                });
                canSave = false;
            }
        }

        if (this.state.changeDate === null) {
            this.setState({
                changeDateValid: false,
                changeDateValidMsg: "Pick a date."
            }, () => {
                DataReporter.trackMixpanel(this.props, "Error: No transfer date selected", {
                    category: "Interaction"
                });

            });
            canSave = false;
        }

        let latest = this.props.completeRecordData[this.props.completeRecordData.length - 1];
        let latestDate;
        if (latest.data.recordType === "rootRecord") latestDate = moment(parseInt(latest.data.purchaseDate));
        else latestDate = moment(parseInt(latest.data.changeDate));

        if (moment(latestDate).isAfter(this.state.changeDate)) {
            this.setState({
                changeDateValid: false,
                changeDateValidMsg: "The transfer cannot be dated before the latest extension on record."
            }, () => {
                DataReporter.trackMixpanel(this.props, "Error: Transfer before previous extension", {
                    category: "Interaction"
                });
            });
            canSave = false;
        }

        return canSave;
    }

    submitOpeningForm() {
        if (this.isValidToSave()) {
            if (!this.state.submitting) {
                this.setState({submitting: true}, async () => {
                    this.props.dispatchBlockchainLoading();

                    // Upload data as JSON to IPFS
                    let auth = "Basic " + Buffer.from(appConfig.IPFS_ProjectID + ":" + appConfig.IPFS_Key).toString("base64");
                    let client = create({url: appConfig.IPFS_Endpoint, headers: {authorization: auth}});
                    let added = await client.add(JSON.stringify(this.getOpeningExtensionJSON()));

                    // Send JSON of new RootRecord to blockchain
                    let recordManagerContract = this.props.blockchainReducer.recordManagerContract;
                    let extType = this.getOpeningExtensionJSON().recordType;

                    try {
                        let signature = await window.ethereum.request({
                            method: 'eth_signTypedData_v4',
                            params: [this.props.walletReducer.connectedAddress,
                                JSON.stringify(EIP712types.newExtension(
                                    this.props.rootRecordID, extType))
                            ],
                        });

                        let rsv = EIP712types.getRSVfromSignature(signature);

                        recordManagerContract.methods.addExtensionRecord(this.props.rootRecordID, added.path,
                            extType, this.state.nextOwnerWalletAddress.toLowerCase(), 0, rsv.r, rsv.s, rsv.v)
                            .send({from: this.props.walletReducer.connectedAddress, gas: this.state.estimatedGasUse})
                            .then((receipt) => {
                                this.props.dispatchBlockchainLoadingStop();
                                this.onSubmissionSuccessful(receipt, "Started ownership transfer");

                                DataReporter.trackMixpanel(this.props, "Success: Transfer opening", {
                                    category: "Interaction"
                                });
                            })
                            .catch((err) => {
                                this.setState({
                                    errorMessage: "Error during transfer opening. Please try again later.",
                                    submitting: false
                                }, () => {
                                    this.props.dispatchBlockchainLoadingStop();

                                    DataReporter.trackSentry(err, {extra: {additionalData: "OwnershipChanger: Add opening Ext."}});
                                    DataReporter.trackMixpanel(this.props, "Error: Transfer opening",
                                        { category: "Interaction" });
                                });
                            })

                    } catch (err) {
                        this.props.dispatchBlockchainLoadingStop();

                        let msg = "Signing failed. Please check if your wallet is configured correctly.";
                        if (err.message.toLowerCase().includes("chainid")) {
                            msg = `Signing failed. Please check if your wallet is connected to the ${appConfig.currentConfig.blockchainName} network.`;
                        }


                        this.setState({
                            errorMessage: msg,
                            submitting: false
                        }, () => {
                            DataReporter.trackSentry(err, { extra: {additionalData: "OwnershipChanger: Signing transfer opening."}});
                            DataReporter.trackMixpanel(this.props, "Error signing transf. opening",
                                { category: "Interaction" });
                        });
                    }
                });
            }

        }
    }

    submitConfirmationForm() {
        if (!this.state.submitting) {
            this.setState({submitting: true},
                async () => {
                    this.props.dispatchBlockchainLoading();

                    // Upload data as JSON to IPFS
                    let auth = "Basic " + Buffer.from(appConfig.IPFS_ProjectID + ":" + appConfig.IPFS_Key).toString("base64");
                    let client = create({url: appConfig.IPFS_Endpoint, headers: {authorization: auth}});
                    let added = await client.add(JSON.stringify(this.getConfirmationExtensionJSON()));

                    // Send JSON of new RootRecord to blockchain
                    let recordManagerContract = this.props.blockchainReducer.recordManagerContract;
                    let extType = this.getConfirmationExtensionJSON().recordType;

                    try {
                        let signature = await window.ethereum.request({
                            method: 'eth_signTypedData_v4',
                            params: [this.props.walletReducer.connectedAddress,
                                JSON.stringify(EIP712types.newExtension(
                                    this.props.rootRecordID, extType))
                            ],
                        });

                        let rsv = EIP712types.getRSVfromSignature(signature);

                        recordManagerContract.methods.addExtensionRecord(this.props.rootRecordID, added.path,
                            extType, "", parseInt(this.getLastOpeningExtension()[0]), rsv.r, rsv.s, rsv.v)
                            .send({
                                from: this.props.walletReducer.connectedAddress,
                                gas: this.state.estimatedGasUse
                            })
                            .then((receipt) => {
                                DataReporter.trackMixpanel(this.props, "Success: Transfer confirmation", {
                                    category: "Interaction"
                                });

                                this.onSubmissionSuccessful(receipt);
                            })
                            .catch((err) => {
                                this.setState({
                                    errorMessage: "Error during ownership confirmation (2). Please try again later.",
                                    submitting: false
                                }, () => {
                                    this.props.dispatchBlockchainLoadingStop();

                                    DataReporter.trackSentry(err, {
                                        extra: {additionalData: "OwnershipChanger: Adding transfer confirm ext."}
                                    });
                                });
                            })
                    } catch (err) {
                        this.props.dispatchBlockchainLoadingStop();

                        let msg = "Signing failed. Please check if your wallet is configured correctly.";
                        if (err.message.toLowerCase().includes("chainid")) {
                            msg = `Signing failed. Please check if your wallet is 
                            connected to the ${appConfig.blockchainName} network.`;
                        }


                        this.setState({
                            errorMessage: msg,
                            submitting: false
                        }, () => {
                            DataReporter.trackSentry(err, {
                                extra: {additionalData: "OwnershipChanger: Signing confirmation form."}
                            });
                        });
                    }
                });
        }
    }

    // Events
    componentDidMount() {
        DataReporter.trackMixpanel(this.props, "Component view: OwnershipChanger", {
            pageDisplayMode: this.props.appReducer.mobileMode ? "mobile" : "desktop"
        });
    }

    onSubmissionSuccessful(receipt, descr) {
        this.props.dispatchBlockchainLoadingStop();

        this.setState({
                submissionSuccessful: true,
                trxReceipt: receipt,
                receiptDescr: descr,
                submitting: false,
                errorMessage: null
            },
            () => {
                if (this.props.updateDetailsCallback) this.props.updateDetailsCallback()
            });

    }

    onNextOwnerWalletChange(e) {
        if (e.inputValue.length >= this.state.minInputLength) {
            this.setState({nextOwnerWalletAddress: e.inputValue});
        }
    }

    onNextOwnerWalletPaste(e) {
        this.onNextOwnerWalletChange(e.clipboardData.getData("text"));
    }

    dateOnChange(date) {
        this.setState({
            changeDate: DateConverter.getUTCMilliseconds(date)
        }, () => {
            DataReporter.trackMixpanel(this.props, "Changed transfer date", {
                category: "Interaction"
            });
        });
    }

    optionalCommentOnChange(e) {
        if (e.inputValue.length >= this.state.minInputLength) {
            this.setState({optionalComment: e.inputValue});
        }
    }

    optionalCommentOnPaste(e) {
        this.optionalCommentOnChange(e.clipboardData.getData("text"));
    }

    onEstimatedGasUse(estimate) {
        this.setState({
            estimatedGasUse: estimate
        });
    }

    // Renderers
    renderErrorMessage() {
        if (this.state.errorMessage !== null) {
            return <FeedbackMessage
                success={false}
                message={this.state.errorMessage}
            />;
        }
    }

    renderTRXReceipt() {
        if (this.state.submissionSuccessful !== null) {
            DataReporter.trackMixpanel(this.props, "TRX receipt: Ownersh. changer", {
                category: "Display"
            });

            let last = this.state.trxReceipt;

            return (
                <div id={"trx-receipt-container"}>
                    <TRXReceipt
                        forMobile={this.props.appReducer.mobileMode}
                        descr={this.state.receiptDescr}
                        trxHash={last.transactionHash}
                        trxGasUsed={last.gasUsed}
                        estimatedGas={this.state.estimatedGasUse}
                    />;
                </div>
            );
        }
    }

    renderDateValid() {
        if (!this.state.changeDateValid) {
            return (
                <div className={"input-error"}>
                    <p className={"msg"}>{this.state.changeDateValidMsg}</p>
                </div>
            );
        }
    }

    renderTransferConfirmationForm() {
        if (this.state.submissionSuccessful === null) {
            let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";

            let optionalComment = null;

            for (let i = 0; i < this.props.completeRecordData.length; i += 1) {
                let rec = this.props.completeRecordData[i];

                if (rec.data.recordType === "ownershipTransferOpening"
                    && rec.data.originator === this.props.currentOwnerAddress
                    && rec.data.nextOwnerWalletAddress === this.props.walletReducer.connectedAddress.toLowerCase()
                    && rec.data.optionalComment) {
                    optionalComment = rec.data.optionalComment;
                    break;
                }
            }

            let optCommentElement;
            if (optionalComment) {
                optCommentElement = (
                    <div id={"optional-comment"}>
                        <p id={"label"}>The current owner left this comment for you</p>

                        <div className={"inline"}>
                            <p className={"red-quotes"}>“</p>
                            <p id={"quote"}>{optionalComment}</p>
                            <p className={"red-quotes"}>”</p>
                        </div>
                    </div>
                );
            }

            let currentOwnerAddress = this.props.currentOwnerAddress;
            if (this.props.appReducer.mobileMode) {
                currentOwnerAddress = currentOwnerAddress.slice(0,5) + "-" + currentOwnerAddress.slice(-5);
            }

            let spinner;
            if (this.state.submitting) {
                spinner = <div id={"spinner-container" + mobileSuffix}>
                    <img id="spinner" src={process.env.PUBLIC_URL + '/spinner.gif'} alt={"spinner"}/>
                </div>;
            }

            return (
                <div id={"transfer-confirmation-form" + mobileSuffix}>
                    <h2>You have been appointed as the next owner of this Record</h2>

                    <p className={"descr"}>
                        Please check the Record's current history.
                        By clicking the confirmation button,<br/> you accept your new role as the owner of this product.
                    </p>


                    {optCommentElement}

                    <div id={"submit-container"}>
                        <VTRCalculator
                            id={"vtr-calc"}
                            posName={"Taking ownership of record"}
                            rateName={"getRateOwnershipTransferNextOwner"}
                            onGetGasUseEstimate={(estimate) => this.onEstimatedGasUse(estimate)}
                        />
                        {this.renderErrorMessage()}
                        <div className={"inline" + mobileSuffix}>
                            <UserButton
                                forMobile={this.props.appReducer.mobileMode}
                                className={"user-button ext-button"}
                                id={"confirm-transfer-button"}
                                value={"Confirm ownership transfer"}
                                icon={<GiHouseKeys className={"text-icon"}/>}
                                onClick={() => this.submitConfirmationForm()}
                                disabled={this.state.submitting}
                            />
                            {spinner}
                        </div>
                    </div>
                </div>
            );
        }
    }

    renderTransferOpeningForm() {
        if (this.state.submissionSuccessful === null && !this.props.showConfirmationForm) {
            DataReporter.trackMixpanel(this.props, "Rendering transfer opening form", {
                category: "Display"
            });

            let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";

            let s = this.state;
            let disabled = s.nextOwnerWalletAddress === null || s.changeDate === null || s.submitting;

            let spinner;
            if (s.submitting) {
                spinner = <div id={"spinner-container" + mobileSuffix}>
                    <img id="spinner" src={process.env.PUBLIC_URL + '/spinner.gif'} alt={"spinner"}/>
                </div>;
            }

            let minDate = new Date();
            minDate.setFullYear(minDate.getFullYear() - 5);

            return (
                <div id={"form-container" + mobileSuffix}>
                    <div id={"transfer-opening-form" + mobileSuffix}>
                        <TextInput
                            inputID={"next-owner-input"}
                            label={"Next owner's wallet address"}
                            placeholder={"Next owner's wallet address"}
                            onChange={(e) => this.onNextOwnerWalletChange(e)}
                            onPaste={(e) => this.onNextOwnerWalletPaste(e)}
                            validationError={!this.state.nextOwnerWalletAddressValid}
                            validationMsg={this.state.nextOwnerWalletAddressValidMsg}
                            maxLen={42}
                        />

                        <div id={"date-container"}>
                            <p className={"input-label"}>
                                <BsCalendarCheck className={"label-icon"}/>
                                Date of actual ownership change
                            </p>
                            <DatePicker
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                yearDropdownItemNumber={20}
                                dropdownMode="select"
                                dateFormat="yyyy-MM-dd"
                                id={"change-date-input"}
                                className={"purchase-date-input"}
                                selected={this.state.changeDate}
                                onChange={(date) => this.dateOnChange(date)}
                                minDate={minDate}
                                maxDate={new Date()}
                            />
                            {this.renderDateValid()}
                        </div>

                        <div id={"comment-input-container"}>
                            <TextArea
                                id={"comment-input"}
                                label={"Optional comment"}
                                maxLen={140}
                                rows={2}
                                placeholder={"Optional comment"}
                                onChange={(e) => this.optionalCommentOnChange(e)}
                                onPaste={(e) => this.optionalCommentOnPaste(e)}
                                validationError={!this.state.optionalCommentValid}
                                validationMsg={"The comment is too short or not valid."}
                                hints={
                                    <HintContainer
                                        forMobile={this.props.appReducer.mobileMode}
                                        hints={[
                                            "Where and how you agreed on selling or transferring this product.",
                                        ]}
                                    />
                                }
                            />
                        </div>

                        <div id={"submit-container"}>
                            <VTRCalculator
                                id={"vtr-calc"}
                                posName={"Opening ownership transfer"}
                                rateName={"getRateOwnershipTransferOpening"}
                                onGetGasUseEstimate={(estimate) => this.onEstimatedGasUse(estimate)}
                            />
                            {this.renderErrorMessage()}

                            <div className={"inline" + mobileSuffix}>
                                <UserButton
                                    forMobile={this.props.appReducer.mobileMode}
                                    id={"submit-button"}
                                    value={"Transfer Record ownership"}
                                    onClick={(e) => this.submitOpeningForm(e)}
                                    disabled={disabled}
                                />
                                {spinner}
                            </div>
                        </div>
                    </div>
                    <SidebarExplainer
                        id={"sidebar-explainer"}
                        h1={"Ownership transfer process"}
                        steps={this.getExplainerText()}
                    />

                </div>
            );
        }
    }

    render() {
        let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";

        let form;
        let h1;
        let descr;

        if (this.props.showConfirmationForm) {
            form = this.renderTransferConfirmationForm();
        }
        else {
            form = this.renderTransferOpeningForm();

            if (!this.state.submissionSuccessful) {
                h1 = (<h1 id={"h1"}>Record ownership transfer</h1>);
                descr = <h2 id={"descr"}>Enter the details for the ownership transfer.</h2>;
            }
        }

        return (
            <div id={"ownership-change-container" + mobileSuffix}>
                {h1}
                {descr}
                {this.renderTRXReceipt()}
                {form}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        walletReducer: state.wallet,
        blockchainReducer: state.blockchain,
        appReducer: state.app
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatchBlockchainLoading: () => {
            dispatch(requestSetBlockchainLoading())
        },
        dispatchBlockchainLoadingStop: () => {
            dispatch(requestSetBlockchainLoadingStop())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OwnershipChanger);