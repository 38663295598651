class customWagmiChains {
    static getInfuraAmoy() {
        return {
            id: 80002,
            name: "Polygon (Ventrace)",
            network: "amoy",
            nativeCurrency: {
                decimals: 18,
                name: "MATIC",
                symbol: "MATIC",
            },
            rpcUrls: {
                public: { http: ["https://polygon-amoy.infura.io/v3/2GJzNm7jqG2sGv40HbNuMTe6FUZ"] },
                default: { http: ["https://polygon-amoy.infura.io/v3/2GJzNm7jqG2sGv40HbNuMTe6FUZ"] },
            },
            blockExplorers: {
                etherscan: { name: 'Polyscan', url: "https://www.oklink.com/amoy" },
                default: { name: 'Polyscan', url: "https://www.oklink.com/amoy" },
            },
            contracts: {
                multicall3: {
                    address: "0xca11bde05977b3631167028862be2a173976ca11",
                    blockCreated: 25770160,
                },
            },
        }
    }

    static getAmoy() {
        return {
            id: 80002,
            name: "Polygon (Ventrace)",
            network: "amoy",
            nativeCurrency: {
                decimals: 18,
                name: "MATIC",
                symbol: "MATIC",
            },
            rpcUrls: {
                public: { http: ["https://rpc-amoy.polygon.technology"] },
                default: { http: ["https://rpc-amoy.polygon.technology"] },
            },
            blockExplorers: {
                etherscan: { name: 'Polyscan', url: "https://www.oklink.com/amoy" },
                default: { name: 'Polyscan', url: "https://www.oklink.com/amoy" },
            },
            contracts: {
                multicall3: {
                    address: "0xca11bde05977b3631167028862be2a173976ca11",
                    blockCreated: 25770160,
                },
            },
        }
    }

    static getPolygonMainnet() {
        return {
            id: 137,
            name: "Polygon",
            network: "polygon",
            nativeCurrency: {
                decimals: 18,
                name: "MATIC",
                symbol: "MATIC",
            },
            rpcUrls: {
                public: { http: ["https://polygon-bor-rpc.publicnode.com"] },
                default: { http: ["https://polygon-bor-rpc.publicnode.com"] },
            },
            blockExplorers: {
                etherscan: { name: 'Polygonscan', url: "https://polygonscan.com" },
                default: { name: 'Polygonscan', url: "https://polygonscan.com" },
            },
            contracts: {
                multicall3: {
                    address: "0x275617327c958bD06b5D6b871E7f491D76113dd8",
                    blockCreated: 18120000,
                },
            },
        }
    }

    static getNetwork() {
        return this.getAmoy();
    }

}

export default customWagmiChains;