import React from "react";
import {connect} from "react-redux";
import appConfig from "../appConfig";
import {create} from "ipfs-http-client";
import * as Sentry from "@sentry/react";
import Slider from 'rc-slider';
import Mixpanel from "mixpanel-browser";
import UserButton from "./inputs/UserButton";
import FeedbackMessage from "./FeedbackMessage";
import HintContainer from "./HintContainer";
import SidebarExplainer from "./SidebarExplainer";
import VTRCalculator from "./VTRCalculator";
import TRXReceipt from "./TRXReceipt";
import {EIP712types} from "../EIP712types";
import {requestSetBlockchainLoading, requestSetBlockchainLoadingStop} from "../store/actions/blockchain";
import 'rc-slider/assets/index.css'
import "../styles/components/recordprivacyswitcher.scss";
import {DataReporter} from "../DataReporter";


class RecordPrivacySwitcher extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            privateMonthCount: 0,
            privateMonthCountValid: true,
            rootIsPrivate: this.props.rootRecord[3] === "true",
            submissionSuccessful: null,
            trxReceipt: null,
            switchingPrivacy: false,
            enoughBalance: false,
            rateToPay: null,
            errorMessage: null
        }
    }

    getExplainerText() {
        return [
            {
                "name": "Setting your record to private mode",
                "descr": "Once you switch your record to private mode, nobody on Ventrace will be able to see" +
                    " your record's details except you. However, your record will appear in the landingPage results. "
            },
            {
                "name": "Privacy duration",
                "descr": "Your record will remain in private mode as long as you chose it to be. Once your privacy"
                    + " duration runs out, anybody on Ventrace will be able to switch your record back to public mode."

            },
            {
                "name": "Back to public mode",
                "descr": "You can switch your record back to public mode anytime you want. But be aware that you'll" +
                    "need to pay a fee in VTR to do so."

            }
        ];
    }

    getSetPrivateJSON() {
        return {
            "recordType": "setPrivateMode",
            "recordCreationDate": Date.now().toString(),
            "originator": this.props.walletReducer.connectedAddress,
            "privateMonthCount": this.state.privateMonthCount
        }
    }

    getSetPublicJSON() {
        return {
            "recordType": "setPublicMode",
            "recordCreationDate": Date.now().toString(),
            "originator": this.props.walletReducer.connectedAddress,
        }
    }

    isValidToSetPrivate() {
        let canSave = true;
        this.setState({
            privateMonthCountValid: true
        }, () => {
            DataReporter.trackMixpanel(this.props, "Validating to set private", {
                category: "Interaction"
            });
        });

        if (!this.state.privateMonthCount) {
            canSave = false;
            this.setState({
                privateMonthCountValid: false
            }, () => {
                DataReporter.trackMixpanel(this.props, "Validation error: Private month count", {
                    category: "Interaction",
                    value: this.state.privateMonthCount
                });
            });
        }

        return canSave;
    }

    async submitSetPrivate() {
        this.setState({switchingPrivacy: true},
            async () => {
                if (this.isValidToSetPrivate()) {
                    this.props.dispatchBlockchainLoading();

                    // Get approval for VTR cost
                    let vtrTokenContract = this.props.blockchainReducer.vtrTokenContract;
                    vtrTokenContract.methods.approve(appConfig.currentConfig.contracts.address_VTR_Manager,
                        this.state.rateToPay*appConfig.approvalMultiply)
                        .send({
                            from: this.props.walletReducer.connectedAddress,
                            gas: appConfig.currentConfig.blockchainGasLimit
                        })
                        .then(async (receipt) => {
                            // Upload data as JSON to IPFS
                            let auth = "Basic " + Buffer.from(appConfig.IPFS_ProjectID + ":" + appConfig.IPFS_Key).toString("base64");
                            let client = create({url: appConfig.IPFS_Endpoint, headers: {authorization: auth}});
                            let storedJSON = await client.add(JSON.stringify(this.getSetPrivateJSON()));

                            let recordManagerContract = this.props.blockchainReducer.recordManagerContract;
                            let extType = this.getSetPrivateJSON().recordType;

                            try {
                                let signature = await window.ethereum.request({
                                    method: 'eth_signTypedData_v4',
                                    params: [this.props.walletReducer.connectedAddress,
                                        JSON.stringify(EIP712types.newExtension(parseInt(this.props.rootRecord[0]), extType))
                                    ],
                                });

                                let rsv = EIP712types.getRSVfromSignature(signature);

                                recordManagerContract.methods.addExtensionRecord(parseInt(this.props.rootRecord[0]),
                                    storedJSON.path, extType, "", this.state.privateMonthCount, rsv.r, rsv.s, rsv.v)
                                    .send({
                                        from: this.props.walletReducer.connectedAddress,
                                        gas: this.state.estimatedGasUse
                                    })
                                    .then((addExtensionReceipt) => {
                                        this.props.dispatchBlockchainLoadingStop();
                                        this.onSubmissionSuccessful(addExtensionReceipt);

                                        DataReporter.trackMixpanel(this.props, "Success: Submitted private mode", {
                                            category: "Interaction"
                                        });
                                    })
                                    .catch((err) => {
                                        this.setState({
                                            errorMessage: "Error during privacy level switch (3). Please try again later.",
                                            switchingPrivacy: false
                                        }, () => {
                                            this.props.dispatchBlockchainLoadingStop();
                                            DataReporter.trackSentry(err, {
                                                extra: {additionalData: "PrivacySwitcher: Adding private mode ext."}
                                            });

                                            DataReporter.trackMixpanel(this.props, "Error: Add ext. set private",
                                                { category: "Interaction" });

                                        });
                                    })
                            } catch (err) {
                                this.props.dispatchBlockchainLoadingStop();

                                let msg = "Signing failed. Please check if your wallet is configured correctly.";
                                if (err.message.toLowerCase().includes("chainid")) {
                                    msg = `Signing failed. Please check if your wallet is connected to the ${appConfig.currentConfig.blockchainName} network.`;
                                }


                                this.setState({
                                    errorMessage: msg,
                                    switchingPrivacy: false
                                }, () => {
                                    DataReporter.trackSentry(err, {
                                        extra: {additionalData: "PrivacySwitcher: Signing private mode switch."}
                                    });

                                    DataReporter.trackMixpanel(this.props, "Error: Signing set private",
                                        { category: "Interaction" });

                                });
                            }
                        })
                        .catch((err) => {
                            this.setState({
                                errorMessage: "Error during privacy level switch (4). Please try again later.",
                                switchingPrivacy: false
                            }, () => {
                                this.props.dispatchBlockchainLoadingStop();

                                DataReporter.trackSentry(err, {
                                    extra: {additionalData: "PrivacySwitcher: Approve switch to private."}
                                });

                                DataReporter.trackMixpanel(this.props, "Error: Approve to set private",
                                    { category: "Interaction" });
                            });
                        })
                }
            });
    }

    async submitSetPublic() {
        this.setState({switchingPrivacy: true},
            async () => {
                this.props.dispatchBlockchainLoading();

                // Get approval for VTR cost
                let vtrTokenContract = this.props.blockchainReducer.vtrTokenContract;
                vtrTokenContract.methods.approve(appConfig.currentConfig.contracts.address_VTR_Manager,
                    this.state.rateToPay*appConfig.approvalMultiply)
                    .send({
                        from: this.props.walletReducer.connectedAddress,
                        gas: appConfig.currentConfig.blockchainGasLimit
                    })
                    .then(async (receipt) => {
                        // Upload data as JSON to IPFS
                        let auth = "Basic " + Buffer.from(appConfig.IPFS_ProjectID + ":" + appConfig.IPFS_Key).toString("base64");
                        let client = create({url: appConfig.IPFS_Endpoint, headers: {authorization: auth}});
                        let storedJSON = await client.add(JSON.stringify(this.getSetPublicJSON()));

                        // Send JSON of new RootRecord to blockchain
                        let recordManagerContract = this.props.blockchainReducer.recordManagerContract;
                        let extType = this.getSetPublicJSON().recordType;

                        try {
                            let signature = await window.ethereum.request({
                                method: 'eth_signTypedData_v4',
                                params: [this.props.walletReducer.connectedAddress,
                                    JSON.stringify(EIP712types.newExtension(parseInt(this.props.rootRecord[0]), extType))
                                ],
                            });

                            let rsv = EIP712types.getRSVfromSignature(signature);

                            recordManagerContract.methods.addExtensionRecord(parseInt(this.props.rootRecord[0]),
                                storedJSON.path, extType, "", this.props.remainingPrivateMonths, rsv.r, rsv.s, rsv.v)
                                .send({
                                    from: this.props.walletReducer.connectedAddress,
                                    gas: this.state.estimatedGasUse
                                })
                                .then((addExtensionReceipt) => {
                                    this.props.dispatchBlockchainLoadingStop();
                                    this.onSubmissionSuccessful(addExtensionReceipt);

                                    DataReporter.trackMixpanel(this.props, "Success: Submitted set public", {
                                        category: "Interaction"
                                    });
                                })
                                .catch((err) => {
                                    this.setState({
                                        errorMessage: "Error during privacy level switch (1). Please try again later."
                                    }, () => {
                                        this.props.dispatchBlockchainLoadingStop();

                                        DataReporter.trackSentry(err, {
                                            extra: {additionalData: "PrivacySwitcher: Switch to public addExt."}
                                        });

                                        DataReporter.trackMixpanel(this.props, "Error: Add set public ext.",
                                            { category: "Interaction" });
                                    });
                                })

                        } catch (err) {
                            this.props.dispatchBlockchainLoadingStop();

                            let msg = "Signing failed. Please check if your wallet is configured correctly.";
                            if (err.message.toLowerCase().includes("chainid")) {
                                msg = `Signing failed. Please check if your wallet is connected to the ${appConfig.blockchainName} network.`;
                            }

                            this.setState({
                                errorMessage: msg,
                                submittingRecord: false
                            }, () => {
                                DataReporter.trackSentry(err, {
                                    extra: {additionalData: "PrivacySwitcher: Sign switch to public mode."}
                                });
                                DataReporter.trackMixpanel(this.props, "Error: Signing set public",
                                    { category: "Interaction" });
                            });
                        }

                    })
                    .catch((err) => {
                        this.setState({
                            errorMessage: "Error during privacy level switch (2). Please try again later."
                        }, () => {
                            this.props.dispatchBlockchainLoadingStop();

                            DataReporter.trackSentry(err, {
                                extra: {additionalData: "PrivacySwitcher: Approve switch to public mode."}
                            });

                            DataReporter.trackMixpanel(this.props, "Error: Approve set public",
                                { category: "Interaction" });
                        });
                    })
            });
    }

    // Events
    componentDidMount() {
        DataReporter.trackMixpanel(this.props, "Component view: Privacy switcher", {
            pageDisplayMode: this.props.appReducer.mobileMode ? "mobile" : "desktop"
        });
    }

    onSubmissionSuccessful(receipt) {
        this.setState({
            submissionSuccessful: true,
            trxReceipt: receipt,
            errorMessage: null
        })
    }

    onVTRGetRate(rate) {
        if (this.props.walletReducer.vtrBalance) {
            this.setState({
                enoughBalance: this.props.walletReducer.vtrBalance >= Math.abs(rate),
                rateToPay: Math.abs(rate)
            });
        }
    }

    sliderOnChange(e) {
        this.setState({
            privateMonthCount: e
        }, () => {
            DataReporter.trackMixpanel(this.props, "Month count slider change", {
                category: "Interaction", value: e}
            );
        });
    }

    onEstimatedGasUse(estimate) {
        this.setState({
            estimatedGasUse: estimate
        });
    }

    // Renderers
    renderErrorMessage() {
        if (this.state.errorMessage !== null) {
            return <FeedbackMessage
                success={false}
                message={this.state.errorMessage}
            />;
        }
    }

    renderTRXReceipt() {
        if (this.state.submissionSuccessful !== null) {
            DataReporter.trackMixpanel(this.props, "TRX receipt: Prv. switcher", {
                category: "Interaction"
            });

            let last = this.state.trxReceipt;

            return (
                <div id={"trx-receipt-container"}>
                    <TRXReceipt
                        forMobile={this.props.appReducer.mobileMode}
                        descr={"Changed privacy level"}
                        trxHash={last.transactionHash}
                        trxGasUsed={last.gasUsed}
                        estimatedGas={this.state.estimatedGasUse}
                    />
                </div>
            );
        }
    }

    renderSubmitContainer() {
        if (!this.state.submissionSuccessful) {
            let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";

            let spinner;
            if (this.state.switchingPrivacy) {
                spinner = spinner = <div id={"spinner-container"}>
                    <img id="spinner" src={process.env.PUBLIC_URL + '/spinner.gif'} alt={"spinner"}/>
                </div>;
            }

            if (!this.state.rootIsPrivate) {
                let buttonDisabled = this.state.switchingPrivacy || !this.state.privateMonthCount
                    || !this.state.enoughBalance;

                let buttonValue = "Switch to private mode";
                if (this.state.switchingPrivacy) buttonValue = "Switching to private mode";

                return (<div className={"submit-container"}>
                    <VTRCalculator
                        posName={`Setting private mode for ${this.state.privateMonthCount} months`}
                        rateName={"getRateSetPrivateMode"}
                        months={this.state.privateMonthCount}
                        refChangeUpdate={this.state.privateMonthCount}
                        onGetRate={(rate) => this.onVTRGetRate(rate)}
                        onGetGasUseEstimate={(estimate) => this.onEstimatedGasUse(estimate)}
                    />
                    {this.renderErrorMessage()}
                    <div className={"inline" + mobileSuffix}>
                        <UserButton
                            id={"submit-button"}
                            value={buttonValue}
                            onClick={() => this.submitSetPrivate()}
                            disabled={buttonDisabled}
                        />
                        {spinner}
                    </div>
                </div>);
            } else if (this.state.rootIsPrivate) {
                let buttonDisabled = this.state.switchingPrivacy || !this.state.enoughBalance;
                let buttonValue = "Reactivate public mode";
                if (this.state.switchingPrivacy) buttonValue = "Reactivating public mode";

                return (<div className={"submit-container"}>
                    <VTRCalculator
                        posName={`Manually reactivate public mode`}
                        rateName={"getRateSetPublicMode"}
                        remainingMonths={this.props.remainingPrivateMonths}
                        onGetRate={(rate) => this.onVTRGetRate(rate)}
                        onGetGasUseEstimate={(estimate) => this.onEstimatedGasUse(estimate)}
                    />
                    {this.renderErrorMessage()}
                    <div className={"inline" + mobileSuffix}>
                        <UserButton
                            id={"submit-button"}
                            value={buttonValue}
                            onClick={() => this.submitSetPublic()}
                            disabled={buttonDisabled}
                        />
                        {spinner}
                    </div>
                </div>);
            }
        }
    }

    renderPrivacyInfo() {
        if (this.state.submissionSuccessful === null) {
            if (this.state.rootIsPrivate) {
                return (
                    <div className={"privacy-info-container"}>
                        <p>This Record will remain in private mode for the
                            next {this.props.remainingPrivateMonths} months.</p>
                        <p>Switching back to public mode will make the following changes to your Record:</p>
                        <HintContainer
                            forMobile={this.props.appReducer.mobileMode}
                            id={"public-mode-hint"}
                            hints={["This record's whole history will be visible the public."]}
                        />
                    </div>
                )
            } else {
                return (
                    <div className={"privacy-info-container"}>
                        <p>Activating private mode will make the following changes to your Record:</p>
                        <HintContainer
                            forMobile={this.props.appReducer.mobileMode}
                            id={"private-mode-hint"}
                            hints={[
                                "This Record's whole history will be hidden from the public (except for you)",
                                "This Record will still be listed in the landingPage results."
                            ]}
                        />
                    </div>
                )
            }
        }
    }

    renderMonthCount() {
        if (this.state.privateMonthCount) {
            let month_wording = "months";
            if (this.state.privateMonthCount === 1) month_wording = "month";

            return (<p id={"month-count"}>{this.state.privateMonthCount} {month_wording}</p>);
        }
        return (<p id={"month-count"}>0 months</p>);
    }

    renderSliderValid() {
        if (!this.state.privateMonthCountValid) {
            return (
                <div className={"input-error"}>
                    <p className={"msg"}>Please pick at least 1 month.</p>
                </div>
            );
        }
    }

    renderSlider() {
        if (this.state.submissionSuccessful === null && !this.state.rootIsPrivate) {
            let marks = {1: 1, 3: 3, 6: 6, 12: 12, 18: 18, 24: 24};

            return (
                <div className={"slider-container"}>
                    <h2>Private mode duration</h2>
                    <p>Specify how long you want this Record to be in private mode.</p>
                    {this.renderMonthCount()}
                    {this.renderSliderValid()}

                    <div id={"slider-wrapper"}>
                        <Slider
                            className={"slider"}
                            min={1} max={24} step={1}
                            marks={marks}
                            onChange={(e) => this.sliderOnChange(e)}
                        />
                    </div>
                </div>);
        }
    }

    renderSidebar() {
        if (!this.state.submissionSuccessful) {
            return (
                <SidebarExplainer
                    forMobile={this.props.appReducer.mobileMode}
                    id={"sidebar-explainer"}
                    h1={"How record privacy works"}
                    steps={this.getExplainerText()}
                />
            );
        }
    }

    render() {
        let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";

        let header;
        if (!this.state.submissionSuccessful) {
            header = <h1>Switch your Record to private mode</h1>;
            if (this.state.rootIsPrivate) header = <h2>Switch back to public mode</h2>;
        }

        return (
            <div className={"record-privacy-switcher-container" + mobileSuffix}>
                <div id={"l"}>
                    {header}
                    {this.renderPrivacyInfo()}
                    {this.renderSlider()}
                    {this.renderSubmitContainer()}
                </div>
                <div id={"r"}>
                    {this.renderSidebar()}
                </div>
                {this.renderTRXReceipt()}
                <div id={"clearer"}/>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        walletReducer: state.wallet,
        blockchainReducer: state.blockchain,
        appReducer: state.app
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatchBlockchainLoading: () => {
            dispatch(requestSetBlockchainLoading())
        },
        dispatchBlockchainLoadingStop: () => {
            dispatch(requestSetBlockchainLoadingStop())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecordPrivacySwitcher);