import React from "react";
import env from "../../appConfig";
import {DateConverter} from "../../DateConverter";
import {BsCalendarWeek} from "react-icons/bs";
import {MdPublic} from "react-icons/md";
import "../../styles/components/details_public_mode_extension.scss";

class PublicModeDueExtension extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    clickOpenAddressExplorer(val) {
        let baseUrl = env.explorerURLs.testnet.address;
        if (env.onDev) {

        }
        window.open(baseUrl + val, "_blank");
    }

    render() {
        if (this.props.data) {
            let originatorAddress = this.props.data.originator;
            let changeDateLabel = "Activated on";

            if (this.props.forMobile) {
                changeDateLabel = "On";

                let sliceLen = 7;
                originatorAddress = originatorAddress.slice(0,sliceLen)
                    + "-" + originatorAddress.slice(-sliceLen);
            }

            return (
                <div className={"private-mode-extension-container"}>
                    <div className={"record-content"}>
                        <div className={"l1 inline"}>
                            <p className={"record-type ext-type"}>
                                <MdPublic className={"type-icon"} />
                                Expired Record privacy
                            </p>
                            <div className={"added-on-container"}>
                                <p className={"change-date"}>
                                    {changeDateLabel}&nbsp;
                                    <span className={"data"}>
                                        <BsCalendarWeek className={"cal-icon"}/>
                                        {DateConverter.dateObjToString(parseInt(this.props.data.recordCreationDate))}
                                     </span>
                                </p>
                            </div>
                        </div>

                        <div className={"l2"}>
                            <p className={"originator"}>
                                Creator
                                <a
                                    className={"data"}
                                    onClick={() => this.clickOpenAddressExplorer(this.props.data.originator)}>
                                    {originatorAddress}
                                </a>
                            </p>
                        </div>

                        <div className={"l3"}>
                            <div className={"descr-container"}>
                                <p className={"descr-label"}>System message</p>
                                <p className={"descr-val"}>
                                    The duration for the private mode expired.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default PublicModeDueExtension;