import React from "react";
import {connect} from "react-redux";
import appConfig from "../appConfig";
import axios from "axios";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import TextInput from "../components/inputs/TextInput";
import {DataReporter} from "../DataReporter";
import ContentLoader from "react-content-loader";
import FeedbackMessage from "../components/FeedbackMessage";
import {BsCalendarWeek} from "react-icons/bs";
import {DateConverter} from "../DateConverter";
import {VscExtensions} from "react-icons/vsc";
import {AiFillInfoCircle, AiOutlineSearch} from "react-icons/ai";
import CTANoSearchResult from "../components/CTANoSearchResult";
import {RiRadarLine} from "react-icons/ri";
import {requestSetBlockchainLoading, requestSetBlockchainLoadingStop} from "../store/actions/blockchain";
import {requestSetClaimingRecord, requestSetCurrentPage} from "../store/actions/app";
import * as Sentry from "@sentry/react";
import "../styles/pages/search.scss";

class Search extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputSerialNr: null,
            searchRes: [],
            bigSearchViewed: false,
            errorMessage: null,
            searchedOnChain: false,
            searchedOnDB: false,
            searching: false,
            inputIsValid: true,
            inputValidMsg: null,
        }
        this.searchFormRef = React.createRef();
    }

    getProductWord(capitalize) {
        let productWord = "product";
        if (this.props.appReducer.activePersona === appConfig.personas.watches) {
            productWord = "watch";
        }
        if (capitalize) {
            return productWord.charAt(0).toUpperCase() + productWord.slice(1);
        } else {
            return productWord;
        }
    }

    searchInputIsValid() {
        let canSubmit = true;
        this.setState({
            inputIsValid: true,
            inputValidMsg: null,
        });

        if (this.state.inputSerialNr === null || this.state.inputSerialNr.length < this.state.minInputLength) {
            this.setState({
                inputIsValid: false,
                inputValidMsg: "Your input is too short. Please enter a full serial number."
            });
            canSubmit = false;
        }
        return canSubmit;
    }

    checkForLossNotices() {
        try {
            axios
                .post(appConfig.currentConfig.backendApp.url + "/lossnotice/check",
                    {SNR: this.state.inputSerialNr.trimStart().trimEnd()},
                    {
                        headers: {
                            Authorization: `Bearer ${appConfig.currentConfig.backendApp.tokens.lossNoticeQuery}`,
                            'Content-Type': 'application/json',
                        },
                    })
                .then(res => {
                    if (!res.data.success && !appConfig.onDev) {
                        Sentry.captureEvent("Couldn't check for loss notices");
                    }
                })
                .catch(err => {
                    DataReporter.trackSentry(err, {extra: {additionalData: "checkForLossNotices 1"}});
                })
        } catch (err) {
            DataReporter.trackSentry(err, {extra: {additionalData: "checkForLossNotices 2"}});
        }
    }

    async searchOnDB() {
        if (this.searchInputIsValid()) {
            try {
                axios
                    .get(appConfig.currentConfig.backendApp.url + "/rootRecords/all",
                        {
                            headers: {
                                Authorization: `Bearer ${appConfig.currentConfig.backendApp.tokens.standard}`,
                                'Content-Type': 'application/json',
                            },
                            params: {
                                SNR: this.state.inputSerialNr.trimStart().trimEnd()
                            }
                        }
                    )
                    .then(res => {
                        if (res.status === 200) {
                            let searchRes = [];

                            for (let i = 0; i < res.data.result.length; i += 1) {
                                searchRes.push(res.data.result[i]);
                            }

                            this.setState({
                                errorMessage: null,
                                searchRes: searchRes.reverse(),
                                bigSearchViewed: true,
                                searching: false
                            });
                        }
                    })
                    .catch(err => {
                        this.setState({
                            searchedOnDB: true
                        });
                    })
            } catch (err) {
                DataReporter.trackMixpanel(this.props, "Search Error", {category: "Error"});
                DataReporter.trackSentry(err);
            }
        }
    }

    // Events
    componentDidMount() {
        this.props.dispatchSetPage("search");

        DataReporter.trackMixpanel(this.props, "Page view: Search",
            {
                pageDisplayMode: this.props.appReducer.mobileMode ? "mobile" : "desktop",
            });
    }

    onClickSearch() {
        let targetYPosition = this.searchFormRef.current.getBoundingClientRect().top;

        window.scrollTo({
            top: window.scrollY + targetYPosition - 120,
            behavior: 'smooth',
        });
    }

    onSeachResultClick(curr) {
        DataReporter.trackMixpanel(this.props, "Clicked on Search result", {
            category: "Interaction"
        });

        setTimeout(() => {
            window.open(`/record?rp=${curr.rootRecord.routeParam}`, "_self");
        }, 500);
    }

    onQueryInputChange(e) {
        if (e.enterPressed && this.state.inputSerialNr) {
            this.props.dispatchSetClaimingRecord(null);
            this.setState({
                searching: true
            }, () => {
                DataReporter.trackMixpanel(this.props, "Searching for VR",
                    {
                        pageDisplayMode: this.props.appReducer.mobileMode ? "mobile" : "desktop",
                    });

                this.searchOnDB();
                setTimeout(() => this.checkForLossNotices(), 3000);
            })
        } else {
            this.setState({inputSerialNr: e.inputValue});
        }
    }

    onInputReset() {
        this.setState({searchRes: []}, () => {
            DataReporter.trackMixpanel(this.props,
                "Search Reset", {category: "Display"});
        });
    }

    // Renderers
    renderErrorMessage() {
        if (this.state.errorMessage !== null) {
            return <FeedbackMessage
                centered={true}
                success={false}
                message={this.state.errorMessage}
            />;
        }
    }

    renderContentSpinner() {
        if (this.state.searching) {
            let height = this.props.appReducer.mobileMode ? 12 : 5;
            return (
                <div id={"content-spinner"}>
                    <ContentLoader viewBox={"0 0 100 25"}
                                   backgroundColor={"#eeeeee"}
                                   foregroundColor={"#dadada"}
                                   speed={1.5}
                    >

                        <rect x={0} y={0} rx={1} ry={1} width={100} height={height}/>
                    </ContentLoader>
                </div>
            );
        }
    }

    renderNoSearchResultsInfo() {
        let meaning;
        if (this.props.appReducer.mobileMode) {
            meaning = (
                <p id={"meaning"}>
                    The digital certificate for the {this.getProductWord()} you're looking for hasn't been activated yet.
                </p>
            );
        } else {
            meaning = (
                <p id={"meaning"}>
                    The digital certificate for the {this.getProductWord()} you're looking for hasn't been activated yet.<br/>
                    If you're the owner of that {this.getProductWord()}, you can easily order the digital certificate
                    ("Ventrace Record") yourself - see below.
                </p>
            );
        }

        return (
            <div id={"no-result-container"}>
                <p id={"no-res-count"}>
                    <RiRadarLine id={"no-signal"}/>
                    No Ventrace Record found
                </p>
                {meaning}
            </div>
        );
    }

    renderSearchResult() {
        let resCountP;
        let resItems = [];
        let ctaCreateRecord = null;

        if (this.state.searchRes.length > 0) {
            let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";

            for (let i = 0; i < this.state.searchRes.length; i += 1) {
                let curr = this.state.searchRes[i];

                let originator;
                if (curr.providerName) {
                    originator = curr.providerName;
                } else {
                    originator = curr.displayName;
                }

                resItems.push(
                    <div className={"result-item" + mobileSuffix}
                         key={i}
                         onClick={() => this.onSeachResultClick(curr)}>

                        <p className={"name"}>{curr.rootRecord.rootRecordData.name}</p>
                        <p className={"originator"}>
                            {curr.providerName ? "Provided by" : "Record owner"}
                            <span className={curr.providerName ? "data-p" + mobileSuffix : "data" + mobileSuffix}>
                                {originator}
                            </span>
                        </p>
                        <p className={"date-added" + mobileSuffix}>
                            Record activation
                            <span className={"data" + mobileSuffix}>
                                <BsCalendarWeek className={"cal-icon"}/>
                                {DateConverter.dateObjToString(parseInt(curr.rootRecord.rootRecordData.recordCreationDate))}
                            </span>
                        </p>
                    </div>
                )
            }

            if (this.state.bigSearchViewed && this.state.searchRes.length >= 1) {
                if (this.state.searchRes.length >=2) {
                    resCountP = (
                        <div>
                            <p id={"res-count"}>
                                Found <b>{this.state.searchRes.length}</b> Records
                            </p>
                            <p id={"why-multiple-results"}>
                                <AiFillInfoCircle id={"icon"} />
                                Multiple results can happen by chance when different owners order a
                                Ventrace Record for the same product, or when various factories use the same
                                serial number for their products.
                            </p>
                        </div>
                    );
                } else {
                    resCountP = (
                        <p id={"res-count"}>
                            Found <b>{this.state.searchRes.length}</b> Record
                        </p>
                    );
                }

                DataReporter.trackMixpanel(this.props, "Showing search results",
                    {
                        category: "Display",
                        searchResCount: resItems.length,
                        query: this.state.inputSerialNr
                    });
            }

        } else if (this.state.bigSearchViewed && resItems.length === 0) {
            DataReporter.trackMixpanel(this.props, "Showing NoRes CTA",
                {
                    category: "Searching",
                    query: this.state.inputSerialNr ? this.state.inputSerialNr : ""
                });

            resCountP = this.renderNoSearchResultsInfo();
            ctaCreateRecord = (
                <CTANoSearchResult forMobile={this.props.appReducer.mobileMode}/>
            );
        }

        if (this.state.bigSearchViewed) {
            window.scrollTo(0, 0);

            return (
                <div id={"search-res-container"}>
                    {resCountP}
                    {resItems}
                    {ctaCreateRecord}
                </div>
            );
        }
    }

    renderSearchInfo() {
        if (!this.state.bigSearchViewed) {
            let descr;
            if (this.props.appReducer.mobileMode) {
                descr = (
                    <p id={"descr"}>
                        Look for existing Records to find out about essential {this.getProductWord()} details
                        such as production years, repair dates or international trades.
                    </p>
                );
            } else {
                descr = (
                    <p id={"descr"}>
                        Look for existing Records to find out about essential {this.getProductWord()} details
                        such as production years,<br/>repair dates or international trades.
                    </p>
                );
            }

            return (
                <div id={"search-info-container"}>
                    <div id={"header-container"}>
                        <AiOutlineSearch id={"icon"} />
                        <h2>Search for existing Ventrace Records</h2>
                    </div>
                    {descr}
                </div>
            );
        }
    }

    renderSearchForm() {
        return (
            <div id={"compact-search-form-container"} ref={this.searchFormRef}>
                <h1>Ventrace Record Search</h1>
                <div id={"compact-search-form"}>
                    <p id={"label"}>{this.getProductWord(true)} Serial Number</p>
                    <div className={"inline"}>
                        <TextInput
                            id={"serial-nr"}
                            inputID={"serial-nr-input"}
                            renderLabel={false}
                            placeholder={`${this.getProductWord(true)} Serial Number`}
                            value={this.state.inputSerialNr}
                            onChange={(e) => this.onQueryInputChange(e)}
                            width={null}
                            showSubmitButton={true}
                            onReset={() => this.onInputReset()}
                            validationError={!this.state.inputIsValid}
                            validationMsg={this.state.inputValidMsg}
                        />
                    </div>
                    {this.renderErrorMessage()}
                </div>
                {this.renderContentSpinner()}
            </div>
        );
    }

    render() {
        let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";

        document.title = "Ventrace - Search";
        return (
            <div>
                <Navigation
                    onClickSearch={() => this.onClickSearch()}
                />

                <div className={"search-container" + mobileSuffix}>
                    {this.renderSearchForm()}
                    {this.renderSearchInfo()}
                    {this.renderSearchResult()}
                </div>

                <Footer
                    forMobile={this.props.appReducer.mobileMode}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        appReducer: state.app,
        blockchainReducer: state.blockchain,
        walletReducer: state.wallet,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatchBlockchainLoading: () => {
            dispatch(requestSetBlockchainLoading());
        },
        dispatchBlockchainLoadingStop: () => {
            dispatch(requestSetBlockchainLoadingStop());
        },
        dispatchSetPage: (pageName) => {
            dispatch(requestSetCurrentPage(pageName));
        },
        dispatchSetClaimingRecord: (claimingRecord) => {
            dispatch(requestSetClaimingRecord(claimingRecord));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Search);