import React from "react";
import appConfig from "../../appConfig";
import Mixpanel from "mixpanel-browser";
import {DateConverter} from "../../DateConverter";
import {AiOutlineBranches} from "react-icons/ai";
import {BsCalendarWeek} from "react-icons/bs";
import "../../styles/components/details_branchdismissed.scss";
import {DataReporter} from "../../DataReporter";

class BranchDismissed extends React.Component {
    clickOpenAddressExplorer(val) {
        DataReporter.trackMixpanel(this.props, "Clicking on branch dismissed creator", {
            category: "Display",
        });
        let baseUrl = appConfig.explorerURLs.testnet.address;
        window.open(baseUrl + val, "_blank");
    }

    render() {
        if (this.props.data) {
            let mobileSuffix = this.props.forMobile ? "-mobile" : "";

            let originatorAddress = this.props.data.originator;
            let changeDateLabel = "Dismissed on";

            if (this.props.forMobile) {
                let sliceLen = 7;
                originatorAddress = originatorAddress.slice(0,sliceLen) + "-" + originatorAddress.slice(-sliceLen);
                changeDateLabel = "On";
            }

            return (
                <div className={"branch-dismissed-container" + mobileSuffix}>
                    <div className={"record-content"}>
                        <div className={"l1 inline"}>
                            <p className={"record-type"}>
                                <AiOutlineBranches className={"type-icon"} />
                                Branch dismissed
                            </p>
                            <div className={"added-on-container"}>
                                <p className={"label"}>{changeDateLabel}&nbsp;</p>
                                <p className={"data"}>
                                    <BsCalendarWeek className={"cal-icon"} />
                                    {DateConverter.dateObjToString(parseInt(this.props.data.recordCreationDate))}
                                </p>
                            </div>
                        </div>

                        <div className={"l2"}>
                            <div className={"originator"}>
                                <p className={"label"}>Creator</p>
                                <a className={"data"}
                                   onClick={() => this.clickOpenAddressExplorer(this.props.data.originator)}>
                                    {originatorAddress}
                                </a>
                            </div>
                        </div>

                        <div className={"l3"}>
                            <div className={"descr-container"}>
                                <p className={"descr-label"}>System message</p>
                                <p className={"descr-val"}>
                                    The owner of this Record has dismissed the branch.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            );
        }
    }
}

export default BranchDismissed;