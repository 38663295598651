import React from "react";
import {connect} from "react-redux";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";
import {DataReporter} from "../../DataReporter";
import "../../styles/components/support_faq_box.scss";

class FAQBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
    }

    onClickAnswer(question) {
        this.setState({
            open: !this.state.open
        }, () => {
            DataReporter.trackMixpanel(this.props,
                this.state.open ? 'Show FAQ answer' : 'Close FAQ answer',
                { category: "Interaction", question: question }
            );
        });
    }

    render() {
        if (this.props.filterCategory === null || (this.props.category === this.props.filterCategory)) {
            let content = <p className={"a-closed"} onClick={() => this.onClickAnswer(this.props.question)}>
                <IoIosArrowDown className={"arrow"} />
                Show answer
            </p>;

            if (this.state.open) {
                content = <div className={"a-open"} onClick={() => this.onClickAnswer(this.props.question)}>
                    <IoIosArrowUp className={"arrow"} />
                    <p className={"answer"}>{this.props.answer}</p>
                </div>;
            }

            let mobileSuffix = this.props.forMobile ? "-mobile" : "";

            return (
                <div className={"faq-box-container" + mobileSuffix}>
                    <p className={"q"}>{this.props.question}</p>
                    {content}
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        appReducer: state.app
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(FAQBox);