import {combineReducers} from "redux";
import app from "./app";
import wallet from "./wallet";
import blockchain from "./blockchain";
import user from "./user";

export default combineReducers({
    app,
    user,
    blockchain,
    wallet,
});