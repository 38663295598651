import React from "react";
import {withRouter} from "react-router-dom";
import {MdCheckBoxOutlineBlank, MdOutlineCheckBox} from "react-icons/md";
import "../../styles/inputs/checkbox_input.scss";

class CheckboxInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isChecked: this.props.checked,
        }
    }

    changeHandler() {
        this.setState({
            isChecked: !this.state.isChecked
        }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.state.isChecked);
            }
        });
    }

    renderValidationResult() {
        if (this.props.validationError) {
            return (
                <div className={"input-error"}>
                    <p className={"msg"}>
                        {this.props.validationMsg}
                    </p>
                </div>
            )
        }
    }

    render() {
        let icon;
        if (this.state.isChecked) icon = <MdOutlineCheckBox className={"cb-icon"} />;
        else icon = <MdCheckBoxOutlineBlank className={"cb-icon"} />;

        return (
            <div className={this.props.className}
                 id={this.props.id}
                 onClick={(e) => this.changeHandler()}>

                {icon}
                <p className={"label"}>{this.props.label}</p>

                {this.renderValidationResult()}
            </div>
        );
    }
}

CheckboxInput.defaultProps = {
    className: "checkbox",
    checked: null
}

export default withRouter(CheckboxInput);