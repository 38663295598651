import React from "react";
import {connect} from "react-redux";
import appConfig from "../appConfig";
import axios from "axios";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import FormStepsIndicator from "../components/FormStepsIndicator";
import TextInput from "../components/inputs/TextInput";
import {DataReporter} from "../DataReporter";
import HintContainer from "../components/HintContainer";
import UserButton from "../components/inputs/UserButton";
import {requestSetEmail, requestSetLoginToken} from "../store/actions/user";
import { FaChevronRight } from "react-icons/fa";
import "../styles/pages/login.scss";

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            minInputLength: 5,
            userEmail: null,
            userEmailValidationMsg: null,
            canVerify: false,
            verificationCode: null,
            verificationCodeMsg: null,
            verifiedToken: null
        };
    }

    componentDidMount() {
        DataReporter.trackMixpanel(this.props,
            "Page view: Login",
            {
                pageDisplayMode: this.props.appReducer.mobileMode ? "mobile" : "desktop"
            });
    }

    getStepNumber() {
        if (this.state.canVerify) return 2;
        return 1;
    }

    validateEmail() {
        let isValid = true;
        if (!this.state.userEmail.includes("@") || !this.state.userEmail.includes(".")) {
            isValid = false;
            this.setState({
                userEmailValidationMsg: "The given email address isn't valid."
            });

            DataReporter.trackMixpanel(this.props,
                "Login: Email invalid", {page: "Login"}
            );
        }

        return isValid;
    }

    validateVerificationCode() {
        let isValid = true;
        if (!this.state.verificationCode) {
            isValid = false;
            this.setState({
                verificationCodeMsg: "Please enter your verification code."
            });

            DataReporter.trackMixpanel(this.props,
                "Login: Verification code invalid", {page: "Login"}
            );
        }
        return isValid;
    }

    // Events
    onSendEmail() {
        if (appConfig.onDev) {
            this.props.dispatchSetLoginToken("100");
            this.props.dispatchSetEmail("onkaraman@outlook.com");

            window.open("/myrecords", "_self");

        } else if (this.validateEmail()) {
            this.setState({
                loading: true,
                userEmailValidationMsg: null
            }, () => {
                try {
                    axios
                        .post(appConfig.currentConfig.backendApp.url + "/vrholder/sendverification",
                            {
                                email: this.state.userEmail.trimStart().trimEnd()
                            },
                            {
                                headers: {
                                    Authorization: `Bearer ${appConfig.currentConfig.backendApp.tokens.standard}`,
                                    'Content-Type': 'application/json',
                                },
                            })
                        .then(res => {
                            this.setState({
                                canVerify: true,
                                loading: false
                            }, () => {
                                DataReporter.trackMixpanel(this.props,
                                    "Login: Email entered", {page: "Login"}
                                );
                            });
                        })
                        .catch(err => {
                            DataReporter.trackSentry(err, {extra: {additionalData: "onSendEmail 1"}});
                            this.setState({
                                loading: false,
                                userEmailValidationMsg: "No connection. Please try again."
                            }, () => {
                                DataReporter.trackMixpanel(this.props,
                                    "Login: Email error 1", {page: "Login"}
                                );
                            });
                        })
                } catch (err) {
                    DataReporter.trackSentry(err, {extra: {additionalData: "onSendEmail 2"}});
                    this.setState({
                        loading: false,
                        userEmailValidationMsg: "Login failed. Please try again."
                    }, () => {
                        DataReporter.trackMixpanel(this.props,
                            "Login: Email error 2", {page: "Login"}
                        );
                    });
                }
            });
        }
    }

    onSendCode() {
        if (this.validateVerificationCode()) {
            this.setState({
                loading: true,
                verificationCodeMsg: null
            }, () => {
                try {
                    axios
                        .post(appConfig.currentConfig.backendApp.url + "/vrholder/checkverification",
                            {
                                email: this.state.userEmail.trimStart().trimEnd(),
                                verificationCode: this.state.verificationCode
                            },
                            {
                                headers: {
                                    Authorization: `Bearer ${appConfig.currentConfig.backendApp.tokens.standard}`,
                                    'Content-Type': 'application/json',
                                },
                            })
                        .then(res => {
                            if (res.data.success) {
                                this.props.dispatchSetLoginToken(res.data.token);
                                this.props.dispatchSetEmail(this.state.userEmail.trimStart().trimEnd());

                                DataReporter.trackMixpanel(this.props,
                                    "Login: Verification code correct", {page: "Login"}
                                );

                                setTimeout(() => {
                                    window.open("/myrecords", "_self");
                                }, 500);
                            } else {
                                this.setState({
                                    loading: false,
                                    verificationCodeMsg: "Wrong verification code. Please try again."
                                }, () => {
                                    DataReporter.trackMixpanel(this.props,
                                        "Login: Wrong verification code", {page: "Login"}
                                    );
                                });
                            }
                        })
                        .catch(err => {
                            DataReporter.trackSentry(err, {extra: {additionalData: "onSendCode 1"}});

                            this.setState({
                                loading: false,
                                verificationCodeMsg: "No connection. Please try again."
                            });
                        })
                } catch (err) {
                    DataReporter.trackSentry(err, {extra: {additionalData: "onSendCode 2"}});
                }
            })
        }
    }

    onEmailChange(e) {
        if (e.enterPressed) {
            this.onSendEmail();
        }
        else if (e.inputValue.length >= this.state.minInputLength) {
            this.setState({userEmail: e.inputValue});
        }
    }

    onEmailPaste(e) {
        this.onEmailChange({inputValue: e.clipboardData.getData("text")});
    }

    onVerificationCodeChange(e) {
        if (e.enterPressed) {
            this.onSendCode();
        }
        else if (e.inputValue.length >= this.state.minInputLength) {
            this.setState({verificationCode: e.inputValue});
        }
    }

    onVerificationCodePaste(e) {
        this.onVerificationCodeChange({inputValue: e.clipboardData.getData("text")});
    }

    onGoBackToEmail() {
        this.setState({
            canVerify: false
        }, () => {
            DataReporter.trackMixpanel(this.props,
                "Login: Back to email", {page: "Login"}
            );
        });
    }

    // Renderers
    renderEmailForm() {
        let spinner;
        if (this.state.loading) {
            spinner = <div id={"spinner-container"}>
                <img id="spinner" src={process.env.PUBLIC_URL + '/spinner.gif'} alt={"spinner"}/>
            </div>;
        }

        return (
            <div id={"email-form"}>
                <TextInput
                    inputID={"email-input"}
                    label={`Your email address`}
                    value={this.state.userEmail}
                    placeholder={`Your email address`}
                    onChange={(e) => this.onEmailChange(e)}
                    onPaste={(e) => this.onEmailPaste(e)}
                    validationError={this.state.userEmailValidationMsg}
                    validationMsg={this.state.userEmailValidationMsg}
                    maxLen={50}
                    hints={
                        <HintContainer
                            forMobile={this.props.appReducer.mobileMode}
                            hints={[
                                "Use the email address from the order of your Ventrace Records.",
                            ]}
                        />
                    }
                />

                <UserButton
                    forMobile={this.props.appReducer.mobileMode}
                    id={"send-email-button"}
                    icon={<FaChevronRight className={"text-icon"}/>}
                    value={"Continue"}
                    onClick={() => this.onSendEmail()}
                    disabled={this.state.loading}
                />

                {spinner}
            </div>
        );
    }

    renderVerifyForm() {
        let spinner;
        if (this.state.loading) {
            spinner = <div id={"spinner-container"}>
                <img id="spinner" src={process.env.PUBLIC_URL + '/spinner.gif'} alt={"spinner"}/>
            </div>;
        }

        return (
            <div id={"verify-form"}>
                <p id={"verify-descr"}>
                    We've sent you a verification code to the email address you have entered. Please enter
                    the code below to log in.
                </p>

                <TextInput
                    inputID={"code-input"}
                    label={`Your verification code`}
                    placeholder={`Your verification code`}
                    onChange={(e) => this.onVerificationCodeChange(e)}
                    onPaste={(e) => this.onVerificationCodePaste(e)}
                    validationError={this.state.verificationCodeMsg}
                    validationMsg={this.state.verificationCodeMsg}
                    maxLen={6}
                    showCharLimit={false}
                />

                <UserButton
                    forMobile={this.props.appReducer.mobileMode}
                    id={"send-code-button"}
                    value={"Log in"}
                    onClick={() => this.onSendCode()}
                    disabled={this.state.loading}
                />

                <p id={"go-back-link"} onClick={() => this.onGoBackToEmail()}>
                    Go back to your email address
                </p>

                {spinner}
            </div>
        );
    }

    render() {
        let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";

        let form;
        if (this.state.canVerify) form = this.renderVerifyForm();
        else form = this.renderEmailForm();

        document.title = "Ventrace - Login";
        return (
            <div>
                <Navigation />
                <div className={"content-container" + mobileSuffix} id={"login-container"}>
                    <h1>Log in</h1>
                    <p id={"login-descr"}>
                        Log into Ventrace to see and manage the Ventrace Records which you own.
                    </p>

                    <FormStepsIndicator
                        activeStep={this.getStepNumber()}
                        steps={["Your email address", "Verification code"]}
                    />

                    {form}
                </div>
                <Footer
                    forMobile={this.props.appReducer.mobileMode}
                />
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        appReducer: state.app,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatchSetEmail: (email) => {
            dispatch(requestSetEmail(email));
        },
        dispatchSetLoginToken: (token) => {
            dispatch(requestSetLoginToken(token));
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Login);