import React from "react";
import appConfig from "../appConfig";
import Mixpanel from "mixpanel-browser";
import { DataFormatter } from "../DataFormatter";
import "../styles/components/trxreceipt.scss";
import {DataReporter} from "../DataReporter";

class TRXReceipt extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    openTRXhashInExplorer(val) {
        DataReporter.trackMixpanel(this.props, "TRX receipt: Open hash in explorer",
            { category: "Interaction", });

        let baseUrl = "https://www.oklink.com/de/amoy/tx/";
        window.open(baseUrl + val, "_blank");
    }


    renderTRXHash(val) {
        let sliceLen = 7;
        let slicedVal = val.slice(0, sliceLen) + "-" + val.slice(-sliceLen);

        return (
            <div className={"inline detail"}>
                <p className={"name"}>TRX hash</p>
                <a className={"val"} id={"hash-val"} onClick={() => this.openTRXhashInExplorer(val)}>{slicedVal}</a>
            </div>
        )
    }

    renderDetail(name, val) {
        return (
            <div className={"inline detail"}>
                <p className={"name"}>{name}</p>
                <p className={"val"}>{val}</p>
            </div>
        )
    }

    render() {
        let mobileSuffix = this.props.forMobile ? "-mobile" : "";

        return (
            <div className={"trx-receipt-container" + mobileSuffix}>
                <p id={"h1"}>Transaction receipt</p>
                <p id={"h2"}>{this.props.descr}</p>
                <div className={"inline"}>
                    <img
                        id={"trx-receipt-img"}
                        src={process.env.PUBLIC_URL + '/assets/TRX_Receipt.png'}
                        alt={""}
                    />
                    <div className={"details"}>
                        {this.renderTRXHash(this.props.trxHash)}
                        {this.renderDetail("Estimated gas use",
                            DataFormatter.numberToDecimals(this.props.estimatedGas))}
                        {this.renderDetail("Actual gas used",
                            DataFormatter.numberToDecimals(this.props.trxGasUsed))}
                        {this.renderDetail("Gas saved",
                            DataFormatter.numberToDecimals(this.props.estimatedGas - this.props.trxGasUsed))}
                    </div>
                </div>
            </div>
        )
    }
}

export default TRXReceipt;