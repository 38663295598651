import React from "react";
import { GoDotFill } from "react-icons/go";
import "../styles/components/hintcontainer.scss"

class HintContainer extends React.Component {
    renderHints() {
        let hints = [];

        let textActiveSuffix = "";
        if (this.props.activeHints) {
            textActiveSuffix = "-active";
        }

        for (let i=0; i < this.props.hints.length; i+=1) {
            hints.push(
                <div className={"hint"} key={i}>
                    <GoDotFill className={"icon"} />
                    <p className={"text" + textActiveSuffix}>{this.props.hints[i]}</p>
                </div>
            );
        }
        return hints;
    }

    render() {
        if (this.props.hints.length > 0) {
            let mobileSuffix = this.props.forMobile ? "-mobile" : "";

            return (
                <div className={"hint-container" + mobileSuffix} id={this.props.id}>
                    {this.renderHints()}
                </div>
            );
        }
    }
}

export default HintContainer;