import * as types from "../actionTypes/blockchain";
import * as actionResponse from "./actionResponse";


export function requestSetBlockchainLoading() {
    return (dispatch) => {
        dispatch(actionResponse.gen(types.BLOCKCHAIN_LOADING, null));
    }
}

export function requestSetBlockchainLoadingStop() {
    return (dispatch) => {
        dispatch(actionResponse.gen(types.BLOCKCHAIN_LOADING_STOP, null));
    }
}

export function requestSetWeb3(web3) {
    return (dispatch) => {
        dispatch(actionResponse.gen(types.SET_WEB3, web3));
    }
}

export function requestSetContract(type, contractObj) {
    return (dispatch) => {
        dispatch(actionResponse.gen(type, contractObj));
    }
}

export function requestSetWalletReady(ready) {
    return (dispatch) => {
        dispatch(actionResponse.gen(types.SET_WALLET_READY, ready));
    }
}

export function requestSetContractstReady(ready) {
    return (dispatch) => {
        dispatch(actionResponse.gen(types.SET_CONTRACTS_READY, ready));
    }
}

