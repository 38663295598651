import React from "react";
import {connect} from "react-redux";
import appConfig from "../appConfig";
import axios from "axios";
import {DataReporter} from "../DataReporter";
import {isMobile} from "react-device-detect";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import FeedbackMessage from "../components/FeedbackMessage";
import HeadlineSection from "../components/landingPage/HeadlineSection";
import CheckAuthenticSection from "../components/landingPage/CheckAuthenticSection";
import FindStolenSection from "../components/landingPage/FindStolenSection";
import NewsletterSection from "../components/landingPage/NewsletterSection";
import ReliableInformationSection from "../components/landingPage/ReliableInformationSection";
import TestimonialsSection from "../components/landingPage/TestimonialsSection";
import RecordSection from "../components/landingPage/RecordSection";
import EnvironmentSection from "../components/landingPage/EnvironmentSection";
import PublicKPISection from "../components/landingPage/publicKPI_Section";
import SectionDivider from "../components/landingPage/SectionDivider";
import CookieConsentBanner from "../components/CookieConsentBanner";
import {requestSetBlockchainLoading, requestSetBlockchainLoadingStop} from "../store/actions/blockchain";
import {
    requestSetClaimingRecord, requestSetCurrentPage,
    requestSetPersona, requestSetSupportPhoneNrs,
    requestSetUserRegion,
    requestSetVRPrices
} from "../store/actions/app";
import "../styles/pages/landing.scss";

class Landing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputSerialNr: null,
            minInputLength: 5,
            inputIsValid: true,
            inputValidMsg: null,
            searchRes: [],
            bigSearchViewed: false,
            errorMessage: null,
            userRegionChecked: false,
            pricingChecked: false,
            phoneNrsChecked: false,
            searchedOnChain: false,
            searchedOnDB: false,
        }

        this.searchFormRef = React.createRef();
    }

    getPricing() {
        if (!this.state.pricingChecked) {
            try {
                axios
                    .get(appConfig.currentConfig.backendApp.url + "/platform/vrpricing",
                        {
                            headers: {
                                Authorization: `Bearer ${appConfig.currentConfig.backendApp.tokens.standard}`,
                                'Content-Type': 'application/json',
                            },
                        })
                    .then(res => {
                        if (res.status === 200) {
                            this.setState({
                                pricingChecked: true
                            }, () => {
                                this.props.dispatchSetVRprices(res.data);
                            });
                        }
                    })
                    .catch(err => {
                        DataReporter.trackSentry(err, { extra: {additionalData: "getPricing (1)"} });
                    })

            } catch (err) {
                DataReporter.trackSentry(err, { extra: {additionalData: "getPricing (2)"} });
            }
        }
    }

    getSupportPhoneNumbers() {
        if (!this.state.phoneNrsChecked) {
            try {
                axios
                    .get(appConfig.currentConfig.backendApp.url + "/platform/phonenrs",
                        {
                            headers: {
                                Authorization: `Bearer ${appConfig.currentConfig.backendApp.tokens.standard}`,
                                'Content-Type': 'application/json',
                            },
                        })
                    .then(res => {
                        if (res.status === 200) {
                            this.setState({
                                phoneNrsChecked: true
                            }, () => {
                                this.props.dispatchSetPhoneNrs(res.data);
                            });
                        }
                    })
                    .catch(err => {
                        DataReporter.trackSentry(err,
                            { extra: {additionalData: "getSupportPhoneNumbers (1)"} });
                    })

            } catch (err) {
                DataReporter.trackSentry(err,
                    { extra: {additionalData: "getSupportPhoneNumbers (2)"} });
            }
        }
    }

    getUserRegion() {
        if (!this.state.userRegionChecked) {
            try {
                axios.get('https://ipinfo.io/json?token=99c415e778f562')
                    .then(response => {
                        this.setState({
                            userRegionChecked: true
                        }, () => {
                            // Relevant country codes: "US" or "DE"
                            this.props.dispatchSetUserRegion(response.data.country);
                        });
                    })
                    .catch(error => {
                        DataReporter.trackSentry(error, "Getting user region (1)");
                    });
            } catch(err) {
                DataReporter.trackSentry(err, "Getting user region (2)");
            }
        }
    }

    getProductWord(capitalize) {
        let productWord = "product";
        if (this.props.appReducer.activePersona === appConfig.personas.watches) {
            productWord = "watch";
        }
        if (capitalize) {
            return productWord.charAt(0).toUpperCase() + productWord.slice(1);
        } else {
            return productWord;
        }
    }

    checkPersona() {
        let persona = new URLSearchParams(this.props.location.search).get("prs");

        if (persona) {
            if (persona.length === 0) persona = null;
        } else if (!persona) {
            persona = this.props.appReducer.activePersona;
        }

        if (persona === appConfig.personas.watches) {
            this.props.dispatchSetPersona(persona);
        } else {
            this.props.dispatchSetPersona(null);
        }
    }

    // Events
    componentDidMount() {
        this.props.dispatchSetPage("landing");
        this.checkPersona();

        DataReporter.trackMixpanel(this.props, "Page view: Landing",
            {
                pageDisplayMode: this.props.appReducer.mobileMode ? "mobile" : "desktop",
                referralCode: new URLSearchParams(this.props.location.search).get("rc"),
                persona: this.props.appReducer.activePersona
            });

        setTimeout(() => this.getUserRegion(), 500);
        setTimeout(() => this.getPricing(), 1000);
        setTimeout(() => this.getSupportPhoneNumbers(), 2500);
    }

    // Renderers
    renderErrorMessage() {
        if (this.state.errorMessage !== null) {
            return <FeedbackMessage
                centered={true}
                success={false}
                message={this.state.errorMessage}
            />;
        }
    }

    // Sections
    renderHeadlineSection() {
        if (this.state.searchRes.length <= 0 && !this.state.bigSearchViewed) {
            let salesMode = new URLSearchParams(this.props.location.search).get("sm");

            return <HeadlineSection
                forMobile={this.props.appReducer.mobileMode}
                salesMode={salesMode}
                searchInputChange={(e) => this.onQueryInputChange(e)}
                errorMessage={this.state.errorMessage}
                validationError={!this.state.inputIsValid}
                validationMsg={this.state.inputValidMsg}
                searchFormRef={this.searchFormRef}
            />;
        }
    }

    renderCheckAuthenticSection() {
        if (this.state.searchRes.length <= 0 && !this.state.bigSearchViewed) {
            return (
                <div>
                    <CheckAuthenticSection
                        forMobile={this.props.appReducer.mobileMode}
                    />
                </div>
            );
        }
    }

    renderStolenSection() {
        return (
            <div>
                <FindStolenSection
                    forMobile={isMobile}
                />
            </div>
        );
    }

    renderReliableInformationSection() {
        if (this.state.searchRes.length <= 0 && !this.state.bigSearchViewed) {
            return <ReliableInformationSection
                forMobile={this.props.appReducer.mobileMode}
            />;
        }
    }

    renderNewsletterSection() {
        if (this.state.searchRes.length <= 0 && !this.state.bigSearchViewed) {
            return <NewsletterSection />;
        }
    }

    renderTestimonialSection() {
        if (this.state.searchRes.length <= 0 && !this.state.bigSearchViewed) {
            return <TestimonialsSection
                forMobile={this.props.appReducer.mobileMode}
            />;
        }
    }

    renderRecordSection() {
        if (this.state.searchRes.length <= 0 && !this.state.bigSearchViewed) {
            return <RecordSection
                forMobile={this.props.appReducer.mobileMode}
            />;
        }
    }

    renderEnvironmentPackage() {
        if (this.state.searchRes.length <= 0 && !this.state.bigSearchViewed) {
            return (
                <EnvironmentSection
                    forMobile={this.props.appReducer.mobileMode}
                />
            );
        }
    }

    renderPublicKPIs() {
        if (this.state.searchRes.length <= 0 && !this.state.bigSearchViewed) {
            return (
                <div>
                    <SectionDivider
                        forMobile={this.props.appReducer.mobileMode}
                    />
                    <PublicKPISection
                        forMobile={this.props.appReducer.mobileMode}
                    />
                </div>
            );
        }
    }

    render() {
        let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";

        document.title = `Ventrace - Level Up Your ${this.getProductWord(true)} Game`;

        return (
            <div>
                <Navigation />
                <div className={"landing-container" + mobileSuffix}>
                    {this.renderHeadlineSection()}
                    {this.renderCheckAuthenticSection()}
                    {this.renderStolenSection()}
                    {this.renderReliableInformationSection()}
                    {this.renderRecordSection()}
                    {this.renderTestimonialSection()}
                    {this.renderEnvironmentPackage()}
                    {this.renderNewsletterSection()}

                    <CookieConsentBanner
                        forMobile={this.props.appReducer.mobileMode}
                    />
                </div>
                <Footer
                    forMobile={this.props.appReducer.mobileMode}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        walletReducer: state.wallet,
        blockchainReducer: state.blockchain,
        appReducer: state.app
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatchBlockchainLoading: () => {
            dispatch(requestSetBlockchainLoading());
        },
        dispatchBlockchainLoadingStop: () => {
            dispatch(requestSetBlockchainLoadingStop());
        },
        dispatchSetPersona: (persona) => {
            dispatch(requestSetPersona(persona));
        },
        dispatchSetClaimingRecord: (claimingRecord) => {
            dispatch(requestSetClaimingRecord(claimingRecord));
        },
        dispatchSetUserRegion: (region) => {
            dispatch(requestSetUserRegion(region));
        },
        dispatchSetVRprices: (vrPrices) => {
            dispatch(requestSetVRPrices(vrPrices));
        },
        dispatchSetPhoneNrs: (phoneNrs) => {
            dispatch(requestSetSupportPhoneNrs(phoneNrs));
        },
        dispatchSetPage: (pageName) => {
            dispatch(requestSetCurrentPage(pageName));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Landing);