import * as types from "../actionTypes/app";

const initState = {
    currentPage: null,
    cookieDecisionMade: false,
    cookiesAccepted: true,
    switchMobileWidth: 1190,
    mobileMode: false,
    onRightNetwork: null,
    adBlockerActive: null,
    autoConnect: false,
    activePersona: "w",
    claimingRecord: null,
    claimingExtension: null,
    orderData: null,
    userRegion: "US",
    userRegionInEurope: false,
    VRprices: {
        standard: 15.00,
        premium: 4.95
    },
    officeAddress: {
        name: "Ventrace - Onur Karaman",
        street: "Postfach 12 01 16",
        postcode: "60114",
        city: "Frankfurt",
        country: "Germany"
    },
    supportPhoneNrs: {
        Europe: "+49 EU",
        USA: "+1 USA"
    }
}

export default function app(state=initState, payload) {
    switch (payload.type) {
        case types.SET_CURRENT_PAGE: {
            return {
                ...state,
                currentPage: payload.data
            }
        }
        case types.ACCEPT_COOKIES: {
            return {
                ...state,
                cookiesAccepted: true,
                cookieDecisionMade: true
            }
        }
        case types.REJECT_COOKIES: {
            return {
                ...state,
                cookiesAccepted: false,
                cookieDecisionMade: true
            }
        }
        case types.SET_MOBILE_MODE: {
            return {
                ...state,
                mobileMode: payload.data
            }
        }
        case types.SET_ON_RIGHT_NETWORK: {
            return {
                ...state,
                onRightNetwork: payload.data
            }
        }
        case types.SET_ADBLOCK_ACTIVE: {
            return {
                ...state,
                adBlockerActive: payload.data
            }
        }
        case types.SET_AUTO_CONNECT: {
            return {
                ...state,
                autoConnect: payload.data
            }
        }
        case types.SET_PERSONA: {
            return {
                ...state,
                activePersona: payload.data
            }
        }
        case types.SET_CLAIMING_RECORD: {
            return {
                ...state,
                claimingRecord: payload.data
            }
        }
        case types.SET_CLAIMING_EXTENSION: {
            return {
                ...state,
                claimingExtension: payload.data
            }
        }
        case types.SET_ORDER_DATA: {
            return {
                ...state,
                orderData: payload.data
            }
        }
        case types.SET_VR_PRICES: {
            return {
                ...state,
                VRprices: payload.data
            }
        }
        case types.SET_USER_REGION: {
            let inEurope = false;

            if (payload.data === "DE" || payload.data === "FR"
                || payload.data === "IT" || payload.data === "CH"
                || payload.data === "NL" || payload.data === "ES"
                || payload.data === "BE" || payload.data === "AT") {

                inEurope = true;
            }

            return {
                ...state,
                userRegion: payload.data,
                userRegionInEurope: inEurope
            }
        }
        case types.SET_SUPPORT_PHONE_NRS: {
            return {
                ...state,
                supportPhoneNrs: payload.data
            }
        }
        default:
            return state;
    }
}