import React from "react";
import UserButton from "./UserButton";
import {FiDelete} from "react-icons/fi";
import {AiOutlineSearch} from "react-icons/ai";
import "../../styles/inputs/textInput.scss";

class TextInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            charLimitMsg: "",
            enterPressed: false,
            escPressed: false,
            inputValue: this.props.value ? this.props.value : "",
            resetted: false
        }
    }

    checkCharLimit(e) {
        if (this.props.showCharLimit) {
            let len = e.target.value.length;

            if (len >= this.props.maxLen - 10) {
                let charsLeft = this.props.maxLen - len;
                this.setState({
                    charLimitMsg: `(${charsLeft} characters left)`
                });
            } else {
                this.setState({charLimitMsg: ""});
            }
        }
    }

    updateParent(e) {
        this.setState({
                inputValue: e.target.value,
                enterPressed: e.key === "Enter",
                escPressed: e.key === "Escape"
            },
            () => {
                if (this.props.onChange) {
                    this.props.onChange(this.state);
                }
            });
    }

    onKeyUp(e) {
        this.checkCharLimit(e);
        this.updateParent(e);
    }

    onChange(e) {
        this.setState({inputValue: e.target.value});
    }

    onResetButtonClick(e) {
        this.setState({
            inputValue: ""
        }, () => {
            if (this.props.onReset) {
                this.props.onReset();
            }
        });
    }

    onSubmitButtonClick() {
        if (this.props.onChange) {
            this.props.onChange({
                enterPressed: true,
                inputValue: this.state.inputValue
            });
        }
    }

    onMouseEnter(e) {
        if (this.props.onMouseEnter) {
            this.props.onMouseEnter(e);
        }
    }

    onMouseLeave(e) {
        if (this.props.onMouseLeave) {
            this.props.onMouseLeave(e);
        }
    }

    // Renderers
    renderLabel() {
        if (this.props.renderLabel) {
            return (
                <div className="label-container">
                    <p className="input-label">
                        {this.props.labelIcon}
                        {this.props.label}
                    </p>

                    <p className="char-limit">
                        {this.state.charLimitMsg}
                    </p>
                </div>
            );
        }
    }

    renderValidationResult() {
        if (this.props.validationError) {
            return (
                <div className={"input-error"}>
                    <p className={"msg"}>
                        {this.props.validationMsg}
                    </p>
                </div>
            )
        }
    }

    renderResetButton() {
        if (this.props.showResetButton) {
            return <UserButton
                id={"reset-button"}
                icon={<FiDelete className={"text-icon"}/>}
                onClick={(e) => this.onResetButtonClick()}
            />;
        }
    }

    renderSubmitButton() {
        if (this.props.showSubmitButton) {
            let icon = <AiOutlineSearch className={"submit-icon"}/>;
            if (this.props.submitIcon) icon = this.props.submitIcon;

            return <UserButton
                id={"submit-button"}
                icon={icon}
                onClick={(e) => this.onSubmitButtonClick()}
            />;
        }
    }

    render() {
        return (
            <div className="text-input-container" id={this.props.id}>
                {this.renderLabel()}
                {this.props.hints ? this.props.hints : null}
                <div className={"input-container"}>
                    <input
                        disabled={this.props.disabled}
                        type={this.props.type}
                        className={this.props.className}
                        id={this.props.inputID}
                        name={this.props.name}
                        placeholder={this.props.placeholder}
                        style={{fontSize: this.props.fontSize, width: this.props.width}}
                        maxLength={this.props.maxLen}
                        onKeyUp={(e) => this.onKeyUp(e)}
                        value={this.state.inputValue}
                        onChange={(e) => this.onChange(e)}
                        onPaste={(e) => this.props.onPaste ? this.props.onPaste(e) : null}
                        autoComplete={this.props.autoComplete}
                        onMouseEnter={(e) => this.onMouseEnter(e)}
                        onMouseLeave={(e) => this.onMouseLeave(e)}
                    />
                    {this.renderResetButton()}
                    {this.renderSubmitButton()}
                </div>
                {this.renderValidationResult()}

            </div>
        )
    }
}

TextInput.defaultProps = {
    renderLabel: true,
    showCharLimit: true,
    type: "text",
    width: "200px",
    className: "text-input",
    autoComplete: "off",
    disabled: false,
    labelIcon: null
}

export default TextInput;