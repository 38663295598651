import React from 'react';
import Mixpanel from "mixpanel-browser";
import {useSelector} from 'react-redux'
import {Link} from "react-router-dom"
import "../styles/components/logo.scss";

const Logo = () => {
    const blockchainReducer = useSelector(state => state.blockchain);

    const renderSpinner = () => {
        if (blockchainReducer.loading) {
            return (
                <div id={"spinner-container"}>
                    <img id="spinner" src={process.env.PUBLIC_URL + '/spinner.gif'} alt={"spinner"}/>
                </div>
            );
        }
    }

    const handleLogoClick = () => {
        window.open("/", "_self");
    };

    return (
        <div className={"logo-container"}>
            <Link onClick={handleLogoClick}>
                <img className={"logo"} src={process.env.PUBLIC_URL + '/VentraceLogo.png'} alt={"logo"}/>
            </Link>
        </div>
    );

}

export default Logo;