export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const ACCEPT_COOKIES = "ACCEPT_COOKIES";
export const REJECT_COOKIES = "REJECT_COOKIES";
export const SET_MOBILE_MODE = "SET_MOBILE_MODE";
export const SET_ON_RIGHT_NETWORK = "SET_ON_RIGHT_NETWORK";
export const SET_ADBLOCK_ACTIVE = "SET_ADBLOCK_ACTIVE";
export const SET_AUTO_CONNECT = "SET_AUTO_CONNECT";
export const SET_PERSONA = "SET_PERSONA";
export const SET_CLAIMING_RECORD = "SET_CLAIMING_RECORD";
export const SET_CLAIMING_EXTENSION = "SET_CLAIMING_EXTENSION";

export const SET_ORDER_DATA = "SET_ORDER_DATA";

export const SET_SUPPORT_PHONE_NRS = "SET_SUPPORT_PHONE_NRS";
export const SET_VR_PRICES = "SET_VR_PRICES";
export const SET_USER_REGION = "SET_USER_REGION";