import React from "react";
import {withRouter} from "react-router-dom";
import "../../styles/inputs/dropdownInput.scss";

class DropdownInput extends React.Component {
    changeHandler(e) {
        if (this.props.onChange) {
            this.props.onChange(e.target.value);
        }
    }

    renderValidationResult() {
        if (this.props.validationError) {
            return (
                <div className={"input-error"}>
                    <p className={"msg"}>
                        {this.props.validationMsg}
                    </p>
                </div>
            )
        }
    }

    render() {
        let options = [];
        for (let i=0; i<this.props.options.length; i+=1) {
            let val = this.props.options[i];
            options.push(<option key={i} value={val}>{val}</option>);
        }

        return (
            <div className={"dropdown-container"} id={this.props.id}>
                <p className={"input-label"}>{this.props.label}</p>
                {this.props.hints ? this.props.hints : null}
                <select
                    disabled={this.props.disabled}
                    className={this.props.className}
                    style={{width: this.props.width}}
                    name={this.props.name}
                    id={this.props.selectID}
                    onChange={(e) => this.changeHandler(e)}>
                    {options}
                </select>

                {this.renderValidationResult()}
            </div>
        );
    }
}

DropdownInput.defaultProps = {
    className: "dropdown",
    width: "200px",
    disabled: false
}

export default withRouter(DropdownInput);