import React from "react";
import "../../styles/inputs/textArea.scss";

class TextArea extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            charLimitMsg: "",
            enterPressed: false,
            escPressed: false,
            inputValue: this.props.value ? this.props.value : null,
        }
    }

    checkCharLimit(e) {
        if (this.props.showCharLimit) {
            let len = e.target.value.length;

            if (len >= this.props.maxLen - 10) {
                let charsLeft = this.props.maxLen - len;
                this.setState({
                    charLimitMsg: `(${charsLeft} characters left)`
                });
            } else {
                this.setState({charLimitMsg: ""});
            }
        }
    }

    updateParent(e) {
        this.setState({
                inputValue: e.target.value,
                enterPressed: e.key === "Enter",
                escPressed: e.key === "Escape"
            },
            () => {
                if (this.props.onChange) {
                    this.props.onChange(this.state);
                }
            });
    }

    onKeyUp(e) {
        this.checkCharLimit(e);
        this.updateParent(e);
    }

    onChange(e) {
        this.setState({inputValue: e.target.value});
    }

    // Renderers
    renderLabel() {
        if (this.props.renderLabel) {
            return (
                <div className="label-container">
                    <p className="input-label">
                        {this.props.label}
                    </p>

                    <p className="char-limit">
                        {this.state.charLimitMsg}
                    </p>
                </div>
            );
        }
    }

    renderValidationResult() {
        if (this.props.validationError) {
            return (
                <div className={"input-error"}>
                    <p className={"msg"}>
                        {this.props.validationMsg}
                    </p>
                </div>
            )
        }
    }

    render() {
        return (
            <div className="text-area-container" id={this.props.id}>
                {this.renderLabel()}
                {this.props.hints ? this.props.hints : null}
                <textarea
                    id={this.props.inputID}
                    rows={this.props.rows}
                    cols={this.props.cols}
                    className={this.props.className}
                    name={this.props.name}
                    placeholder={this.props.placeholder}
                    style={{fontSize: this.props.fontSize, width: this.props.width}}
                    maxLength={this.props.maxLen}
                    onKeyUp={(e) => this.onKeyUp(e)}
                    value={this.state.inputValue || this.props.afterValue}
                    onChange={(e) => this.onChange(e)}
                    onPaste={(e) => this.props.onPaste ? this.props.onPaste(e) : null}
                    disabled={this.props.disabled}
                />

                {this.renderValidationResult()}

            </div>
        )
    }
}

TextArea.defaultProps = {
    rows: 3,
    cols: 80,
    renderLabel: true,
    showCharLimit: true,
    type: "text",
    width: "200px",
    className: "text-area",
    disabled: false
}

export default TextArea;