import React from "react";
import {connect} from "react-redux";
import appConfig from "../appConfig";
import axios from "axios";
import Logger from "js-logger";
import TextArea from "../components/inputs/TextArea";
import {DateConverter} from "../DateConverter";
import {requestSetBlockchainLoading, requestSetBlockchainLoadingStop} from "../store/actions/blockchain";
import UserButton from "./inputs/UserButton";
import FeedbackMessage from "./FeedbackMessage";
import {DataReporter} from "../DataReporter";
import SidebarExplainer from "./SidebarExplainer";
import "../styles/components/disputereporter.scss";


class DisputeReporter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submitting: false,
            submissionSuccessful: null,
            minInputLength: 10,
            problemDescr: null,
            problemDescrValid: true,
            problemDescrValidMsg: null,
            selectedExtensionsIDs: [],
            errorMessage: null,
            disputeOngoing: null,
            ongoingDisputeData: null,
            closed: false,
        }
    }

    getDisputeReportJSON() {
        return {
            "creationDate": Date.now().toString(),
            "originator": this.props.userReducer.email,
            "rootRecordID": this.props.rootRecord.id,
            "rootRecordRouteParam": this.props.rootRecord.routeParam,
            "selectedExtensions": this.state.selectedExtensionsIDs,
            "description": this.state.problemDescr
        }
    }

    getExtListItem(index, type, date) {
        let isSelected = this.state.selectedExtensionsIDs.includes(this.props.completeRecord[index - 1].id);
        let classSelected = isSelected ? " item-selected" : "";

        return (
            <div className={"ext-list-item-container" + classSelected}
                 onClick={() => this.onClickExtension(index - 1)}>
                <p className={"index"}>{index}</p>
                <p className={"type"}>{type}</p>
                <p className={"creation-date"}>
                    {DateConverter.dateObjToString(parseInt(date))}
                </p>
            </div>
        );
    }

    isValidToSubmit() {
        let canSave = true;
        this.setState({
            problemDescrValid: true,
            problemDescrValidMsg: null,
        }, () => {
            DataReporter.trackMixpanel(this.props, "Validating dispute report", {
                category: "Interaction",
            });
        });

        if (!this.state.problemDescr || this.state.problemDescr.length < this.state.minInputLength) {
            this.setState({
                problemDescrValid: false,
                problemDescrValidMsg: "Your description is too short."
            }, () => {
                DataReporter.trackMixpanel(this.props, "Validation Error: Description too short", {
                    category: "Interaction",
                });
            });
            canSave = false;
        }

        let regex = /[<>{;}]/;

        if (regex.test(this.state.problemDescr)) {
            this.setState({
                problemDescrValid: false,
                problemDescrValidMsg: "Your description can't contain special characters."
            }, () => {
                DataReporter.trackMixpanel(this.props, "Validation Error: Description has special characters", {
                    category: "Interaction",
                });
            });

            canSave = false;
        }

        return canSave;
    }

    submitDisputeReport() {
        this.setState({submitting: true},
            async () => {
                if (this.isValidToSubmit()) {

                    axios
                        .post(appConfig.currentConfig.backendApp.url + "/recordDispute", {
                            relevantRootRecordID: parseInt(this.props.rootRecord.id),
                            originator: this.props.userReducer.email,
                            opening_IPFS_CID: this.getDisputeReportJSON()
                        }, {
                            headers: {
                                Authorization: `Bearer ${appConfig.currentConfig.backendApp.tokens.standard}`,
                                'Content-Type': 'application/json',
                            },
                        })
                        .then(res => {
                            if (res.data.success) {
                                this.setState({
                                    submissionSuccessful: true,
                                    submitting: false,
                                    alreadyReported: true
                                })
                            } else {
                                this.setState({
                                    errorMessage: "Loss report not successful. Please try again (1).",
                                    submitting: false,
                                });
                            }
                        })
                        .catch(err => {
                            this.setState({
                                errorMessage: "Loss report not successful. Please try again (2).",
                                submitting: false,
                            });
                        })

                } else {
                    this.setState({
                        submitting: false
                    }, () => {
                        DataReporter.trackMixpanel(this.props, "Dispute reporter: Not valid to submit");
                    });
                }
            });
    }

    checkForOpenDispute() {
        if (!this.state.submitting) {
            this.setState({submitting: true}, () => {
                axios
                    .get(appConfig.currentConfig.backendApp.url + "/recordDispute/getSingle",
                        {
                            headers: {
                                Authorization: `Bearer ${appConfig.currentConfig.backendApp.tokens.standard}`,
                                'Content-Type': 'application/json',
                            },
                            params: {
                                rrID: parseInt(this.props.rootRecord.id)
                            },
                        })
                    .then(async (res) => {
                        if (res.data.success) {
                            if (res.data.disputesFound) {
                                this.setState({
                                    disputeOngoing: true,
                                    closed: res.data.dispute.isClosed,
                                    ongoingDisputeData: res.data.dispute.opening_IPFS_CID,
                                    submitting: false
                                }, () => {
                                    DataReporter.trackMixpanel(this.props, "Dispute reporter: Previous dispute existing");
                                });

                            } else {
                                this.setState({
                                    disputeOngoing: false,
                                    submitting: false
                                });
                            }
                        }
                    })
                    .catch(err => {
                        DataReporter.trackMixpanel(this.props,
                            "Error checking existing loss notice", {category: "Error"});
                        DataReporter.trackSentry(err);
                    })
            })
        }
    }

    getSideBarText() {
        return [
            {
                "name": "Submit the dispute report",
                "descr": "Use this dispute form to describe the problem with your Ventrace Record."
            },
            {
                "name": "Wait for moderation",
                "descr": "Your dispute will be queued for review by the Ventrace Moderation Team. This can take 2-3 days."
            },
            {
                "name": "Dispute decisions",
                "descr": "The dispute may be answered by a comment, by an invalidation (removal) of an update, or a closure" +
                    " of the whole Record."
            },
            {
                "name": "Stay up to date",
                "descr": "You will not receive an email when your dispute gets reviewed. Therefore make sure that you"
                    + " check your Ventrace Record regularly."
            },
        ];
    }

    // Events
    componentDidMount() {
        DataReporter.trackMixpanel(this.props, "Component view: Dispute reporter", {
            pageDisplayMode: this.props.appReducer.mobileMode ? "mobile" : "desktop"
        });
    }

    descrOnChange(e) {
        if (e.inputValue.length >= this.state.minInputLength) {
            this.setState({problemDescr: e.inputValue});
        }
    }

    descrOnPaste(e) {
        this.descrOnChange({inputValue: e.clipboardData.getData("text")});
    }

    onClickExtension(i) {
        let e = this.state.selectedExtensionsIDs;
        let addIndex = this.props.completeRecord[i].id;

        if (e.includes(addIndex)) {
            e.splice(e.indexOf(addIndex, 1));
        } else {
            e.push(this.props.completeRecord[i].id);
        }

        this.setState({
            selectedExtensionsIDs: e
        }, () => {
            DataReporter.trackMixpanel(this.props, "Dispute reporter: Clicked on extension");
        })
    }

    // Renderers
    renderErrorMessage() {
        if (this.state.errorMessage !== null) {
            return <FeedbackMessage
                success={false}
                message={this.state.errorMessage}
            />;
        }
    }

    renderExtensions() {
        let extListItems = [];
        for (let i = 1; i < this.props.completeRecord.length; i += 1) {
            let recItem = this.props.completeRecord[i];

            if (i === 0) {
                extListItems.push(this.getExtListItem(i + 1, "Initial record", recItem.rootRecordData.purchaseDate));
            } else {
                if (recItem.extData.recordType === "ownershipTransferOpening") {
                    extListItems.push(this.getExtListItem(i + 1, "Transfer opening",
                        recItem.extData.recordCreationDate));

                } else if (recItem.extData.recordType === "ownershipTransferConfirmation") {
                    extListItems.push(this.getExtListItem(i + 1, "Transfer confirmation", recItem.extData.recordCreationDate));

                } else if (recItem.extData.recordType === "setPrivateMode") {
                    extListItems.push(this.getExtListItem(i + 1, "Set private", recItem.extData.recordCreationDate));

                } else if (recItem.extData.recordType === "setPublicMode") {
                    extListItems.push(this.getExtListItem(i + 1, "Set public", recItem.extData.recordCreationDate));

                } else if (recItem.extData.recordType === "setPublicModeDue") {
                    extListItems.push(this.getExtListItem(i + 1, "Set public due", recItem.extData.recordCreationDate));

                } else if (recItem.extData.recordType === "branchCreated") {
                    extListItems.push(this.getExtListItem(i + 1, "Branch created", recItem.extData.recordCreationDate));

                } else if (recItem.extData.recordType === "branchAccepted") {
                    extListItems.push(this.getExtListItem(i + 1, "Branch accepted", recItem.extData.recordCreationDate));

                } else if (recItem.extData.recordType === "branchDueAccepted") {
                    extListItems.push(this.getExtListItem(i + 1, "Branch due", recItem.extData.recordCreationDate));

                } else if (recItem.extData.recordType === "branchDismissed") {
                    extListItems.push(this.getExtListItem(i + 1, "Branch dismissed", recItem.extData.recordCreationDate));

                } else if (recItem.extData.recordType === "generalExtension") {
                    extListItems.push(this.getExtListItem(i + 1, recItem.extData.changeType, recItem.extData.recordCreationDate));

                }
            }
        }
        return (
            <div id={"record-li-container"}>
                <p id={"h"}>Relevant update (if applicable)</p>
                <p id={"descr"}>
                    The following extensions are summarized from the current <span
                    className={"vr-hl"}>Ventrace Record</span>.
                </p>
                {extListItems}
            </div>
        );
    }

    renderDisputeOngoing() {
        if (this.state.disputeOngoing === true && !this.state.closed) {
            let date = DateConverter.dateObjToString(parseInt(this.state.ongoingDisputeData.creationDate));
            return (
                <div id={"dispute-ongoing-container"}>
                    <p id={"info"}>
                        You've already created a dispute about this Record (created on {date}).<br/>
                        Check the Record's history for comments by Ventrace moderators.<br/><br/>
                        Otherwise, if you have another concern, you can use the&nbsp;
                        <a href={"/support"}>Help & Support</a> page.
                    </p>
                </div>
            );
        }
    }

    renderSubmitContainer() {
        if (this.state.submissionSuccessful === null) {
            let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";

            let disabled = this.state.submitting;

            let spinner;
            if (this.state.submitting) {
                spinner = <div id={"spinner-container" + mobileSuffix}>
                    <img id="spinner" src={process.env.PUBLIC_URL + '/spinner.gif'} alt={"spinner"}/>
                </div>;
            }

            return (
                <div id={"submit-dispute-container"}>
                    {this.renderErrorMessage()}
                    <div className={"inline" + mobileSuffix}>
                        <UserButton
                            forMobile={this.props.appReducer.mobileMode}
                            id={"submit-dispute-button"}
                            value={disabled ? "Sending dispute report" : "Send dispute report"}
                            onClick={() => this.submitDisputeReport()}
                            disabled={disabled}
                        />
                        {spinner}
                    </div>
                </div>
            );
        }
    }

    renderSubmitForm() {
        return (
            <div>
                <h1>Submit a Ventrace Record dispute</h1>
                <p id={"descr"}>
                    If you think that this Record contains false information, you can submit a formal
                    dispute to Ventrace. Your request will be moderated by human beings.
                </p>

                <TextArea
                    id={"problem-descr-input"}
                    label={"Problem description*"}
                    maxLen={140}
                    placeholder={"What's wrong with the record?"}
                    onChange={(e) => this.descrOnChange(e)}
                    onPaste={(e) => this.descrOnPaste(e)}
                    validationError={!this.state.problemDescrValid}
                    validationMsg={this.state.problemDescrValidMsg}
                />

                {this.renderExtensions()}
                {this.renderSubmitContainer()}
            </div>
        );
    }

    render() {
        let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";

        let content = null

        if (!this.state.submissionSuccessful) {
            if (this.state.disputeOngoing === null) {
                this.checkForOpenDispute();
                content = (
                    <div id={"checking-container"} className={"inline"}>
                        <img id="spinner" src={process.env.PUBLIC_URL + '/spinner.gif'} alt={"spinner"}/>
                        <p id={"info"}>Checking if a dispute has already been reported.</p>
                    </div>
                );

            } else if (this.state.disputeOngoing === false || this.state.closed) {
                content = this.renderSubmitForm();
            }
        } else if (this.state.submissionSuccessful) {
            content = (
                <FeedbackMessage
                    success={true}
                    message={"Your dispute has been reported."}
                />);
        }

        return (
            <div className={"dispute-recorder-container" + mobileSuffix}>
                <div id={"left"}>
                    {content}
                </div>
                <div id={"right"}>
                    <SidebarExplainer
                        forMobile={this.props.appReducer.mobileMode}
                        id={"sidebar-explainer"}
                        h1={"How a reporting a dispute works"}
                        steps={this.getSideBarText()}
                    />
                </div>
                {this.renderDisputeOngoing()}

                <div id={"clearer"}/>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        userReducer: state.user,
        walletReducer: state.wallet,
        blockchainReducer: state.blockchain,
        appReducer: state.app
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatchBlockchainLoading: () => {
            dispatch(requestSetBlockchainLoading())
        },
        dispatchBlockchainLoadingStop: () => {
            dispatch(requestSetBlockchainLoadingStop())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DisputeReporter);