import React from "react";
import {connect} from "react-redux";
import appConfig from "../appConfig";
import axios from "axios";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import {DataReporter} from "../DataReporter";
import {requestRemoveEmail, requestRemoveLoginToken} from "../store/actions/user";
import { AiOutlineSave, AiOutlineLogout } from "react-icons/ai";
import { FaRegAddressCard } from "react-icons/fa";
import TextInput from "../components/inputs/TextInput";
import UserButton from "../components/inputs/UserButton";
import "../styles/pages/myaccount.scss";
import FeedbackMessage from "../components/FeedbackMessage";

class MyAccount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            currentDisplayName: null,
            displayName: null,
            displayNameInvalidMsg: null,
            displayNameChangeSuccess: false,
            minInputLength: 5
        };
    }

    componentDidMount() {
        DataReporter.trackMixpanel(this.props,
            "Page view: My Account",
            {
                pageDisplayMode: this.props.appReducer.mobileMode ? "mobile" : "desktop"
            });
        this.getCurrentUserProfile();
    }

    getCurrentUserProfile() {
        this.setState({
            loading: true
        }, () => {
            try {
                axios
                    .get(appConfig.currentConfig.backendApp.url + "/vrholder/getsingle",
                        {
                            headers: {
                                Authorization: `Bearer ${appConfig.currentConfig.backendApp.tokens.standard}`,
                                'Content-Type': 'application/json'
                            },
                            params: {
                                email: this.props.userReducer.email
                            }
                        })
                    .then(res => {
                        if (res.status === 200) {
                            let token = res.data.token;
                            if (token !== this.props.userReducer.loginToken) {
                                this.props.dispatchRemoveLoginToken();

                                setTimeout(() => {
                                    window.open("/", "_self");
                                }, 500);
                            }

                            this.setState({
                                currentDisplayName: res.data.displayName,
                                loading: false
                            });
                        } else {
                            DataReporter.trackMixpanel(this.props,
                                "Error: Getting user profile");
                        }
                    })
                    .catch(err => {
                        DataReporter.trackSentry(err, { extra: {additionalData: "getCurrentUserProfile"} });
                    })

            } catch (err) {
                DataReporter.trackSentry(err, { extra: {additionalData: "getCurrentUserProfile"} });
            }
        });
    }

    validateDisplayName() {
        let isValid = true;

        if (this.state.displayName.length < this.state.minInputLength) {
            isValid = false;
            this.setState({
                displayNameInvalidMsg: "Your display name is too short."
            });
        }

        return isValid;
    }

    saveDisplayName() {
        this.setState({
            displayNameInvalidMsg: null,
            displayNameChangeSuccess: false
        }, () => {
            if (this.validateDisplayName()) {
                try {
                    axios
                        .post(appConfig.currentConfig.backendApp.url + "/vrholder/displayname",
                            {
                                email: this.props.userReducer.email,
                                displayName: this.state.displayName,
                                loginToken: this.props.userReducer.loginToken
                            },
                            {
                                headers: {
                                    Authorization: `Bearer ${appConfig.currentConfig.backendApp.tokens.standard}`,
                                    'Content-Type': 'application/json',
                                },
                            })
                        .then(res => {
                            if (res.status == 200) {
                                this.setState({
                                    currentDisplayName: res.data.displayName,
                                    displayNameChangeSuccess: true,
                                    loading: false
                                }, () => {
                                    DataReporter.trackMixpanel(this.props,
                                        "Login: Display name changed", {page: "My account"}
                                    );
                                });
                            }
                        })
                        .catch(err => {
                            DataReporter.trackSentry(err, {extra: {additionalData: "saveDisplayName 1"}});
                            this.setState({
                                loading: false,
                                displayNameInvalidMsg: "No connection. Please try again."
                            }, () => {
                                DataReporter.trackMixpanel(this.props,
                                    "Login: Email error 1", {page: "My account"}
                                );
                            });
                        })
                } catch (err) {
                    DataReporter.trackSentry(err, {extra: {additionalData: "saveDisplayName 2"}});

                    this.setState({
                        loading: false,
                        displayNameInvalidMsg: "Change failed. Please try again."
                    }, () => {
                        DataReporter.trackMixpanel(this.props,
                            "Login: Email error 2", {page: "My account"}
                        );
                    });
                }
            }
        })
    }

    // Events
    onDisplayNameChange(e) {
        this.setState({displayName: e.inputValue});
    }

    onDisplaynamePaste(e) {
        this.onDisplayNameChange({inputValue: e.clipboardData.getData("text")});
    }

    onLogOutButtonClick() {
        this.setState({
            loading: true
        }, () => {
            DataReporter.trackMixpanel(this.props,
                "Login: Logging out", {page: "My account"}
            );
            this.props.dispatchRemoveLoginToken();
            this.props.dispatchRemoveEmail();

            setTimeout(() => {
                window.open("/", "_self");
            }, 500);
        })
    }

    // Renderers
    renderDisplayNameForm() {
        let currentDisplayName = null;
        if (this.state.currentDisplayName) {
            currentDisplayName = (
                <p id={"current-display-name"}>
                    <FaRegAddressCard id={"icon"} />
                    {this.state.currentDisplayName}
                </p>
            );
        }

        let feedbackMsg = null;
        if (this.state.displayNameChangeSuccess) {
            feedbackMsg = (
                <FeedbackMessage
                    success={true}
                    message={"Your display name has been updated."}
                />
            )
        }

        return (
            <div id={"display-name-form"}>
                <h2>Display name</h2>

                {currentDisplayName}

                <p id={"display-name-descr"}>
                    Your display name will be used as your owner name in all of your Ventrace Records.
                </p>

                <div className={"inline"}>
                    <TextInput
                        inputID={"display-name-input"}
                        label={`Your new display name`}
                        value={this.state.displayName}
                        placeholder={`Your new display name`}
                        onChange={(e) => this.onDisplayNameChange(e)}
                        onPaste={(e) => this.onDisplaynamePaste(e)}
                        validationError={this.state.displayNameInvalidMsg}
                        validationMsg={this.state.displayNameInvalidMsg}
                        maxLen={50}
                    />

                    <UserButton
                        forMobile={this.props.appReducer.mobileMode}
                        id={"save-name-button"}
                        icon={<AiOutlineSave className={"text-icon"}/>}
                        value={"Save"}
                        onClick={() => this.saveDisplayName()}
                        disabled={this.state.loading}
                    />
                </div>

                {feedbackMsg}
            </div>
        );
    }

    logOutForm() {
        return (
            <div id={"logout-form"}>
                <h2>Log out</h2>
                <p id={"logout-descr"}>
                    Please keep in mind, that you need access to your personal email account, in case you want
                    to log back in.
                </p>

                <UserButton
                    forMobile={this.props.appReducer.mobileMode}
                    id={"logout-button"}
                    icon={<AiOutlineLogout className={"text-icon"}/>}
                    value={"Log out"}
                    onClick={() => this.onLogOutButtonClick()}
                    disabled={this.state.loading}
                />
            </div>
        )
    }

    render() {
        if (this.props.userReducer.loginToken) {
            let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";

            document.title = "Ventrace - My Account";
            return (
                <div>
                    <Navigation />

                    <div className={"content-container" + mobileSuffix}
                         id={"my-account-container" + mobileSuffix } >
                        <h1>My account</h1>
                        <p id={"account-descr"}>
                            Here you can configure settings about your personal account,
                            which will have a platform-wide effect on Ventrace.
                        </p>

                        {this.renderDisplayNameForm()}
                        {this.logOutForm()}
                    </div>

                    <Footer />
                </div>
            );
        } else {
            window.open("/", "_self");
        }
    }
}

const mapStateToProps = (state) => {
    return {
        appReducer: state.app,
        userReducer: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatchRemoveLoginToken: () => {
            dispatch(requestRemoveLoginToken())
        },
        dispatchRemoveEmail: () => {
            dispatch(requestRemoveEmail())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);