import React from "react";
import {connect} from "react-redux";
import appConfig from "../../appConfig";
import {DataFormatter} from "../../DataFormatter";
import "../../styles/components/publicKPI_Section.scss";

class PublicKPI_Section extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            gettingKPIs: false,
            recordCount: null,
            vtrSupply: null,
            displayRecordCount: 0,
            displayVTRsupply: 0,
            counterStartPercentage: 0.2
        }
    }

    getKPIS() {
        if (!this.state.gettingKPIs && !this.state.recordCount) {
            this.setState({gettingKPIs: true}, () => {
                setTimeout(() => {

                    try {
                        let recordStorageContract = this.props.blockchainReducer.recordStorageContract;
                        let vtrContract = this.props.blockchainReducer.vtrTokenContract;

                        recordStorageContract.methods.rootRecordID()
                            .call({
                                from: this.props.walletReducer.connectedAddress,
                                gas: appConfig.currentConfig.blockchainGasLimit
                            })
                            .then((receipt_rootRecordID) => {

                                vtrContract.methods.totalSupply()
                                    .call({
                                        from: this.props.walletReducer.connectedAddress,
                                        gas: appConfig.currentConfig.blockchainGasLimit
                                    })
                                    .then((receipt_vtrSupply) => {

                                        let startPct = this.state.counterStartPercentage;

                                        let recordCount = receipt_rootRecordID;
                                        let vtrSupply = receipt_vtrSupply / 10 ** 5;

                                        this.setState(
                                            {
                                                recordCount: recordCount,
                                                vtrSupply: vtrSupply,
                                                displayRecordCount: Math.ceil(recordCount * startPct),
                                                displayVTRsupply: Math.ceil(vtrSupply * startPct)
                                            }
                                            , () => {
                                                setTimeout(() => this.countUpRecordCount(), 6000);
                                                setTimeout(() => this.countUpVTRSupply(), 6000);
                                            });
                                    })


                            })
                            .catch((err) => {
                                console.log(1);
                            })
                    } catch (err) {
                        console.log(1);
                    }
                }, 1000);
            })
        }

    }

    componentDidMount() {
        this.getKPIS();
    }

    countUpRecordCount() {
        if (this.state.displayRecordCount > 0) {
            let nextRecordCount = this.state.displayRecordCount + 1;
            let _continue = true;

            if (nextRecordCount >= this.state.recordCount) {
                nextRecordCount = this.state.recordCount;
                _continue = false;
            }

            this.setState({
                    displayRecordCount: nextRecordCount
                },
                () => {
                    if (_continue) {
                        setTimeout(() => this.countUpRecordCount(), 2000);
                    }
                })
        }
    }

    countUpVTRSupply() {
        if (this.state.displayVTRsupply > 0) {
            let nextVTRsupplyCount = this.state.displayVTRsupply + 1;
            let _continue = true;

            if (nextVTRsupplyCount >= this.state.vtrSupply) {
                nextVTRsupplyCount = this.state.vtrSupply;
                _continue = false;
            }

            this.setState({
                    displayVTRsupply: nextVTRsupplyCount
                },
                () => {
                    if (_continue) {
                        setTimeout(() => this.countUpVTRSupply(), 350);
                    }
                })
        }
    }

    // Renderers
    renderKPI(name, value, id=null) {
        return (
            <div className={"kpi-container"} id={id}>
                <p className={"value"}>{value}</p>
                <p className={"name"}>{name}</p>
            </div>
        );
    }

    render() {
        if (this.state.recordCount && this.state.vtrSupply) {
            let mobileSuffix = this.props.forMobile ? "-mobile" : "";
            let infoBoxContainerClass = "infobox-container";
            let infoBoxKPIClass = "infobox-kpis";

            if (this.props.forMobile) {
                infoBoxContainerClass += mobileSuffix;
                infoBoxKPIClass += mobileSuffix;
            }

            return (
                <div className={infoBoxContainerClass + " " + infoBoxKPIClass}>

                    <div id={"content"}>
                        <p id={"headline"}>Ventrace by the numbers</p>

                        <div className={"kpis"}>
                            {this.renderKPI("Ventrace Records created",
                                DataFormatter.numberToDecimals(this.state.displayRecordCount),
                                "records-created")}

                            {this.renderKPI("Ventrace crypto tokens in circulation",
                                DataFormatter.numberToDecimals(this.state.displayVTRsupply),
                            "vtr-circulation")}
                        </div>
                    </div>

                </div>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        walletReducer: state.wallet,
        blockchainReducer: state.blockchain
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(PublicKPI_Section);