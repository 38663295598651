import React from "react";
import {connect} from "react-redux";
import {DateConverter} from "../../DateConverter";
import { PiSealWarning } from "react-icons/pi";
import "../../styles/components/pendingextensions.scss";

class PendingExtensions extends React.Component {

    getClaimableExtensionsCount() {
        let count = 0;
        for (let i=0; i < this.props.pendingExtensions.length; i+= 1) {
            let isClaimable = this.props.pendingExtensions[i].isClaimable;
            if (isClaimable === "1" || isClaimable === true) count += 1;
        }
        return count;
    }

    // Events
    onItemClick(ext) {
        this.props.onClaimExtension(ext);
    }

    // Renderers
    renderPendingExtItems() {
        if (this.props.userReducer.loginToken) {
            let items = [];

            for (let i=0; i < this.props.pendingExtensions.length; i+=1) {
                let curr = this.props.pendingExtensions[i];

                if (curr.isClaimable) {
                    let capType = "Modification";
                    if (curr.extData.capType === "Damage") capType = "Damage";
                    if (curr.extData.capType === "Other") capType = "Custom";

                    items.push(
                        <div className={"item"} onClick={() => this.onItemClick(curr)}>
                            <p className={"label"} id={"type-label"}>Update type</p>
                            <p id={"cap-type"}>{capType}</p>

                            <p className={"label"} id={"happened-label"}>Happened on</p>
                            <p id={"happened-on"}>
                                {DateConverter.dateObjToString(new Date(curr.extData.capDate))}
                            </p>

                            <p className={"label"} id={"provided-by-label"}>Update provided by</p>
                            <p id={"provider-name"}>
                                {curr.providerName}
                            </p>
                        </div>
                    );
                }
            }

            return (
                <div id={"items"}>
                    {items.reverse()}
                </div>
            );
        }
    }

    renderCreationHint() {
        if (this.props.userReducer.loginToken) {
            return (
                <p id={"creation-hint"}>
                    Select an update to add it to your Ventrace Record.
                </p>
            );
        }
    }

    renderForOwnersOnly() {
        if (!this.props.userReducer.loginToken) {
            return (
                <p id={"order-text"}>
                    Order this Ventrace Record to see these product updates in detail.
                </p>
            );
        }
    }

    render() {
        let count = this.getClaimableExtensionsCount();

        if (count > 0) {
            let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";

            return (
                <div id={"pending-ext-container" + mobileSuffix}>
                    <div id={"h-container"}>
                        <PiSealWarning id={"icon"} />
                        <p id={"h"}>
                            This Record has<span id={"count"}>{count}</span>pending update{count >= 2 ? "s" : ""}
                        </p>
                    </div>

                    {this.renderForOwnersOnly()}
                    {this.renderPendingExtItems()}
                    {this.renderCreationHint()}

                </div>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        userReducer: state.user,
        appReducer: state.app
    }
}

export default connect(mapStateToProps, null)(PendingExtensions);