import React from "react";
import Logger from "js-logger";
import {connect} from "react-redux";
import {create} from "ipfs-http-client";
import appConfig from "../appConfig";
import * as Sentry from "@sentry/react";
import Mixpanel from "mixpanel-browser";
import UserButton from "../components/inputs/UserButton";
import FeedbackMessage from "./FeedbackMessage";
import VTRCalculator from "./VTRCalculator";
import TRXReceipt from "./TRXReceipt";
import HintContainer from "./HintContainer";
import {requestSetBlockchainLoading, requestSetBlockchainLoadingStop} from "../store/actions/blockchain";
import {EIP712types} from "../EIP712types";
import "../styles/components/duepublicsetter.scss";
import {DataReporter} from "../DataReporter";

class DuePublicSetter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submittingBlockchain: false,
            submissionSuccessful: false,
            trxReceipt: false,
            errorMessage: null
        }
    }

    getSetPublicDueJSON() {
        return {
            "recordType": "setPublicModeDue",
            "recordCreationDate": Date.now().toString(),
            "originator": this.props.walletReducer.connectedAddress,
        }
    }

    async setPublic() {
        this.props.dispatchBlockchainLoading();

        // Upload data as JSON to IPFS
        let auth = "Basic " + Buffer.from(appConfig.IPFS_ProjectID + ":" + appConfig.IPFS_Key).toString("base64");
        let client = create({url: appConfig.IPFS_Endpoint, headers: {authorization: auth}});
        let storedJSON = await client.add(JSON.stringify(this.getSetPublicDueJSON()));

        // Send JSON of new RootRecord to blockchain

        try {
            let extType = this.getSetPublicDueJSON().recordType;

            let signature = await window.ethereum.request({
                method: 'eth_signTypedData_v4',
                params: [this.props.walletReducer.connectedAddress,
                    JSON.stringify(EIP712types.newExtension(parseInt(this.props.rootRecord[0]), extType))
                ],
            });

            let rsv = EIP712types.getRSVfromSignature(signature);

            let recordManagerContract = this.props.blockchainReducer.recordManagerContract;

            recordManagerContract.methods.addExtensionRecord(parseInt(this.props.rootRecord[0]),
                storedJSON.path, extType, "", 0, rsv.r, rsv.s, rsv.v)
                .send({
                    from: this.props.walletReducer.connectedAddress, gas: this.state.estimatedGasUse
                })
                .then((addExtensionReceipt) => {
                    Logger.info(`Successfully switched to public mode hash: ${addExtensionReceipt.transactionHash}`);

                    DataReporter.trackMixpanel(this.props, "Success: Set due public", {
                        category: 'Interaction'
                    });

                    this.props.dispatchBlockchainLoadingStop();
                    this.onSubmissionSuccessful(addExtensionReceipt);
                })
                .catch((err) => {
                    this.props.dispatchBlockchainLoadingStop();

                    this.setState({
                        errorMessage: "Error during due public switch (1). Please try again later."
                    }, () => {
                        DataReporter.trackSentry(err, {
                            extra: {additionalData: "Due public setter: addExtRecord."}
                        });
                    });
                })
        } catch (err) {
            this.props.dispatchBlockchainLoadingStop();

            let msg = "Signing failed. Please check if your wallet is configured correctly.";
            if (err.message.toLowerCase().includes("chainid")) {
                msg = `Signing failed. Please check if your wallet is connected to the ${appConfig.currentConfig.blockchainName} network.`;
            }

            this.setState({
                errorMessage: msg,
                switchingPrivacy: false
            }, () => {
                DataReporter.trackSentry(err, {
                    extra: {additionalData: "Signing due public setter."}
                });
            });
        }
    }

    // Events
    onSubmissionSuccessful(receipt) {
        this.setState({
            submissionSuccessful: true,
            submittingBlockchain: false,
            trxReceipt: receipt,
            errorMessage: null
        })
    }

    onButtonSetPublic() {
        this.setState({
            submittingBlockchain: true
        }, async () => {
            this.setPublic()
        });
    }

    onEstimatedGasUse(estimate) {
        this.setState({
            estimatedGasUse: estimate
        });
    }

    // Renderers

    renderErrorMessage() {
        if (this.state.errorMessage !== null) {
            return <FeedbackMessage
                success={false}
                message={this.state.errorMessage}
            />;
        }
    }

    renderTRXReceipt() {
        if (this.state.submissionSuccessful !== null) {
            let last = this.state.trxReceipt;
            return <TRXReceipt
                descr={"Reactivated public mode"}
                trxHash={last.transactionHash}
                trxGasUsed={last.gasUsed}
                estimatedGas={this.state.estimatedGasUse}
            />;
        }
    }

    renderSubmitContainer() {
        if (this.props.walletReducer.noWalletMode) {
            return (
              <div id={"get-wallet-container"}>
                  <p id={"h"}>Connect your wallet</p>
                  <p id={"descr"}>
                      In order to set this record to public mode, you first have to connect your wallet.<br/>
                  </p>
              </div>
            );
        } else {
            let content;
            if (!this.state.submissionSuccessful) {
                content = <div id={"submit-container"}>
                    <VTRCalculator
                        id={"vtr-calc"}
                        posName={`Reactivate public mode`}
                        rateName={"getRateSetPublicDue"}
                        onGetGasUseEstimate={(estimate) => this.onEstimatedGasUse(estimate)}
                    />
                    {this.renderErrorMessage()}
                    <UserButton
                        forMobile={this.props.appReducer.mobileMode}
                        id={"submit-button"}
                        value={"Reactivate public mode"}
                        onClick={() => this.onButtonSetPublic()}
                        disabled={this.state.submittingBlockchain}
                    />
                </div>;
            } else {
                content = <div>
                    {this.renderTRXReceipt()}
                    <p id={"reload-info"}>Automatically reloading Record details...</p>
                </div>;

                setTimeout(() => {
                    this.props.reloadRecord();
                }, 3000);
            }
            return content;
        }
    }

    render() {
        let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";

        return (
            <div className={"due-public-setter-container" + mobileSuffix}>
                <h2>The private mode duration has elapsed</h2>
                <p id={"descr"}>
                    The configured time for this Record to be in private mode has elapsed.
                </p>
                <HintContainer
                    forMobile={this.props.appReducer.mobileMode}
                    id={"hint-container"}
                    hints={[
                        "This record can be switched back to public mode by anyone at this point.",
                        "Since you're already here, you can switch this record back, too.",
                        "You will earn VTR for your contribution to Ventrace."
                    ]}
                />
                {this.renderSubmitContainer()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        walletReducer: state.wallet,
        blockchainReducer: state.blockchain,
        appReducer: state.app
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatchBlockchainLoading: () => {
            dispatch(requestSetBlockchainLoading())
        },
        dispatchBlockchainLoadingStop: () => {
            dispatch(requestSetBlockchainLoadingStop())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DuePublicSetter);