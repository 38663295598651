import React from "react";
import {connect} from "react-redux";
import appConfig from "../appConfig";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import {DataReporter} from "../DataReporter";
import {AiFillPhone, AiOutlineMail} from "react-icons/ai";
import { RiYoutubeLine } from "react-icons/ri";
import { FaRegFilePdf } from "react-icons/fa6";
import "../styles/pages/providers.scss";

class Providers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        DataReporter.trackMixpanel(this.props,
            "Page view: Providers",
            {
                pageDisplayMode: this.props.appReducer.mobileMode ? "mobile" : "desktop"
            });
    }

    // Events
    onEmailClick() {
        DataReporter.trackSentry(this.props, "Contact via mail", {
            category: "Interaction"
        });
        window.location.href = `mailto:${appConfig.supportEmail}`;
    }

    // Renderers
    renderContactSection() {
        let phoneNr = this.props.appReducer.supportPhoneNrs.USA;
        if (this.props.appReducer.userRegionInEurope) {
            phoneNr = this.props.appReducer.supportPhoneNrs.Europe;
        }

        return (
            <div id={"provider-contact-container"}>
                <h2>Get in touch now</h2>
                <p id={"descr"}>
                    We're looking forward to work with you. Let's get in touch!
                </p>

                <div className={"inline"}>
                    <div id={"phone-box-container"}>
                        <p id={"h"}>Contact us per phone</p>
                        <p id={"phone-nr"}><AiFillPhone id={"phone-icon"}/> {phoneNr}</p>
                        <p id={"d"}>
                            This is a free service phone number. However, your phone service
                            provider may generally charge you for outgoing calls.
                        </p>
                    </div>

                    <div id={"email-box"}>
                        <div id={"descr-container"} onClick={() => this.onEmailClick()}>
                            <div className={"inline"}>
                                <AiOutlineMail id={"icon"} onClick={() => this.onEmailClick()}/>
                                <p id={"h"}>Contact per email</p>
                            </div>
                            <p id={"descr"}>
                                Send your question per mail and get help directly.
                                You can expect to get an answer within 1-2 days.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderMainMessageSection() {
        return (
            <div id={"message-container"}>
                <h2>Elevate Your Watch Business: Boost Trust and Profits with Ventrace</h2>
                <p id={"descr"}>
                    Offer your customers more ways to build a lasting and beneficial
                    relationship with your business.
                </p>

            </div>
        );
    }

    renderResources() {
        return (
            <div id={"resources-container"}>
                <h2>Resources</h2>
                <p id={"descr"}>
                    Do you need more details? Take a look at the documents below to find your answers.
                </p>

                <div id={"resource-items"}>
                    <div className={"res-item"}>
                        <FaRegFilePdf className={"pdf-icon"} />
                        <p className={"resource-name"}>
                            The Ventrace Platform Explainer
                        </p>
                    </div>

                    <div className={"res-item"}>
                        <FaRegFilePdf className={"pdf-icon"} />
                        <p className={"resource-name"}>
                            Provider Delivery Requirements
                        </p>
                    </div>

                    <div className={"res-item"}>
                        <RiYoutubeLine className={"yt-icon"} />
                        <p className={"resource-name"}>
                            Video Tutorial
                        </p>
                    </div>

                </div>
            </div>
        );
    }

    render() {
        let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";
        document.title = "Ventrace - Providers";

        return (
            <div>
                <Navigation />
                <div className={"content-container" + mobileSuffix}
                     id={"provider-container"+ mobileSuffix}>
                    <h1>Providers Overview</h1>

                    {this.renderMainMessageSection()}
                    {this.renderContactSection()}
                    {this.renderResources()}
                </div>
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        appReducer: state.app,
    }
}

export default connect(mapStateToProps, null)(Providers);