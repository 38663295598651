import * as types from "../actionTypes/user";

const initState = {
    loginToken: null,
    email: null
}

export default function user(state = initState, payload) {
    switch (payload.type) {
        case types.SET_LOGIN_TOKEN: {
            return {
                ...state,
                loginToken: payload.data
            }
        }
        case types.SET_EMAIL: {
            return {
                ...state,
                email: payload.data
            }
        }
        case types.REMOVE_LOGIN_TOKEN: {
            return {
                ...state,
                loginToken: false
            }
        }
        case types.REMOVE_EMAIL: {
            return {
                ...state,
                email: false
            }
        }
        default:
            return state;
    }
}