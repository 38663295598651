import React from "react";
import {connect} from "react-redux";
import {create} from "ipfs-http-client";
import Mixpanel from "mixpanel-browser";
import appConfig from "../appConfig";
import Logger from "js-logger";
import UserButton from "../components/inputs/UserButton";
import FeedbackMessage from "./FeedbackMessage";
import VTRCalculator from "./VTRCalculator";
import TRXReceipt from "./TRXReceipt";
import {requestSetBlockchainLoading, requestSetBlockchainLoadingStop} from "../store/actions/blockchain";
import {EIP712types} from "../EIP712types";
import "../styles/components/duebranchmerger.scss";
import * as Sentry from "@sentry/react";
import {DataReporter} from "../DataReporter";

class DueBranchMerger extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submittingBlockchain: false,
            submissionSuccessful: false,
            trxReceipt: false,
            errorMessage: null
        }
    }

    getBranchDueAcceptedJSON() {
        return {
            "recordType": "branchDueAccepted",
            "recordCreationDate": Date.now().toString(),
            "originator": this.props.walletReducer.connectedAddress
        }
    }

    onAcceptBranch() {
        if (!this.state.submittingBlockchain) {
            this.setState({
                submittingBlockchain: true
            }, async () => {
                this.props.dispatchBlockchainLoading();

                let auth = "Basic " + Buffer.from(appConfig.IPFS_ProjectID + ":" + appConfig.IPFS_Key).toString("base64");
                let client = create({url: appConfig.IPFS_Endpoint, headers: {authorization: auth}});
                let storedJSON = await client.add(JSON.stringify(this.getBranchDueAcceptedJSON()));

                let recordStorageContract = this.props.blockchainReducer.recordStorageContract;
                recordStorageContract.methods.getRouteToRootRecordID(this.props.rootRecord[8])
                    .call({
                        from: this.props.walletReducer.connectedAddress,
                        gas: appConfig.currentConfig.blockchainGasLimit
                    })
                    .then(async (res) => {
                        try {
                            let signature = await window.ethereum.request({
                                method: 'eth_signTypedData_v4',
                                params: [this.props.walletReducer.connectedAddress,
                                    JSON.stringify(EIP712types.newExtension(
                                        parseInt(res), "acceptBranch"))
                                ],
                            });

                            let rsv = EIP712types.getRSVfromSignature(signature);

                            let recordManagerContract = this.props.blockchainReducer.recordManagerContract;
                            recordManagerContract.methods.acceptBranch(this.props.rootRecord[5], storedJSON.path,
                                rsv.r, rsv.s, rsv.v)
                                .send({
                                    from: this.props.walletReducer.connectedAddress,
                                    gas: this.state.estimatedGasUse
                                })
                                .then((receipt) => {
                                    this.props.dispatchBlockchainLoadingStop();
                                    this.onSubmissionSuccessful(receipt, true);
                                })
                                .catch((err) => {
                                    DataReporter.trackMixpanel(this.props, "Success: Due branch merge", {
                                        category: 'Interaction'
                                    });

                                    this.props.dispatchBlockchainLoadingStop();

                                    this.setState({
                                        errorMessage: "Error on due accept branch (1). Please try again later."
                                    }, () => {
                                        DataReporter.trackSentry(err, {
                                            extra: {additionalData: "DueBranchMerger: Accept branch."}
                                        });
                                    });
                                })
                        } catch (err) {
                            this.props.dispatchBlockchainLoadingStop();

                            let msg = "Signing failed. Please check if your wallet is configured correctly.";
                            if (err.message.toLowerCase().includes("chainid")) {
                                msg = `Signing failed. Please check if your wallet is connected to the ${appConfig.currentConfig.blockchainName} network.`;
                            }

                            this.setState({
                                errorMessage: msg,
                                decidingOnBranch: false
                            }, () => {
                                DataReporter.trackSentry(err, {
                                    extra: {additionalData: "Signing accept branch."}
                                });
                            });
                        }


                    })
                    .catch((err) => {
                        this.setState({
                            errorMessage: "Error on due accept branch (1). Please try again later."
                        }, () => {
                            console.log(err.message);
                            this.props.dispatchBlockchainLoadingStop();
                        });
                    })
            });
        }
    }

    // Events
    onSubmissionSuccessful(receipt) {
        this.setState({
            submissionSuccessful: true,
            submittingBlockchain: false,
            trxReceipt: receipt,
            errorMessage: null
        })
    }

    onEstimatedGasUse(estimate) {
        this.setState({
            estimatedGasUse: estimate
        });
    }

    // Renderers
    renderErrorMessage() {
        if (this.state.errorMessage !== null) {
            return <FeedbackMessage
                success={false}
                message={this.state.errorMessage}
            />;
        }
    }

    renderTRXReceipt() {
        if (this.state.submissionSuccessful !== null) {
            let last = this.state.trxReceipt;
            return <TRXReceipt
                descr={"Accepted branch"}
                trxHash={last.transactionHash}
                trxGasUsed={last.gasUsed}
                estimatedGas={this.state.estimatedGasUse}
            />;
        }
    }

    render() {
        let mobileSuffix = this.props.forMobile ? "-mobile" : "";
        let content = null;

        if (!this.state.submissionSuccessful) {
            content = <div id={"due-branch-merge-submit-container"}>
                <VTRCalculator
                    id={"vtr-calc"}
                    posName={`Accept past due branch`}
                    rateName={"getRateOwnershipTransferNextOwner"}
                    onGetGasUseEstimate={(estimate) => this.onEstimatedGasUse(estimate)}
                />
                {this.renderErrorMessage()}
                <UserButton
                    forMobile={this.props.forMobile}
                    id={"submit-button"}
                    value={"Merge branch to original record"}
                    onClick={() => this.onAcceptBranch()}
                    disabled={this.state.submittingBlockchain}
                />
            </div>;
        } else {
            content =
                <div>
                    {this.renderTRXReceipt()}
                    <p id={"reload-info"}>Automatically reloading Record details...</p>
                </div>;

            setTimeout(() => {
                this.props.reloadCallback();
            }, 3000);
        }

        return (
            <div className={"due-branch-merger-container" + mobileSuffix}>
                <h3>Merge is past due</h3>
                <p id={"due-branch-descr"}>
                    The decision time of this branch has elapsed. The owner of the original record didn't
                    decide whether this branch should be dismissed or accepted (and thereby merged). <br/><br/>
                    Since Ventrace is a community driven platform, you now get the chance to accept this branch
                    and earn <span className={"vr-hl"}>VTR</span> for doing so.
                </p>
                {content}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        walletReducer: state.wallet,
        blockchainReducer: state.blockchain
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatchBlockchainLoading: () => {
            dispatch(requestSetBlockchainLoading())
        },
        dispatchBlockchainLoadingStop: () => {
            dispatch(requestSetBlockchainLoadingStop())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DueBranchMerger);