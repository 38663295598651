import * as types from "../actionTypes/wallet";

const initState = {
    loading: false,
    noWalletMode: true,
    connectedAddress: null,
    vtrBalance: null,
    lastVTRUpdate: Date.now()
}

export default function wallet (state=initState, payload) {
    switch (payload.type) {
        case types.DISCONNECT_WALLET:
            return {
                ...state,
                noWalletMode: true,
                connectedAddress: null,
                lastVTRUpdate: null
            }
        case types.WALLET_SET_ADDRESS:
            return {
                ...state,
                noWalletMode: false,
                connectedAddress: payload.data
            }
        case types.SET_NO_WALLET_MODE: {
            return {
                ...state,
                noWalletMode: true,
                connectedAddress: null
            }
        }
        case types.SET_VTR_BALANCE:
            return {
                ...state,
                vtrBalance: payload.data ? payload.data : 0,
                lastVTRUpdate: Date.now()
            }
        default:
            return state;
    }
}