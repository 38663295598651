import React from "react";
import { FaCircle } from "react-icons/fa";
import "../../styles/components/section_divider.scss";

class SectionDivider extends React.Component {
    render() {
        let mobileSuffix = this.props.forMobile ? "-mobile" : "";

        return(
          <div id={"section-divider" + mobileSuffix}>
              <div id={"content"}>
                  <div id={"elements"}>
                      <FaCircle id={"dot-left"} />
                      <div id={"line"} />
                      <FaCircle id={"dot-right"} />
                  </div>
              </div>
          </div>
        );
    }
}

export default SectionDivider;