import React from "react";
import env from "../../appConfig";
import {DateConverter} from "../../DateConverter";
import {AiOutlineBranches} from "react-icons/ai";
import {BsCalendarWeek} from "react-icons/bs";
import "../../styles/components/details_branchaccepted.scss";

class BranchDueAccepted extends React.Component {
    clickOpenAddressExplorer(val) {
        let baseUrl = env.explorerURLs.testnet.address;
        if (env.onDev) {

        }
        window.open(baseUrl + val, "_blank");
    }

    render() {
        if (this.props.data) {

            let changeDateLabel = "Accepted on";
            let originatorAddress = this.props.data.originator;

            if (this.props.forMobile) {
                changeDateLabel = "On";
                let sliceLen = 7;
                originatorAddress = originatorAddress.slice(0, sliceLen)
                    + "-" + originatorAddress.slice(-sliceLen);
            }

            return (
                <div className={"branch-accepted-container"}>
                    <div className={"record-content"}>
                        <div className={"l1 inline"}>
                            <p className={"record-type ext-type"}>
                                <AiOutlineBranches className={"type-icon"} />
                                Due branch accepted
                            </p>
                            <div className={"added-on-container"}>
                                <p className={"change-date"}>
                                    {changeDateLabel}&nbsp;
                                    <span className={"data"}>
                                        <BsCalendarWeek className={"cal-icon"} />
                                        {DateConverter.dateObjToString(parseInt(this.props.data.recordCreationDate))}
                                    </span>
                                </p>
                            </div>
                        </div>

                        <div className={"l2"}>
                            <div className={"originator"}>
                                <p className={"label"}>Creator</p>

                                <a className={"data"}
                                   onClick={() => this.clickOpenAddressExplorer(this.props.data.originator)}>
                                    {originatorAddress}
                                </a>
                            </div>
                        </div>

                        <div className={"l3"}>
                            <div className={"descr-container"}>
                                <p className={"descr-label"}>System message</p>
                                <p className={"descr-val"}>
                                    The decision time window to accept or dismiss the branch elapsed. <br/>
                                    The originator of this extension accepted the overdue branch.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default BranchDueAccepted;