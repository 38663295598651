export const BLOCKCHAIN_LOADING = "BLOCKCHAIN_LOADING";
export const BLOCKCHAIN_LOADING_STOP = "BLOCKCHAIN_LOADING_STOP";

export const SET_WEB3 = "BLOCKCHAIN_SET_WEB3";

export const SET_RECORD_MANAGER_CONTRACT = "BLOCKCHAIN_SET_RECORD_MANAGER_CONTRACT";
export const SET_RECORD_STORAGE_CONTRACT = "SET_RECORD_STORAGE_CONTRACT";
export const SET_VTR_TOKEN_CONTRACT = "SET_VTR_TOKEN_CONTRACT";
export const SET_VTR_BONUS_CONTRACT = "SET_VTR_BONUS_CONTRACT";
export const SET_VTR_MANAGER_CONTRACT = "SET_VTR_MANAGER_CONTRACT";
export const SET_DISPUTE_CONTRACT = "SET_DISPUTE_CONTRACT";
export const SET_VALUATOR_CONTRACT = "SET_VALUATOR_CONTRACT";
export const SET_VERIFIED_OWNERS_CONTRACT = "SET_VERIFIED_OWNERS_CONTRACT";


export const SET_CONTRACTS_READY = "SET_CONTRACTS_READY";
export const SET_WALLET_READY = "SET_WALLET_READY";

export const ADD_SESSION_TRX = "ADD_SESSION_TRX";