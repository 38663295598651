import React from "react";
import {connect} from "react-redux";
import Navigation from "../components/Navigation";
import appConfig from "../appConfig";
import Footer from "../components/Footer";
import {DataReporter} from "../DataReporter";
import "../styles/pages/imprint.scss";
import {requestSetCurrentPage} from "../store/actions/app";

class Imprint extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showEmail: false
        };
    }

    componentDidMount() {
        this.props.dispatchSetPage("imprint");
        DataReporter.trackMixpanel(this.props, "Page view: Imprint", {
            pageDisplayMode: this.props.appReducer.mobileMode ? "mobile" : "desktop"
        });
    }

    onShowEmail(){
        this.setState({
            showEmail: true
        });
    }

    renderEmail() {
        if (this.state.showEmail) {
            return (
                <p>{appConfig.supportEmail}</p>
            );
        } else {
            return (
                <p id={"email-hidden"} onClick={() => this.onShowEmail()}>
                    Click to reveal email address
                </p>
            )
        }
    }

    render() {
        let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";

        let phoneNr = this.props.appReducer.supportPhoneNrs.USA;
        if (this.props.appReducer.userRegionInEurope) {
            phoneNr = this.props.appReducer.supportPhoneNrs.Europe;
        }

        let addr = this.props.appReducer.officeAddress;

        return (
            <div>
                <Navigation />
                <div
                    className={"content-container" + mobileSuffix}
                    id={"imprint-container" + mobileSuffix}
                >
                    <h1>Imprint</h1>
                    <p id={"intro-descr"}>
                        The information found on this page is required by the EU authorities.
                    </p>

                    <h3>Legal form (business type)</h3>
                    <p className={"paragraph"}>
                        Sole Proprietorship
                    </p>

                    <h3>Legal entity (business representative)</h3>
                    <p className={"paragraph"}>
                        Onur Karaman
                    </p>

                    <h3>Tax ID</h3>
                    <p className={"paragraph"}>
                        DE367914081
                    </p>


                    <h3>Registered office address</h3>
                    <p className={"paragraph"}>
                        {addr.name}<br/>
                        {addr.street}<br/>
                        {addr.postcode} {addr.city}<br/>
                        {addr.country}
                    </p>

                    <h3>Email address</h3>
                    {this.renderEmail()}

                    <h3>Phone number</h3>
                    <p className={"paragraph"}>
                        {phoneNr}
                    </p>

                    <h3>Contractual Relationship</h3>
                    <p className={"paragraph"}>
                        The use of this website does not create a contractual relationship
                        between the user and Ventrace. As such, there are no contractual
                        or quasi-contractual claims against Ventrace.
                    </p>

                    <h3>Copyrights</h3>
                    <p className={"paragraph"}>
                        The content and works published on this website are protected by
                        copyright. The provider of this website strives to always respect the
                        copyrights of others or to use works created by himself that are free
                        of licenses. The content and works on these pages created by the website
                        operator are subject to German copyright law. Contributions by third
                        parties are identified as such. Reproduction, editing, distribution,
                        and any kind of use beyond the limits of copyright require the written
                        consent of Ventrace.
                    </p>

                    <h3>Online dispute resolution platform</h3>
                    <p className={"paragraph"}>
                        Platform of the EU Commission for online dispute resolution:
                        <a href={"https://www.ec.europa.eu/consumers/odr"} target={"_blank"}>
                            https://www.ec.europa.eu/consumers/odr
                        </a>. Ventrace would like to point out
                        that we are not obligated and generally not willing to participate in
                        such a conciliation procedure before a consumer arbitration board.
                    </p>

                </div>
                <Footer
                    forMobile={this.props.appReducer.mobileMode}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        appReducer: state.app
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatchSetPage: (pageName) => {
            dispatch(requestSetCurrentPage(pageName));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Imprint);