import React from "react";
import {connect} from "react-redux";
import {create} from "ipfs-http-client";
import appConfig from "../appConfig";
import moment from "moment";
import UserButton from "./inputs/UserButton";
import DropdownInput from "./inputs/DropdownInput";
import FeedbackMessage from "../components/FeedbackMessage";
import TextArea from "./inputs/TextArea";
import HintContainer from "../components/HintContainer";
import SidebarExplainer from "./SidebarExplainer";
import DatePicker from "react-datepicker";
import ImageUploadInput from "./inputs/ImageUploadInput";
import {AiOutlinePlus} from "react-icons/ai";
import {BsCalendarCheck} from "react-icons/bs";
import {requestSetBlockchainLoading, requestSetBlockchainLoadingStop} from "../store/actions/blockchain";
import {DateConverter} from "../DateConverter";
import axios from "axios";
import {requestSetClaimingExtension} from "../store/actions/app";
import {DataReporter} from "../DataReporter";
import "../styles/components/extensioncreator.scss";

class ExtensionCreator extends React.Component {
    constructor(props) {
        super(props);
        let appReducer = this.props.appReducer;

        let changeType = null;
        if (appReducer.claimingExtension && appReducer.claimingExtension.extData.capType === "Damage") {
            changeType = "Damage";
        }

        let changeDate = Date.now();
        if (appReducer.claimingExtension) {
            changeDate = new Date(appReducer.claimingExtension.extData.capDate);
        }

        this.state = {
            submitting: false,
            minInputLength: 5,
            changeType: changeType,
            changeTypeValid: true,
            changeDescr: appReducer.claimingExtension ? appReducer.claimingExtension.extData.captureDetails : null,
            changeDescrValid: true,
            changeDate: changeDate,
            changeDateValid: true,
            changeDateValidMsg: null,
            createImageFiles: null,
            imageUploadValidationErr: null,
            imageUploadValidationMsg: null,
            submissionSuccessful: null,
            trxReceipt: null,
            estimatedGasUse: null,
            errorMessage: null
        }
    }

    getExplainerText() {
        return [
            {
                "name": "Submitting your update",
                "descr": "Once you fill out the form and click the submit button, Ventrace will immediately add"
                    + " the update to your Ventrace Record and make it publicly visible. Keep in mind that Record updates"
                    + " can't be removed or altered after submission."
            }
        ];
    }

    getExtensionJSON(imageCIDs) {
        let appReducer = this.props.appReducer;

        return {
            "recordType": "generalExtension",
            "recordCreationDate": Date.now().toString(),
            "originator": this.props.userReducer.email,
            "changeType": this.state.changeType,
            "description": this.state.changeDescr,
            "imageCIDs": imageCIDs,
            "changeDate": this.state.changeDate,
            "providerID": appReducer.claimingExtension ? appReducer.claimingExtension.providerID : null
        }
    }

    async isValidToSave() {
        let canSave = true;
        this.setState({
            changeDescrValid: true,
            changeTypeValid: true,
            changeDateValid: true,
            changeDateValidMsg: null,
            imageUploadValidationErr: null,
        }, () => {
            DataReporter.trackMixpanel(this.props, "Validating extension", {
                category: 'Interaction'
            });
        });

        let enoughTimePassed = await this.enoughTimePassed();
        if (!enoughTimePassed) {
            this.setState({errorMessage: "Too many record creations. Please try again later."},
                () => {
                    DataReporter.trackMixpanel(this.props, "Validaton error: Too frequent", {
                        category: "Validation"
                    });
                });
            canSave = false;
        }

        if (this.state.changeType === null || this.state.changeType === "---") {
            canSave = false;
            this.setState({changeTypeValid: false}, () => {
                DataReporter.trackMixpanel(this.props, "Validation Error: No type selected", {
                    category: "Validation"
                });

            });
        }

        if (this.state.changeDescr === null || this.state.changeDescr.length < this.state.minInputLength) {
            this.setState({changeDescrValid: false}, () => {
                DataReporter.trackMixpanel(this.props, "Validation Error: Description not valid", {
                    category: "Validation"
                });

            });
            canSave = false;
        }

        if (this.state.changeDate === null) {
            this.setState({changeDateValid: false}, () => {
                DataReporter.trackMixpanel(this.props, "Validation Error: Date not valid", {
                    category: "Validation"
                });

            });
            canSave = false;
        }

        if (!this.props.appReducer.claimingExtension && moment(this.props.lastUpdateTimestamp).isAfter(this.state.changeDate)) {
            this.setState({
                changeDateValid: false,
                changeDateValidMsg: "A new extension cannot be dated before the latest extension on record."
            }, () => {
                DataReporter.trackMixpanel(this.props, "Validaton Error: Date previous to last extension", {
                    category: "Validation"
                });

            });
            canSave = false;
        }

        if (this.state.createImageFiles === null || this.state.createImageFiles.length < 1) {
            this.setState({
                imageUploadValidationErr: true,
                imageUploadValidationMsg: "You need to attach at least one image.",
            }, () => {
                DataReporter.trackMixpanel(this.props, "Validation Error: No image attached.", {
                    category: "Validation"
                });
            });
            canSave = false;
        }

        return canSave;
    }

    async enoughTimePassed() {
        // TODO: Work
        return true;

        let blockchainReducer = this.props.blockchainReducer;

        let recordManagerContract = blockchainReducer.recordManagerContract;

        const currentBlockNumber = await this.props.blockchainReducer.web3.eth.getBlockNumber();
        let fromBlock = (currentBlockNumber - 1000) > 0 ? (currentBlockNumber - 1000) : 0;

        let res = await recordManagerContract.getPastEvents("addRecordExtensionEvent",
            {
                fromBlock: fromBlock, toBlock: "latest", limit: 10
            });

        if (res.length > 0) {
            let extensionsInLastHour = 0;

            let mostRecentBlock = await blockchainReducer.web3.eth.getBlock(res[res.length - 1].blockNumber);
            let mostRecentTimeStamp = moment.unix(mostRecentBlock.timestamp).toDate();

            for (let i = 0; i < res.length; i += 1) {
                let b = await blockchainReducer.web3.eth.getBlock(res[i].blockNumber);
                let t = moment.unix(b.timestamp).toDate()

                let difference = moment().diff(t, 'hours');
                if (difference <= 1) extensionsInLastHour += 1;
            }

            if (extensionsInLastHour > 0) {
                if (extensionsInLastHour === 1) {
                    return moment().diff(mostRecentTimeStamp,
                        appConfig.AddExtFrequency.oneInlastHourUnit) >= appConfig.AddExtFrequency.oneInlastHourTime;
                }
                if (extensionsInLastHour >= 3) {
                    return moment().diff(mostRecentTimeStamp,
                        appConfig.AddExtFrequency.severalInLastHourUnit) >= appConfig.AddExtFrequency.severalInLastHourTime;
                }
            }
        }
        return true;

    }

    async storeUpdate() {
        if (!this.state.submitting) {
            this.setState({submitting: true},
                async () => {
                    this.isValidToSave().then(async (isValidToSave) => {
                        if (isValidToSave) {
                            // Upload data as JSON to IPFS
                            let auth = "Basic " + Buffer.from(appConfig.IPFS_ProjectID + ":" + appConfig.IPFS_Key).toString("base64");
                            let client = create({url: appConfig.IPFS_Endpoint, headers: {authorization: auth}});

                            let imageUploadPaths = [];

                            // Image upload
                            if (this.state.createImageFiles && this.state.createImageFiles.length > 0) {
                                imageUploadPaths = await Promise.all(
                                    this.state.createImageFiles.map(async file => {
                                        const result = await client.add(file);
                                        return result.path;
                                    })
                                );
                            }

                            try {
                                axios
                                    .post(appConfig.currentConfig.backendApp.url + "/recordExt/",
                                        {
                                            loginToken: this.props.userReducer.loginToken,
                                            rootRecord: this.props.completeRecordData[0],
                                            extData: this.getExtensionJSON(imageUploadPaths)
                                        },
                                        {
                                            headers: {
                                                Authorization: `Bearer ${appConfig.currentConfig.backendApp.tokens.standard}`,
                                                'Content-Type': 'application/json',
                                            },
                                        })
                                    .then(res => {
                                        if (res.data.success) {
                                            this.setState({
                                                submissionSuccessful: true,
                                                errorMessage: null,
                                                submitting: false
                                            }, () => {
                                                DataReporter.trackMixpanel(this.props,
                                                    "VR Update: Created");
                                            });
                                        } else {
                                            this.setState({
                                                errorMessage: "Creation failed. Please try again.",
                                                submitting: false
                                            }, () => {
                                                DataReporter.trackMixpanel(this.props,
                                                    "VR Update: Creation failed");
                                            });
                                        }
                                    })
                                    .catch(err => {
                                        DataReporter.trackSentry(err, {extra: {additionalData: "storeUpdate 1"}});
                                    })
                            } catch (err) {
                                DataReporter.trackSentry(err, {extra: {additionalData: "storeUpdate 2"}});
                            }


                        } else this.setState({submitting: false});
                    });

                });
        }
    }

    updatePendingExtension() {
        if (!this.state.submitting) {
            this.setState({submitting: true},
                async () => {
                    // Upload data as JSON to IPFS
                    let auth = "Basic " + Buffer.from(appConfig.IPFS_ProjectID + ":" + appConfig.IPFS_Key).toString("base64");
                    let client = create({url: appConfig.IPFS_Endpoint, headers: {authorization: auth}});

                    let imageUploadPaths = [];

                    // Image upload
                    if (this.state.createImageFiles && this.state.createImageFiles.length > 0) {
                        imageUploadPaths = await Promise.all(
                            this.state.createImageFiles.map(async file => {
                                const result = await client.add(file);
                                return result.path;
                            })
                        );
                    }

                    try {
                        axios
                            .put(appConfig.currentConfig.backendApp.url + "/recordExt",
                                {
                                    loginToken: this.props.userReducer.loginToken,
                                    extData: this.getExtensionJSON(imageUploadPaths),
                                    id: this.props.appReducer.claimingExtension.id,
                                    rootRecord: this.props.rootRecord,
                                    isClaimable: false,
                                },
                                {
                                    headers: {
                                        Authorization: `Bearer ${appConfig.currentConfig.backendApp.tokens.standard}`,
                                        'Content-Type': 'application/json',
                                    },
                                })
                            .then(res => {
                                if (res.data.success) {
                                    this.setState({
                                        submissionSuccessful: true,
                                        errorMessage: null,
                                        submitting: false
                                    }, () => {
                                        this.props.dispatchSetClaimingExtension(null);
                                        DataReporter.trackMixpanel(this.props,
                                            "VR Update: Created");
                                    });
                                } else {
                                    this.setState({
                                        errorMessage: "Creation failed. Please try again.",
                                        submitting: false
                                    }, () => {
                                        DataReporter.trackMixpanel(this.props,
                                            "VR Update: Creation failed");
                                    });
                                }
                            })
                            .catch(err => {
                                DataReporter.trackSentry(err, {
                                    extra: {additionalData: "updatePendingExtension"} });

                            })
                    } catch (err) {
                        DataReporter.trackSentry(err, {
                            extra: {additionalData: "updatePendingExtension"} });
                    }
            });
        }
    }

    // Events
    componentDidMount() {
        DataReporter.trackMixpanel(this.props, "Component view: Ext. Creator", {
            pageDisplayMode: this.props.appReducer.mobileMode ? "mobile" : "desktop"
        });
        window.scrollTo(0, 0);
    }

    typeOnChange(e) {
        this.setState({
            changeType: e
        }, () => {
            DataReporter.trackMixpanel(this.props, "Changed update type", {
                category: 'Interaction', value: e
            });
        });
    }

    descriptionOnChange(e) {
        if (e.inputValue.length >= this.state.minInputLength) {
            this.setState({changeDescr: e.inputValue});
        }
    }

    descriptionOnPaste(e) {
        this.descriptionOnChange({inputValue: e.clipboardData.getData("text")});
    }

    dateOnChange(date) {
        this.setState({
            changeDate: DateConverter.getUTCMilliseconds(date)
        }, () => {
            DataReporter.trackMixpanel(this.props, "Changed ext. date", { category: 'Interaction' });
        });
    }

    createImageFileOnChange(e) {
        let a = [];
        for (let i = 0; i < e.length; i += 1) {
            if (e[i] !== null) a.push(e[i]);
        }
        this.setState({createImageFiles: a},
            () => {
                DataReporter.trackMixpanel(this.props, "Changed record image", { category: 'Interaction' });
            });
    }

    resetImageFile(e) {
        this.setState({createImageFile: null});
    }

    onStoreExtensionClick() {
        if (this.props.appReducer.claimingExtension) {
            this.updatePendingExtension();
        } else {
            this.storeUpdate();
        }
    }

    // Renderers
    renderErrorMessage() {
        if (this.state.errorMessage !== null) {
            return <FeedbackMessage
                success={false}
                message={this.state.errorMessage}
            />;
        }
    }

    renderSubmitContainer() {
        if (this.state.submissionSuccessful === null) {
            let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";

            let disabled = this.state.changeType === null || !this.state.changeDescr || !this.state.changeDate
                || this.state.submitting;

            let spinner;
            if (this.state.submitting) {
                spinner = <div id={"spinner-container" + mobileSuffix}>
                    <img id="spinner" src={process.env.PUBLIC_URL + '/spinner.gif'} alt={"spinner"}/>
                </div>;
            }

            return (
                <div id={"submit-container" + mobileSuffix}>
                    {this.renderErrorMessage()}
                    <div className={"inline" + mobileSuffix}>
                        <UserButton
                            forMobile={this.props.appReducer.mobileMode}
                            id={"submit-button"}
                            value={"Create update"}
                            icon={<AiOutlinePlus className={"text-icon"}/>}
                            onClick={() => this.onStoreExtensionClick()}
                            disabled={disabled}
                        />
                        {spinner}
                    </div>
                </div>
            );
        } else if (this.state.submissionSuccessful) {
            return (
                <FeedbackMessage
                    success={true}
                    message={"Update successfully created."}
                />
            );
        }
    }

    renderDescrHintOfType() {
        if (this.state.changeType) {
            let c = this.state.changeType;

            if (c === "Internal") {
                return [
                    "Describe if there was a replacement of an internal part or some other kind of internal modification.",
                ];
            }

            if (c === "External") {
                return [
                    "Describe if there was a replacement of an external part or some other kind of external modification.",
                ];
            }

            if (c === "Damage") {
                return [
                    "How did the damage happen?",
                    "How did you notice the damage?",
                    "What's the current condition of the product?"
                ];
            }

            if (c === "Other") {
                return [
                    "Describe the extension as detailed as necessary."
                ];
            }
            return [];
        } else return [];
    }

    renderDateValidationMsg() {
        if (!this.state.changeDateValid) {
            return <div className={"input-error"}>
                <p className={"msg"}>{this.state.changeDateValidMsg}</p>
            </div>;
        }
    }

    renderForm() {
        let changeTypes = ["---", "Internal", "External", "Damage", "General"];
        let validationMsg = `The name has to be at least ${this.state.minInputLength} characters long.`;

        let minDate = new Date();
        minDate.setFullYear(minDate.getFullYear() - 30);

        return (
            <div>
                <h1>Update your Record</h1>
                <h2 id={"h2-update"}>Fill out the form below to add an update to your Ventrace Record</h2>
                <div id={"form"}>
                    <div id={"type-inputs-container"}>
                        <DropdownInput
                            id={"change-type-dropdown"}
                            label={"Update type *"}
                            options={changeTypes}
                            width={"486px"}
                            onChange={(e) => this.typeOnChange(e)}
                            validationError={!this.state.changeTypeValid}
                            validationMsg={"You need to pick an update type."}
                            hints={
                                <HintContainer
                                    forMobile={this.props.appReducer.mobileMode}
                                    id={"ext-type-hint-container"}
                                    hints={["What kind of change was done?"]}
                                />
                            }
                        />
                    </div>

                    <div id={"descr-inputs-container"}>
                        <TextArea
                            id={"change-descr-input"}
                            label={"Description *"}
                            value={this.state.changeDescr}
                            maxLen={300}
                            width={465}
                            rows={4}
                            placeholder={"Describe your update"}
                            onChange={(e) => this.descriptionOnChange(e)}
                            onPaste={(e) => this.descriptionOnPaste(e)}
                            validationError={!this.state.changeDescrValid}
                            validationMsg={validationMsg}
                            hints={
                                <HintContainer
                                    forMobile={this.props.appReducer.mobileMode}
                                    id={"change-descr-hints"}
                                    hints={this.renderDescrHintOfType()}
                                />
                            }
                            disabled={this.props.appReducer.claimingExtension}
                        />
                    </div>

                    <div id={"change-date-input-container"}>
                        <p className={"input-label"}>
                            <BsCalendarCheck className={"label-icon"}/>
                            Update date*
                        </p>
                        <DatePicker
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            yearDropdownItemNumber={20}
                            dropdownMode="select"
                            dateFormat="yyyy-MM-dd"
                            className={"purchase-date-input"}
                            selected={this.state.changeDate}
                            onChange={(date) => this.dateOnChange(date)}
                            minDate={minDate}
                            maxDate={new Date()}
                        />
                        {this.renderDateValidationMsg()}
                    </div>

                    <div id={"image-upload-container"}>
                        <p className={"input-label"}>Picture of what happened (at least one)</p>
                        <ImageUploadInput
                            forMobile={this.props.appReducer.mobileMode}
                            onChange={(e) => this.createImageFileOnChange(e)}
                            onReset={() => this.resetImageFile()}
                            validationError={this.state.imageUploadValidationErr}
                            validationMsg={this.state.imageUploadValidationMsg}

                            hints={
                                <HintContainer
                                    forMobile={this.props.appReducer.mobileMode}
                                    hints={[
                                        "Attach a picture of a receipt or of the certain spot of the product.",
                                    ]}
                                />
                            }
                        />
                    </div>
                </div>
            </div>
        );
    }

    renderSidebar() {
        return (
            <SidebarExplainer
                forMobile={this.props.appReducer.mobileMode}
                id={"sidebar-explainer" + this.props.appReducer.mobileMode}
                h1={"How updating your Record works"}
                steps={this.getExplainerText()}
            />
        );
    }

    render() {
        let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";

        return (
            <div className={"extension-create-container"}>
                <div id={"l" + mobileSuffix}>
                    {this.renderForm()}
                    {this.renderSubmitContainer()}
                </div>
                <div id={"r" + mobileSuffix}>
                    {this.renderSidebar()}
                </div>
                <div id={"clearer"}/>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        userReducer: state.user,
        walletReducer: state.wallet,
        blockchainReducer: state.blockchain,
        appReducer: state.app
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatchBlockchainLoading: () => {
            dispatch(requestSetBlockchainLoading())
        },
        dispatchBlockchainLoadingStop: () => {
            dispatch(requestSetBlockchainLoadingStop())
        },
        dispatchSetClaimingExtension: (claimingExt) => {
            dispatch(requestSetClaimingExtension(claimingExt));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExtensionCreator);