import React from "react";
import {connect} from "react-redux";
import Logger from "js-logger";
import appConfig from "../appConfig";
import InfoToolTip from "./InfoToolTip";
import { DataFormatter } from "../DataFormatter";
import {requestSetBlockchainLoading, requestSetBlockchainLoadingStop} from "../store/actions/blockchain";
import "../styles/components/vtr_calculator.scss";
import {DataReporter} from "../DataReporter";


class VTRCalculator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            gettingRate: false,
            gettingBonus: false,
            gettingEstimate: false,
            rate: null,
            // When this value changes, update rate
            refChangeUpdate: this.props.refChangeUpdate,
            // Bonus infos
            bonusRate: null
        }
    }

    async getGasUseEstimatesFromEvents() {
        if (!this.state.gettingEstimate) {
            let currentBlockNumber = await this.props.blockchainReducer.web3.eth.getBlockNumber();
            let fromBlock = (currentBlockNumber - 1000) > 0 ? (currentBlockNumber - 1000) : 0;

            this.setState({ gettingEstimate: true },
                async ()=> {
                    let recordManagerContract = this.props.blockchainReducer.recordManagerContract;
                    let disputeContract = this.props.blockchainReducer.disputeContract;

                    if (this.props.rateName === "getRateAddNewRecord") {
                        recordManagerContract.getPastEvents("addRootRecordEvent",
                            {
                                fromBlock: fromBlock, toBlock: "latest", limit: 50
                            })
                            .then((res) => {
                                this.onGetGasUseEstimates(res);
                            })
                            .catch((err) => {
                                DataReporter.trackSentry(err, {
                                    extra: {additionalData: "VTR Calc estimate: getRateAddNewRecord."}
                                });
                            })
                    }

                    if (this.props.rateName === "getRateAddExtension") {
                        recordManagerContract.getPastEvents("addRecordExtensionEvent",
                            {
                                fromBlock: fromBlock, toBlock: "latest", limit: 50
                            })
                            .then((res) => { this.onGetGasUseEstimates(res); })
                            .catch((err) => {
                                DataReporter.trackSentry(err, {
                                    extra: {additionalData: "VTR Calc estimate: getRateAddExtension."}
                                });
                            })
                    }

                    if (this.props.rateName === "getRateSetPrivateMode") {
                        recordManagerContract.getPastEvents("setRecordPrivateEvent",
                            {
                                fromBlock: fromBlock, toBlock: "latest", limit: 50
                            })
                            .then((res) => { this.onGetGasUseEstimates(res); })
                            .catch((err) => {
                                DataReporter.trackSentry(err, {
                                    extra: {additionalData: "VTR Calc estimate: getRateSetPrivateMode."}
                                });
                            })
                    }

                    if (this.props.rateName === "getRateSetPublicMode") {
                        recordManagerContract.getPastEvents("setRecordPublicEvent",
                            {
                                fromBlock: fromBlock, toBlock: "latest", limit: 50
                            })
                            .then((res) => { this.onGetGasUseEstimates(res); })
                            .catch((err) => {
                                DataReporter.trackSentry(err, {
                                    extra: {additionalData: "VTR Calc estimate: getRateSetPublicMode."}
                                });
                            })
                    }

                    if (this.props.rateName === "getRateSetPublicDue") {
                        recordManagerContract.getPastEvents("setRecordPublicDueEvent",
                            {
                                fromBlock: fromBlock, toBlock: "latest", limit: 50
                            })
                            .then((res) => { this.onGetGasUseEstimates(res); })
                            .catch((err) => {
                                DataReporter.trackSentry(err, {
                                    extra: {additionalData: "VTR Calc estimate: getRateSetPublicDue."}
                                });
                            })
                    }

                    if (this.props.rateName === "getRateOwnershipTransferOpening") {
                        recordManagerContract.getPastEvents("ownershipTransferOpeningEvent",
                            {
                                fromBlock: fromBlock, toBlock: "latest", limit: 50
                            })
                            .then((res) => { this.onGetGasUseEstimates(res); })
                            .catch((err) => {
                                DataReporter.trackSentry(err, {
                                    extra: {additionalData: "VTR Calc estimate: getRateOwnershipTransferOpening."}
                                });
                            })
                    }

                    if (this.props.rateName === "getRateOwnershipTransferNextOwner") {
                        recordManagerContract.getPastEvents("ownershipTransferConfirmedEvent",
                            {
                                fromBlock: fromBlock, toBlock: "latest", limit: 50
                            })
                            .then((res) => { this.onGetGasUseEstimates(res); })
                            .catch((err) => {
                                DataReporter.trackSentry(err, {
                                    extra: {additionalData: "VTR Calc estimate: getRateOwnershipTransferNextOwner."}
                                });
                            })
                    }

                    if (this.props.rateName === "getRateCreateBranch") {
                        recordManagerContract.getPastEvents("addRecordBranchEvent",
                            {
                                fromBlock: fromBlock, toBlock: "latest", limit: 50
                            })
                            .then((res) => { this.onGetGasUseEstimates(res); })
                            .catch((err) => {
                                DataReporter.trackSentry(err, {
                                    extra: {additionalData: "VTR Calc estimate: getRateCreateBranch."}
                                });
                            })
                    }

                    if (this.props.rateName === "getRateAcceptBranch") {
                        recordManagerContract.getPastEvents("branchAcceptedEvent",
                            {
                                fromBlock: fromBlock, toBlock: "latest", limit: 50
                            })
                            .then((res) => { this.onGetGasUseEstimates(res); })
                            .catch((err) => {
                                DataReporter.trackSentry(err, {
                                    extra: {additionalData: "VTR Calc estimate: getRateAcceptBranch."}
                                });
                            })
                    }

                    if (this.props.rateName === "getRateAddDisputeNote") {
                        recordManagerContract.getPastEvents("addExtensionDisputeEvent",
                            {
                                fromBlock: fromBlock, toBlock: "latest", limit: 50
                            })
                            .then((res) => { this.onGetGasUseEstimates(res); })
                            .catch((err) => {
                                DataReporter.trackSentry(err, {
                                    extra: {additionalData: "VTR Calc estimate: getRateAddDisputeNote."}
                                });
                            })
                    }

                    if (this.props.rateName === "getRateCreateDisputeReport") {
                        disputeContract.getPastEvents("addDisputeEvent",
                            {
                                fromBlock: fromBlock, toBlock: "latest", limit: 50
                            })
                            .then((res) => { this.onGetGasUseEstimates(res); })
                            .catch((err) => {
                                DataReporter.trackSentry(err, {
                                    extra: {additionalData: "VTR Calc estimate: getRateCreateDisputeReport."}
                                });
                            })
                    }
                });
        }
    }

    getRateAddNewRecord() {
        this.setState({gettingRate: true},
            async () => {
                this.props.dispatchBlockchainLoading();
                let vtrManagerContract = this.props.blockchainReducer.vtrManagerContract;

                vtrManagerContract.methods.getRateAddNewRecord()
                    .call({
                        from: this.props.walletReducer.connectedAddress,
                        gas: appConfig.currentConfig.blockchainGasLimit
                    })
                    .then((receipt) => { this.onGetRateSuccessful(receipt); })
                    .catch((err) => {
                        DataReporter.trackSentry(err, {
                            extra: {additionalData: "VTR Calc estimate: getRateAddNewRecord."}
                        });
                    })
            });
    }

    getRateAddExtension() {
        this.setState({gettingRate: true},
            async () => {
                this.props.dispatchBlockchainLoading();
                let vtrManagerContract = this.props.blockchainReducer.vtrManagerContract;

                vtrManagerContract.methods.getRateAddExtension()
                    .call({
                        from: this.props.walletReducer.connectedAddress,
                        gas: appConfig.currentConfig.blockchainGasLimit
                    })
                    .then((receipt) => { this.onGetRateSuccessful(receipt); })
                    .catch((err) => {
                        DataReporter.trackSentry(err, {
                            extra: {additionalData: "VTR Calc estimate: getRateAddExtension."}
                        });
                    })
            });
    }

    getRateSetPrivateMode() {
        this.setState({gettingRate: true},
            async () => {
                this.props.dispatchBlockchainLoading();
                let vtrManagerContract = this.props.blockchainReducer.vtrManagerContract;

                vtrManagerContract.methods.getRateSetPrivateMode(this.props.months, false)
                    .call({
                        from: this.props.walletReducer.connectedAddress,
                        gas: appConfig.currentConfig.blockchainGasLimit
                    })
                    .then((receipt) => { this.onGetRateSuccessful(receipt); })
                    .catch((err) => {
                        DataReporter.trackSentry(err, {
                            extra: {additionalData: "VTR Calc estimate: getRateSetPrivateMode."}
                        });
                    })
            });
    }

    getRateSetPublicMode() {
        this.setState({gettingRate: true},
            async () => {
                this.props.dispatchBlockchainLoading();
                let vtrManagerContract = this.props.blockchainReducer.vtrManagerContract;

                vtrManagerContract.methods.getRateSetPublicMode(this.props.remainingMonths, false)
                    .call({
                        from: this.props.walletReducer.connectedAddress,
                        gas: appConfig.currentConfig.blockchainGasLimit
                    })
                    .then((receipt) => { this.onGetRateSuccessful(receipt); })
                    .catch((err) => {
                        DataReporter.trackSentry(err, {
                            extra: {additionalData: "VTR Calc estimate: getRateSetPublicMode."}
                        });
                    })
            });
    }

    getRateOwnershipTransferNextOwner() {
        this.setState({gettingRate: true},
            async () => {
                this.props.dispatchBlockchainLoading();
                let vtrManagerContract = this.props.blockchainReducer.vtrManagerContract;

                vtrManagerContract.methods.getRateOwnershipTransferNextOwner()
                    .call({
                        from: this.props.walletReducer.connectedAddress,
                        gas: appConfig.currentConfig.blockchainGasLimit
                    })
                    .then((receipt) => { this.onGetRateSuccessful(receipt); })
                    .catch((err) => {
                        DataReporter.trackSentry(err, {
                            extra: {additionalData: "VTR Calc estimate: getRateOwnershipTransferNextOwner."}
                        });
                    })
            });
    }

    getRateAddDisputeNote() {
        this.setState({gettingRate: true},
            async () => {
                this.props.dispatchBlockchainLoading();
                let vtrManagerContract = this.props.blockchainReducer.vtrManagerContract;

                vtrManagerContract.methods.getRateAddDisputeNote(false)
                    .call({
                        from: this.props.walletReducer.connectedAddress,
                        gas: appConfig.currentConfig.blockchainGasLimit
                    })
                    .then((receipt) => { this.onGetRateSuccessful(receipt); })
                    .catch((err) => {
                        DataReporter.trackSentry(err, {
                            extra: {additionalData: "VTR Calc estimate: getRateAddDisputeNote."}
                        });
                    })
            });
    }

    getRateOwnershipTransferOpening() {
        this.setState({gettingRate: true},
            async () => {
                this.props.dispatchBlockchainLoading();
                let vtrManagerContract = this.props.blockchainReducer.vtrManagerContract;

                vtrManagerContract.methods.getRateOwnershipTransferOpening()
                    .call({
                        from: this.props.walletReducer.connectedAddress,
                        gas: appConfig.currentConfig.blockchainGasLimit
                    })
                    .then((receipt) => { this.onGetRateSuccessful(receipt); })
                    .catch((err) => {
                        DataReporter.trackSentry(err, {
                            extra: {additionalData: "VTR Calc estimate: getRateOwnershipTransferOpening."}
                        });
                    })
            });
    }

    getRateSetPublicDue() {
        this.setState({gettingRate: true},
            async () => {
                this.props.dispatchBlockchainLoading();
                let vtrManagerContract = this.props.blockchainReducer.vtrManagerContract;

                vtrManagerContract.methods.getRateSetPublicDue()
                    .call({
                        from: this.props.walletReducer.connectedAddress,
                        gas: appConfig.currentConfig.blockchainGasLimit
                    })
                    .then((receipt) => { this.onGetRateSuccessful(receipt); })
                    .catch((err) => {
                        DataReporter.trackSentry(err, {
                            extra: {additionalData: "VTR Calc estimate: getRateSetPublicDue."}
                        });
                    })
            });
    }

    getRateCreateBranch() {
        this.setState({gettingRate: true},
            async () => {
                this.props.dispatchBlockchainLoading();
                let vtrManagerContract = this.props.blockchainReducer.vtrManagerContract;

                vtrManagerContract.methods.getRateCreateBranch(false)
                    .call({
                        from: this.props.walletReducer.connectedAddress,
                        gas: appConfig.currentConfig.blockchainGasLimit
                    })
                    .then((receipt) => { this.onGetRateSuccessful(receipt); })
                    .catch((err) => {
                        DataReporter.trackSentry(err, {
                            extra: {additionalData: "VTR Calc estimate: getRateCreateBranch."}
                        });
                    })
            });
    }

    getRateCreateDisputeReport() {
        this.setState({gettingRate: true},
            async () => {
                this.props.dispatchBlockchainLoading();
                let vtrManagerContract = this.props.blockchainReducer.vtrManagerContract;

                vtrManagerContract.methods.getRateCreateDisputeReport(false)
                    .call({
                        from: this.props.walletReducer.connectedAddress,
                        gas: appConfig.currentConfig.blockchainGasLimit
                    })
                    .then((receipt) => { this.onGetRateSuccessful(receipt); })
                    .catch((err) => {
                        DataReporter.trackSentry(err, {
                            extra: {additionalData: "VTR Calc estimate: getRateCreateDisputeReport."}
                        });
                    })
            });
    }

    // Bonuses
    getBonusAddNewRecord() {
        this.setState({gettingBonus: true},
            async () => {
                this.props.dispatchBlockchainLoading();
                let vtrBonusContract = this.props.blockchainReducer.vtrBonusContract;

                vtrBonusContract.methods.getAddNewRecordBonus()
                    .call({
                        from: this.props.walletReducer.connectedAddress,
                        gas: appConfig.currentConfig.blockchainGasLimit
                    })
                    .then((receipt) => { this.onGetBonusSuccessful(receipt); })
                    .catch((err) => {
                        DataReporter.trackSentry(err, {
                            extra: {additionalData: "VTR Calc: getBonusAddNewRecord."}
                        });
                    })
            });
    }

    getBonusAddExtension() {
        this.setState({gettingBonus: true},
            async () => {
                this.props.dispatchBlockchainLoading();
                let vtrBonusContract = this.props.blockchainReducer.vtrBonusContract;

                vtrBonusContract.methods.getAddExtensionBonus()
                    .call({
                        from: this.props.walletReducer.connectedAddress,
                        gas: appConfig.currentConfig.blockchainGasLimit
                    })
                    .then((receipt) => { this.onGetBonusSuccessful(receipt); })
                    .catch((err) => {
                        DataReporter.trackSentry(err, {
                            extra: {additionalData: "VTR Calc: getBonusAddExtension."}
                        });
                    })
            });
    }

    getRateDecider() {
        if (!this.state.gettingRate && this.props.rateName) {
            if (this.props.rateName === "getRateAddNewRecord") {
                this.getRateAddNewRecord();
                this.getBonusAddNewRecord();
            }
            if (this.props.rateName === "getRateAddExtension") {
                this.getRateAddExtension();
                this.getBonusAddExtension();
            }
            if (this.props.rateName === "getRateSetPrivateMode") this.getRateSetPrivateMode();
            if (this.props.rateName === "getRateSetPublicMode") this.getRateSetPublicMode();
            if (this.props.rateName === "getRateOwnershipTransferNextOwner") this.getRateOwnershipTransferNextOwner();
            if (this.props.rateName === "getRateAddDisputeNote") this.getRateAddDisputeNote();
            if (this.props.rateName === "getRateOwnershipTransferOpening") this.getRateOwnershipTransferOpening();
            if (this.props.rateName === "getRateSetPublicDue") this.getRateSetPublicDue();
            if (this.props.rateName === "getRateCreateBranch") this.getRateCreateBranch();
            if (this.props.rateName === "getRateCreateDisputeReport") this.getRateCreateDisputeReport();
        }
    }


    // Events
    onGetGasUseEstimates(events) {
        let estimate = 0;
        for (let i=0; i < events.length; i+=1) estimate += parseInt(events[i].returnValues.gasUsed);

        if (events.length > 0 ) {
            estimate /= events.length;
            estimate += (estimate*0.75);
        }
        else estimate = appConfig.currentConfig.blockchainGasLimit;

        this.setState({
            gasUseEstimate: parseInt(estimate)
        }, () => {
            if (this.props.onGetGasUseEstimate)
                this.props.onGetGasUseEstimate(this.state.gasUseEstimate);
        });
    }

    onGetRateSuccessful(receipt) {
        this.props.dispatchBlockchainLoadingStop();

        this.setState({
            gettingRate: false,
            rate: parseInt(receipt),
            refChangeUpdate: this.props.refChangeUpdate
        }, () => {
            Logger.info("VTR Calc: Updated rate to: ", this.state.rate);

            this.getGasUseEstimatesFromEvents();
            if (this.props.onGetRate) {
                this.props.onGetRate(this.state.rate);
            }
        });
    }

    onGetBonusSuccessful(receipt) {
        try {
            if (receipt && parseInt(receipt) > 0) {
                this.props.dispatchBlockchainLoadingStop();

                this.setState({
                    gettingBonus: false,
                    bonusRate: parseInt(receipt),
                });
            }
        }
        catch (err) {
            Logger.info("Error on onGetBonusSuccessful()");
            Logger.info(err);
        }
    }

    // Renderers
    renderPositions() {
        let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";

        let pos = [];
        let balance = parseInt(this.props.walletReducer.vtrBalance ? this.props.walletReducer.vtrBalance : 0);

        pos.push(
            <div className={"row" + mobileSuffix} key={pos.length}>
                <p className={"l" + mobileSuffix}>{this.props.posName}</p>
                <p className={`r${mobileSuffix} ${(this.state.rate > 0 || this.state.rate === 0) ? "reward" : "cost"}`}>
                    {this.state.rate > 0 ? "+" : ""}{this.state.rate} <span className={"vtr-hl"}>VTR</span></p>
            </div>
        );

        if (this.state.bonusRate !== null) {
            pos.push(
                <div className={"row" + mobileSuffix} key={pos.length}>
                    <p className={"l" + mobileSuffix}>Current bonus</p>
                    <p className={`r${mobileSuffix} reward`}>+{this.state.bonusRate} <span className={"vtr-hl"}>VTR</span></p>
                </div>
            );
            balance += this.state.bonusRate;
        }

        balance += this.state.rate;

        pos.push(
            <div className={"row after" + mobileSuffix} key={pos.length}>
                <p className={"l" + mobileSuffix}>Your balance after transaction</p>
                <p className={"r" + mobileSuffix}>{balance} <span className={"vtr-hl"}>VTR</span></p>
            </div>
        );

        return pos;
    }

    renderGasEstimate() {
        if (this.state.rate !== null) {
            let text = null;
            if (this.state.gasUseEstimate) {
                text = <p id={"gas-estimate"}>
                    Estimated gas use: {DataFormatter.numberToDecimals(this.state.gasUseEstimate)} Gwei
                </p>
            } else text = <p id={"gas-estimate"}>Estimating gas use ...</p>

            return <div id={"gas-estimate-container"}>
                {text}
                <InfoToolTip
                    text={"This value is based on recent transactions on Ventrace."}
                />
            </div>;
        }
    }

    renderEnoughBalance() {
        let balance = this.props.walletReducer.vtrBalance ? this.props.walletReducer.vtrBalance : 0;
        balance = parseInt(balance);
        if (balance + this.state.rate < 0) {
            return (
                <div id={"enough-balance"}>
                    <p>You don't have enough balance to perform this transaction.</p>
                </div>
            )
        }
    }

    render() {
        let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";

        let balance = this.props.walletReducer.vtrBalance ? this.props.walletReducer.vtrBalance : "0";
        let content = <div id={"spinner-container"}>
            <img id="spinner" src={process.env.PUBLIC_URL + '/spinner.gif'} alt={"spinner"} />
        </div>;

        if (this.state.rate !== null) {
            content = <div>
                <div className={"row" + mobileSuffix}>
                    <p className={"l" + mobileSuffix}>Your current <span className={"vtr-hl"}>VTR</span> balance</p>
                    <p className={"r" + mobileSuffix}>{balance} <span className={"vtr-hl"}>VTR</span></p>
                </div>
                {this.renderPositions()}
            </div>;
        } else this.getRateDecider();

        if (this.props.refChangeUpdate && (this.props.refChangeUpdate !== this.state.refChangeUpdate)) {
            this.getRateDecider();
        }

        return (
            <div className={"vtr-calc-container" + mobileSuffix}>
                <h1 className={"h1-mobile"}>Transaction calculator</h1>
                <p className={"descr-calc" + mobileSuffix}>
                    The following crypto calculation applies in case you save your changes.
                </p>
                {content}
                {this.renderEnoughBalance()}
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        walletReducer: state.wallet,
        blockchainReducer: state.blockchain,
        appReducer: state.app
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatchBlockchainLoading: () => {
            dispatch(requestSetBlockchainLoading())
        },
        dispatchBlockchainLoadingStop: () => {
            dispatch(requestSetBlockchainLoadingStop())
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(VTRCalculator);