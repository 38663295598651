import {connect} from "react-redux";
import {BrowserRouter, Route} from "react-router-dom";
import {WagmiConfig, createConfig, configureChains} from 'wagmi'
import {createWeb3Modal} from '@web3modal/wagmi/react'
import {infuraProvider} from 'wagmi/providers/infura';
import {w3mConnectors} from "@web3modal/ethereum";
import {mainnet, polygon} from '@wagmi/core/chains';
import * as Sentry from "@sentry/react";
import Mixpanel from "mixpanel-browser";
import Logger from "js-logger";
import appConfig from "./appConfig";
import Landing from "./pages/Landing";
import Search from "./pages/Search";
import OrderRecord from "./pages/OrderRecord";
import MyRecords from "./pages/MyRecords";
import RecordDetails from "./pages/RecordDetails";
import Support from "./pages/Support";
import About from "./pages/About";
import GettingStarted from "./pages/GettingStarted";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Imprint from "./pages/Imprint";
import Login from "./pages/Login";
import MyAccount from "./pages/MyAccount";
import Providers from "./pages/Providers";
import "./styles/base.scss";

Sentry.init({
    dsn: appConfig.SentryDSN,
    integrations: [
        new Sentry.BrowserTracing({
            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [
                "localhost",
                "192.168.0.114",
                /^https?:\/\/(www\.)?ventrace\.com$/
            ],
        }),
        new Sentry.Replay(),
    ],
    tracesSampleRate: 0.5,
    replaysSessionSampleRate: 0.2,
    replaysOnErrorSampleRate: 0.2,
});

// Wagmi client
const {chains, publicClient} = configureChains(
    [polygon],
    [
        infuraProvider({apiKey: appConfig.Infura_APIKey}),
    ]
);

const metadata = {
    name: "Ventrace web3 connector",
    description: "Connect your wallet to Ventrace",
    url: 'https://www.ventrace.com'
}

const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({
        projectId: appConfig.WalletConnect_ProjectID,
        chains,
    }),
    publicClient
})

// Web3Modal Ethereum Client
createWeb3Modal(
    {
        wagmiConfig: wagmiConfig,
        projectId: appConfig.WalletConnect_ProjectID,
        chains: chains
    });

function App(props) {
    // Setup logger
    Logger.useDefaults();
    Logger.setLevel(Logger.DEBUG);

    const {cookiesAccepted} = props.appReducer;

    // Google Tag Manager
    if (cookiesAccepted) {
        Logger.info("Initializing tracking services");

        //TagManager.initialize({gtmId: appConfig.GTMid});
        Mixpanel.init(appConfig.mixpanelToken);
        Mixpanel.set_config({'persistence': 'localStorage'})
    } else {
        Logger.info("Cookies rejected.");
    }

    return (
        <>
            <WagmiConfig config={wagmiConfig}>
                <BrowserRouter>
                    <Route exact path={"/"} component={Landing}/>
                    <Route exact path={"/search"} component={Search}/>
                    <Route exact path={"/order"} component={OrderRecord}/>
                    <Route exact path={"/myrecords"} component={MyRecords}/>
                    <Route exact path={"/record"} component={RecordDetails}/>
                    <Route exact path={"/support"} component={Support}/>
                    <Route exact path={"/about"} component={About}/>
                    <Route exact path={"/gettingstarted"} component={GettingStarted}/>
                    <Route exact path={"/terms"} component={TermsAndConditions}/>
                    <Route exact path={"/privacypolicy"} component={PrivacyPolicy} />
                    <Route exact path={"/imprint"} component={Imprint} />
                    <Route exact path={"/login"} component={Login} />
                    <Route exact path={"/myaccount"} component={MyAccount} />
                    <Route exact path={"/providers"} component={Providers} />
                </BrowserRouter>
            </WagmiConfig>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        appReducer: state.app
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(App);