import * as types from "../actionTypes/app";
import * as actionResponse from "./actionResponse";

export function requestSetCurrentPage(pageName) {
    return (dispatch) => {
        dispatch(actionResponse.gen(types.SET_CURRENT_PAGE, pageName));
    }
}

export function requestSetCookies(accepted) {
    return (dispatch) => {
        dispatch(actionResponse.gen(accepted ? types.ACCEPT_COOKIES : types.REJECT_COOKIES, null));
    }
}

export function requestSetMobileMode(mobileModeOn) {
    return (dispatch) => {
        dispatch(actionResponse.gen(types.SET_MOBILE_MODE, mobileModeOn));
    }
}

export function requestSetOnRightNetwork(b) {
    return (dispatch) => {
        dispatch(actionResponse.gen(types.SET_ON_RIGHT_NETWORK, b));
    }
}

export function requestSetAutoConnect(b) {
    return (dispatch) => {
        dispatch(actionResponse.gen(types.SET_AUTO_CONNECT, b));
    }
}

export function requestSetPersona(personaName) {
    return (dispatch) => {
        dispatch(actionResponse.gen(types.SET_PERSONA, personaName));
    }
}

export function requestSetClaimingRecord(claimingRecord) {
    return (dispatch) => {
        dispatch(actionResponse.gen(types.SET_CLAIMING_RECORD, claimingRecord));
    }
}

export function requestSetClaimingExtension(claimingExtension) {
    return (dispatch) => {
        dispatch(actionResponse.gen(types.SET_CLAIMING_EXTENSION, claimingExtension));
    }
}

export function requestSetOrderData(orderData) {
    return (dispatch) => {
        dispatch(actionResponse.gen(types.SET_ORDER_DATA, orderData));
    }
}

export function requestSetVRPrices(VRprices) {
    return (dispatch) => {
        dispatch(actionResponse.gen(types.SET_VR_PRICES, VRprices));
    }
}

export function requestSetUserRegion(region) {
    return (dispatch) => {
        dispatch(actionResponse.gen(types.SET_USER_REGION, region));
    }
}

export function requestSetSupportPhoneNrs(phoneNrs) {
    return (dispatch) => {
        dispatch(actionResponse.gen(types.SET_SUPPORT_PHONE_NRS, phoneNrs));
    }
}

