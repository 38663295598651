import React from "react";
import {connect} from "react-redux";
import {DateConverter} from "../DateConverter";
import {AiOutlineNotification, AiOutlineStop} from "react-icons/ai";
import appConfig from "../appConfig";
import Mixpanel from "mixpanel-browser";
import "../styles/components/disputedescision.scss";
import {DataReporter} from "../DataReporter";

class DisputeDecision extends React.Component {
    // Renderers
    renderComment(commentData) {
        return (
            <div className={"decision-comment"}>
                <div className={"inline"}>
                    <AiOutlineNotification className={"icon"}/>
                    <div className={"content"}>
                        <p className={"date"}>
                            Ventrace dispute moderation on {DateConverter.dateObjToString(parseInt(commentData.creationDate))}
                        </p>
                        <p className={"comment"}>
                            <i>{commentData.decisionComment}</i>
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    renderInvalidate(data) {
        return (
            <div className={"decision-invalidate-ext"}>
                <div className={"inline"}>
                    <AiOutlineStop className={"icon"}/>
                    <div className={"content"}>
                        <p className={"date"}>
                            Ventrace dispute moderation on {DateConverter.dateObjToString(parseInt(data.creationDate))}
                        </p>
                        <p className={"comment"}>
                            Record invalidated. <i>{data.decisionComment}</i>
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";
        let ret = [];

        for (let i = 0; i < this.props.decisions.length; i += 1) {
            let curr = this.props.decisions[i];

            if (this.props.itemType && curr.data.selectedRecordItem) {
                if (this.props.itemType === "RR") {
                    if (curr.data.selectedRecordItem.type.includes("RR")
                        && parseInt(curr.data.selectedRecordItem.id) === parseInt(this.props.itemID)) {
                        if (curr.data.decision === "commentRR") {
                            ret.push(this.renderComment(curr.data));
                        }
                    }

                } else if (this.props.itemType === "Ext") {
                    if (curr.data.selectedRecordItem.type.includes("Ext")
                        && parseInt(curr.data.selectedRecordItem.id) === parseInt(this.props.itemID)) {

                        if (curr.data.decision === "commentExt") {
                            ret.push(this.renderComment(curr.data));
                        }
                        if (curr.data.decision === "invalidateExt") {
                            ret.push(this.renderInvalidate(curr.data));
                            if (this.props.onInvalidate) this.props.onInvalidate();
                        }
                    }
                }
            }
        }

        if (ret.length > 0) {
            DataReporter.trackMixpanel(this.props, "Dispute decision: Ext. contains mod info.");
        }

        return (
            <div className={"dispute-decisions-container" + mobileSuffix}>
                {ret}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        appReducer: state.app
    }
}

export default connect(mapStateToProps, null)(DisputeDecision);