import React from 'react';
import ReactDOM from "react-dom/client";
import {createStore, applyMiddleware} from 'redux';
import {persistStore, persistReducer} from 'redux-persist';
import {PersistGate} from 'redux-persist/integration/react';
import localForage from "localforage";
import {Provider} from 'react-redux';
import thunk from 'redux-thunk';
import rootReducer from "./store/reducers/root";
import App from './App';

const root = ReactDOM.createRoot(document.getElementById("root"));

const db = localForage.createInstance({name: "ventrace"});
let storage = {
    db,
    getItem: db.getItem,
    setItem: db.setItem,
    removeItem: db.removeItem
};

const persistConfig = {
    key: 'root',
    storage: storage,
    blacklist: ["blockchain"]
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = createStore(persistedReducer, applyMiddleware(thunk));
const persistor = persistStore(store);

root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <App/>
        </PersistGate>
    </Provider>
);
