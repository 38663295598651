import React from "react";
import appConfig from "../../appConfig";
import Mixpanel from "mixpanel-browser";
import {DateConverter} from "../../DateConverter";
import {AiOutlineBranches} from "react-icons/ai";
import {BsCalendarWeek} from "react-icons/bs";
import "../../styles/components/details_branchaccepted.scss";
import {DataReporter} from "../../DataReporter";

class BranchAccepted extends React.Component {
    clickOpenAddressExplorer(val) {
        let baseUrl = appConfig.explorerURLs.testnet.address;

        DataReporter.trackMixpanel(this.props, "Clicking on branch accepted address",
            { category: "Display", }
        );

        window.open(baseUrl + val, "_blank");
    }

    render() {
        if (this.props.data) {
            let mobileSuffix = this.props.forMobile ? "-mobile" : "";

            let originatorAddress = this.props.data.originator;
            originatorAddress = originatorAddress.slice(0, appConfig.address_SliceLen)
                + "-" + originatorAddress.slice(-appConfig.address_SliceLen);

            let changeDateLabel = "On";
            if (this.props.forMobile) {
                changeDateLabel = "On";
            }

            return (
                <div className={"branch-accepted-container" + mobileSuffix}>
                    <div className={"record-content"}>
                        <div className={"l1 inline"}>
                            <p className={"record-type"}>
                                <AiOutlineBranches className={"type-icon"} />
                                Branch accepted
                            </p>
                            <div className={"added-on-container"}>
                                <p className={"change-date"}>
                                    {changeDateLabel}&nbsp;
                                    <p className={"data"}>
                                        <BsCalendarWeek className={"cal-icon"} />
                                        {DateConverter.dateObjToString(parseInt(this.props.data.recordCreationDate))}
                                    </p>
                                </p>
                            </div>
                        </div>

                        <div className={"l2"}>
                            <div className={"originator"}>
                                <p className={"label"}>Creator</p>
                                <a className={"data"}
                                   onClick={() => this.clickOpenAddressExplorer(this.props.data.originator)}>
                                    {originatorAddress}
                                </a>
                            </div>
                        </div>

                        <div className={"l3"}>
                            <div className={"descr-container"}>
                                <p className={"descr-label"}>System message</p>
                                <p className={"descr-val"}>
                                    The owner of this Record has accepted the branch.<br/>
                                    All extensions of the branched Record have been added to this record.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            );
        }
    }
}

export default BranchAccepted;