import React from "react";
import {connect} from "react-redux";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import {DataReporter} from "../DataReporter";
import "../styles/pages/termsandconditions.scss";
import {requestSetCurrentPage} from "../store/actions/app";

class TermsAndConditions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.dispatchSetPage("terms");
        DataReporter.trackMixpanel(this.props, "Page view: Terms", {
            pageDisplayMode: this.props.appReducer.mobileMode ? "mobile" : "desktop"
        });
    }

    render() {
        let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";
        return (
            <div>
                <Navigation />
                <div
                    className={"content-container" + mobileSuffix}
                    id={"toc-container" + mobileSuffix}
                >
                    <h1>Terms and Conditions</h1>
                    <p id={"intro-descr"}>
                        These Terms of Service ("Terms") govern your use of the Ventrace
                        and services offered by Ventrace, also called the “Ventrace platform”
                        ("we," "us," or "our"). By accessing or using Ventrace, you agree to be bound
                        by these Terms.
                    </p>

                    <h2>Ventrace Records</h2>
                    <h3>1.1 Definition</h3>
                    <p className={"paragraph"}>
                        A Ventrace Record ("VR") is a digital object created on the Ventrace platform.
                        It is owned by the customer who originally ordered it or by the person who received
                        the ownership through a consented ownership transfer. A Record digitally represents
                        a produced good of any kind and contains historical information regarding a product’s
                        origin, quality and condition.
                    </p>

                    <h3>1.2 Record Updates</h3>
                    <p className={"paragraph"}>
                        Owners of VRs can add "Record updates" or "Record extensions" containing information
                        about the product's history. These may be:
                        <ul>
                            <li>The name of the producer and when the product was manufactured.</li>
                            <li>The date and contextual description of</li>
                            <li>the first purchase of the product.</li>
                            <li>any kind of internal or external change of the product</li>
                            <li>any kind of known damage to the product</li>
                            <li>a change of the legal ownership of the product, which is represented by the VR.</li>
                        </ul>

                        Ventrace does not guarantee absolute correctness of the information contained in a VR.
                    </p>

                    <h3>1.3 Data Ownership</h3>
                    <p className={"paragraph"}>
                        Ventrace Records are owned through the address of the owner’s crypto-currency wallet,
                        which was originally used when the VR was ordered on Ventrace. Ventrace is storing all
                        Ventrace Records on a public, decentralized blockchain.<br/><br/>

                        Making changes to a VR is only possible when
                        <ul>
                            <li>
                                the owner of the VR has connected the same cryptocurrency wallet to Ventrace,
                                which was also used when the VR was ordered.
                            </li>
                            <li>
                                the owner is initialitzing, approving and sending the intended changes of the VR
                                in form of a blockchain transaction.
                            </li>
                        </ul>

                        For further explanations and tips, please see
                        <a href={"https://www.ventrace.com/support"} target={"_blank"}>https://www.ventrace.com/support</a>.
                    </p>

                    <h3>1.4 Responsibility</h3>
                    <p className={"paragraph"}>
                        Since the updates of a Ventrace Record are exclusively added and managed by its respective
                        owner, Ventrace is not guaranteeing absolute correctness of what is being contained and
                        represented by a Record. The condition of the physical or digital product, represented by a
                        VR, may therefore be different from what is known on Ventrace.
                        <br/><br/>
                        Ventrace is not taking responsibility for the information being added, contained and
                        represented on a VR. However, Ventrace is taking strong measurements to achieve a high grade
                        of data correctness.
                    </p>


                    <h2>2. User Conduct</h2>
                    <h3>2.1 Eligibility</h3>
                    <p className={"paragraph"}>
                        Users of Ventrace who intend to become an owner of a Ventrace Record must legally own the
                        product/good, which the Ventrace Record will digitally represent. Furthermore, the user of
                        Ventrace must be the legal owner of the crypto-currency wallet which is eventually connected
                        to Ventrace itself.<br/>
                        Ventrace reserves the right to refuse service to anyone for any
                        reason at any time.
                    </p>

                    <h3>2.2 Platform Misuse</h3>
                    <p className={"paragraph"}>
                        Any attempt to maliciously hack or misuse the Ventrace platform is forbidden.
                        Ventrace will contact appropriate authorities to address such attempts.
                    </p>

                    <h2>3. Ordering Process</h2>
                    <h3>3.1 Order Details</h3>
                    <p className={"paragraph"}>
                        Customers must provide accurate and truthful product information during the ordering process,
                        including images showing the actual product and its serial number.
                    </p>

                    <h3>3.2 Crypto Wallet</h3>
                    <p className={"paragraph"}>
                        Customers must connect a legally owned crypto wallet to Ventrace during the ordering process.
                        If the access to the wallet was gained illegally, Ventrace has the right to cancel the order
                        immediately. Also, Ventrace will then have the right to contact suitable authorities about the
                        illegal access of the crypto wallet.
                    </p>

                    <h3>3.3 Order Communication</h3>
                    <p className={"paragraph"}>
                        Ventrace will communicate all order-related information to the email address provided by the customer.
                    </p>


                    <h2>4. Order Moderation and Delivery</h2>
                    <h3>4.1 Confirmation</h3>
                    <p className={"paragraph"}>
                        After the user/customer of Ventrace has placed an order, Ventrace will send an automated
                        confirmation email to the address which was given during the order process. Orders will then
                        be manually checked by the Ventrace moderation team for quality requirements.
                        <br/><br/>
                        Ventrace reserves up to 21 days to process an order.
                    </p>

                    <h3>4.2 Order Changes</h3>
                    <p className={"paragraph"}>
                        Ventrace may contact the customer for clarification and, with consent, change order details
                        to meet the quality requirements of Ventrace. Failure to respond within 14 days may result
                        in order cancellation.
                    </p>

                    <h3>4.3 Ventrace Record Activation</h3>
                    <p className={"paragraph"}>
                        If order details meet quality requirements, a moderator of Ventrace will manually create
                        and activate the Ventrace Record by storing it on the blockchain. Crypto tokens will be sent
                        to the customer's wallet.
                    </p>

                    <h3>4.4 Token Value Guarantee</h3>
                    <p className={"paragraph"}>
                        The moderator will send Ventrace crypto tokens (“VTR1”) and the tokens of the blockchain
                        on which the Ventrace Record is stored to the wallet which was given in the ordering process.
                        <br/><br/>
                        The amount of sent crypto currency may vary due to the changing nature of crypto currency
                        prices. But Ventrace is guaranteeing that the sent amount will be enough to add at least 3 Record updates.
                    </p>

                    <h2>5. Money-Back Guarantee</h2>
                    <h3>5.1 Refund</h3>
                    <p className={"paragraph"}>
                        Customers can request a full refund within 30 days after the order. The timezone of the
                        customer’s national residence is the basis for the 30 days calculcation. The money-back
                        guarantee can be claimed once per order and is not available for orders submitted with an
                        illegally accessed crypto wallet.
                        <br/><br/>
                        After the refund has been requested by the customer, Ventrace has an additional time of
                        30 days to process the refund. The money-back guarantee can be claimed only once per order.
                    </p>

                    <h2>6. Data Privacy</h2>
                    <h3>6.1 Tracking</h3>
                    <p className={"paragraph"}>
                        With customer consent, Ventrace tracks actions on the platform anonymously for the
                        purpose of improving conversion rates.
                    </p>

                    <h3>6.2 Third-Party Services</h3>
                    <p className={"paragraph"}>
                        If consented by the customer, Ventrace tracks their actions on the platform
                        and sends them to Mixpanel and, in case of errors, to
                        <a href={"https://Sentry.io"} target={"_blank"}>https://Sentry.io</a>. This tracked data is
                        used to improve common platform conversion rates.
                    </p>


                    <h2>7. Ventrace Recovery System</h2>
                    <h3>7.1 Loss Mode</h3>
                    <p className={"paragraph"}>
                        Ventrace offers a "Recovery system" to find a lost product represented by a Ventrace Record.
                        The activation of the recovery system (also known as "loss mode") can only be done with an
                        activated Ventrace Record.
                    </p>

                    <h3>7.2 Notification</h3>
                    <p className={"paragraph"}>
                        If the serial number of the lost product has been used in a Ventrace search, then the owner
                        who activated the recovery system will be notified per e-mail. If the owner requests further
                        details about the search activity of the lost product, then Ventrace may supply the exact
                        registered date and closest-known location of where the search request was made. Ventrace
                        will not deliver any known personal details to the owner of a Ventrace Record.
                    </p>


                    <h2>8. Platform Use by Third Parties</h2>
                    <h3>8.1 Authorized Use</h3>
                    <p className={"paragraph"}>
                        Only active and contracted Ventrace providers are allowed to link to or indirectly use
                        Ventrace for their business. This includes using a link to Ventrace on a listing.
                    </p>

                    <h3>8.2 Platform Rights</h3>
                    <p className={"paragraph"}>
                        Ventrace has the right to use any Ventrace Record as an example of how the platform
                        itself works. Furthermore, Ventrace may comment or disable any Ventrace Record if an
                        ongoing dispute leads to no other decision.
                    </p>


                    <h2>9. Technical Security</h2>
                    <h3>9.1 Forbidden Activities</h3>
                    <p className={"paragraph"}>
                        Any attempt to maliciously hack or misuse the Ventrace platform or the background
                        systems is forbidden. Ventrace will then contact suitable authorities to track down
                        and/or stop those attempts.
                    </p>

                    <h3>9.2 Technical Misuse</h3>
                    <p className={"paragraph"}>
                        Technical misuse includes, but is not limited to, attempting to gain unauthorized access
                        to a Ventrace Record.
                    </p>

                    <h2>10. Governing Law</h2>
                    <h3>10.1 Jurisdiction</h3>
                    <p className={"paragraph"}>
                        These Terms and any dispute arising out of or in connection with them shall
                        be governed by and construed in accordance with the laws of Frankfurt am Main, Germany.
                    </p>


                </div>
                <Footer
                    forMobile={this.props.appReducer.mobileMode}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        appReducer: state.app
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatchSetPage: (pageName) => {
            dispatch(requestSetCurrentPage(pageName));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions);