import React from "react";
import {connect} from "react-redux";
import TextInput from "../inputs/TextInput";
import UserButton from "../inputs/UserButton";
import { FaRegBell } from "react-icons/fa6";
import "../../styles/components/newsletter_section.scss"
import appConfig from "../../appConfig";
import {DataReporter} from "../../DataReporter";
import axios from "axios";
import FeedbackMessage from "../FeedbackMessage";

class NewsletterSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            minInputLength: 5,
            submitting: false,
            userEmail: null,
            emailValid: true,
            emailValidMsg: null,
            errorMsg: null,
            subscribed: false
        }
    }

    getProductWord(capitalize) {
        let productWord = "products";
        if (this.props.appReducer.activePersona === appConfig.personas.watches) {
            productWord = "watches";
        }
        if (capitalize) {
            return productWord.charAt(0).toUpperCase() + productWord.slice(1);
        } else {
            return productWord;
        }
    }

    // Events
    emailOnChange(e) {
        if (e.inputValue.length >= this.state.minInputLength) {
            this.setState({userEmail: e.inputValue});
        }
    }

    emailOnPaste(e) {
        this.emailOnChange({inputValue: e.clipboardData.getData("text")});
    }

    onSubscribeClick() {
        if (this.state.userEmail.length > 4 && this.state.userEmail.includes("@")) {
            if (!this.state.submitting) {
                this.setState({ submitting: true }, () => {
                    try {
                        axios
                            .post(appConfig.currentConfig.backendApp.url + "/platform/mailing/addcontact", {
                                contactData: {
                                    email: this.state.userEmail,
                                    persona: this.props.appReducer.activePersona
                                },
                                listID: 2
                            }, {
                                headers: {
                                    Authorization: `Bearer ${appConfig.currentConfig.backendApp.tokens.standard}`,
                                    'Content-Type': 'application/json',
                                },
                            })
                            .then(res => {
                                if (res.data.success) {
                                    this.setState({
                                        submitting: false,
                                        errorMessage: null,
                                        subscribed: true
                                    }, () => {
                                        DataReporter.trackMixpanel(this.props, "Newsletter subscription successful");
                                    })
                                } else {
                                    this.setState({
                                        submitting: false,
                                        errorMessage: "Subscription failed. Please try again. (3)",
                                    }, () => {
                                        DataReporter.trackMixpanel(this.props, "Newsletter subscription failed");
                                    })
                                }
                            })
                            .catch(err => {
                                this.setState({
                                    submitting: false,
                                    errorMessage: "Subsciption failed. Please try again. (1)"
                                });
                            })
                    } catch(err) {
                        this.setState({
                            submitting: false,
                            errorMessage: "Subsciption failed. Please try again. (2)"
                        });
                        DataReporter.trackSentry(err, {extra: {additionalData: "onSubscribeClick (1)"}});
                    }
                })
            }
        } else {
            this.setState({
                emailValid: false,
                emailValidMsg: "Your email address doesn't seem to be correct."
            })
        }
    }

    // Renderers
    renderSuccess() {
        if (this.state.subscribed || this.state.errorMessage) {
            return (
                <FeedbackMessage
                    success={this.state.errorMessage ? false : this.state.subscribed}
                    message={this.state.errorMessage ?
                        this.state.errorMessage : "You have been subscribed. Thank you!"}
                />
            );
        }
    }

    render() {
        let newsletterSectionId = "newsletter-section-container";
        if (this.props.appReducer.mobileMode) {
            newsletterSectionId += "-mobile";
        }

        return (
            <div id={newsletterSectionId}>
                <div id={"content"}>
                    <img id={"vr-seal-golden"}
                         src={process.env.PUBLIC_URL + '/ventrace_record/Seal_Gold.png'}
                         alt={"Golden Ventrace seal"}
                    />

                    <p id={"header"}>Get free access to exclusive insights</p>
                    <p id={"descr"}>
                        Ventrace will send you weekly tips and summaries for known tips and problems
                        of the latest hot {this.getProductWord()} on the market.
                    </p>

                    <div className={"inline"}>
                        <TextInput
                            inputID={"email-input"}
                            placeholder={"Your email address"}
                            onChange={(e) => this.emailOnChange(e)}
                            onPaste={(e) => this.emailOnPaste(e)}
                            validationError={!this.state.emailValid}
                            validationMsg={this.state.emailValidMsg}
                            maxLen={150}
                        />

                        <UserButton
                            forMobile={this.props.appReducer.mobileMode}
                            id={"subscribe-button"}
                            value={this.state.submitting ? "Subscribing ..." : "Subscribe"}
                            icon={<FaRegBell className={"text-icon"}/>}
                            onClick={() => this.onSubscribeClick()}
                            disabled={this.state.submitting}
                        />
                    </div>
                    {this.renderSuccess()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        appReducer: state.app,
        walletReducer: state.wallet
    }
}


const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsletterSection);