import React from "react";
import appConfig from "../../appConfig";
import { FiCamera } from "react-icons/fi";
import "../../styles/components/imagepresenter.scss";

class ImagePresenter extends React.Component {
    constructor(props) {
        super(props);

        let settings = [];

        for (let i=0; i < this.props.imageCIDs.length; i+=1) {
            if (i === 0) settings.push(true);
            else settings.push(false);
        }

        this.state = {
            showImgSettings: settings,
            ignoreShowAttachedImages: false
        }
    }

    onShowImageToggle(imageIndex) {
        let curr = this.state.showImgSettings;
        let set = !curr[imageIndex];

        for (let i=0; i < curr.length; i+=1) curr[i] = false;
        curr[imageIndex] = set;

        this.setState({
            showImgSettings: curr
        });
    }

    renderImageBox(imageIndex) {
        if (this.props.imageCIDs[imageIndex] && this.props.imageCIDs[imageIndex] !== null) {

            let mobileSuffix = this.props.forMobile ? "-mobile" : "";

            if (!this.state.showImgSettings[imageIndex]) {
                return (
                    <div className={"img-closed" + mobileSuffix} onClick={() => this.onShowImageToggle(imageIndex)}>
                        <FiCamera className={"icon"}/> <p className={"img-nr"}>{imageIndex+1}</p>
                    </div>
                );
            } else {
                return (
                    <div className={"img-viewing" + mobileSuffix} onClick={() => this.onShowImageToggle(imageIndex)}>
                        <FiCamera className={"icon"}/> <p className={"img-nr"}>{imageIndex+1}</p>
                    </div>
                );
            }
        }
    }

    renderImagePreview () {
        let imageIndex = null;
        for (let i=0; i < this.state.showImgSettings.length; i+=1) {
            if (this.state.showImgSettings[i]) {
                imageIndex = i;
                break;
            }
        }

        if (imageIndex !== null) {
            let mobileSuffix = this.props.forMobile ? "-mobile" : "";

            return (
                <div className={"img-open" + mobileSuffix}>
                    <img
                        className={"img"}
                        src={appConfig.IPFS_ProjectURL + this.props.imageCIDs[imageIndex]}
                        alt={"Attached image"}
                    />
                </div>
            );
        }
    }

    render() {
        if (this.props.imageCIDs) {
            return (
                <div>
                    <div className={"inline"}>
                        {this.renderImageBox(0)}
                        {this.renderImageBox(1)}
                        {this.renderImageBox(2)}
                    </div>
                    {this.renderImagePreview()}
                </div>
            );
        }
        return null;
    }
}

export default ImagePresenter;